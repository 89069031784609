.notification-icon-container {
  display: flex;
  justify-content: center;
  width: $spacing-a-medium;
  height: $spacing-a-medium;
  border-radius: 50%;
  margin-right: $spacing-a-xsmall;
  cursor: pointer;
  position: relative;

  img {
    width: $spacing-a-small;
  }

  .notification-number {
    position: absolute;
    background-color: $warning-color;
    min-width: 17px;
    /* height: auto; */
    border-radius: 50%;
    text-align: center;
    padding: 1px;
    top: $spacing-a-small;
    left: 19px;
    font-size: 10px;
    color: $surface-white;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
  }
}

.notification-active {
  background-color: $bodyBackground;
}

.notification-active-primary {
  background-color: $base-color;
}

.notification-container {
  width: 348px;
  height: 100vh;
  background-color: $surface-white;
  padding: $spacing-a-small;
  position: absolute;
  top: 62px;
  right: 0px;
  z-index: 9;
  overflow: auto;
  display: none;

  .notification-text {
    font-weight: 700;
  }

  .filter-container {
    font-size: $spacing-a-smaller;
    color: $grey-intense-color;
    margin-left: -$spacing-a-small;
    margin-right: -$spacing-a-small;

    .circle {
      min-width: 15px;
      // height: 15px;
      border-radius: 50%;
      background-color: $warning-color;
      color: $surface-white;
      text-align: center;
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      aspect-ratio: 1/1;

      &.circle-active {
        background-color: $primary-color;
      }
    }

    .filter-menu-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .red-dot {
        width: $spacing-a-smallest;
        height: $spacing-a-smallest;
        border-radius: 50%;
        background-color: $warning-color;
        position: absolute;
        right: 17px;
        top: 74px;
        display: none;

        &.show {
          display: block;
        }
      }

      .title {
        color: $base-color;
      }
      .menu {
        display: flex;
        width: 90%;

        .separator {
          border: 1px solid #eff3f6;
          margin-left: 8px;
          margin-right: 8px;
        }
      }
      .filter-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 4px;
        align-items: center;
        position: relative;

        .clinic-menu {
          position: absolute;
          min-width: 134px;
          background-color: #eff3f6;
          border-radius: 5px;
          top: 26px;
          box-shadow: 3px 2px 4px -1px rgba(0, 0, 0, 0.12);
          display: none;

          .clinic {
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 2px solid $surface-white;
            padding: 4px;

            &:hover {
              background-color: #fdfdfe;
              border-bottom: 2px solid $base-color;
            }
          }
        }

        .clinic-menu-active {
          display: block;
        }
      }

      .filter-menu-active {
        color: $surface-black-color;
        font-weight: 600;
      }

      //   .circle {
      //     width: 15px;
      //     height: 15px;
      //     border-radius: 50%;
      //     background-color: #8ca1b4;
      //     color: #fdfdfe;
      //     text-align: center;
      //     font-size: 10px;
      //   }

      //   .circle-active {
      //     background-color: #508cc0;
      //   }

      .burger-menu {
        display: flex;
        flex-direction: column;
        width: 22px;
        padding: 5px;
        cursor: pointer;
        align-items: center;

        &:hover {
          border-radius: 50%;
          background-color: $bodyBackground;
        }

        span {
          background: $base-color;
          // border-radius: 10px;
          height: 1.8px;
          margin: 1px 0;
          transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
        }

        span:nth-of-type(1) {
          width: 100%;
        }

        span:nth-of-type(2) {
          width: 70%;
        }

        span:nth-of-type(3) {
          width: 30%;
        }
      }

      .burger-menu-active {
        position: relative;
        padding: 11px;
        @extend .burger-menu;
        span {
          position: absolute;
        }
        span:nth-of-type(1) {
          width: 62%;
          transform-origin: top;
          transform: rotatez(45deg) translate(-1.2px, -1px);
        }

        span:nth-of-type(2) {
          transform-origin: top;
          transform: rotatez(-45deg) translate(0px, -1px);
          width: 77%;
        }

        span:nth-of-type(3) {
          transform-origin: bottom;
          transform: translate(3.1px, 2.4px) rotatez(45deg);
        }
      }
    }

    .filter-body {
      background: $surface-white;
      /* Components Shadow */
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
      border-radius: 0px 0px 10px 10px;
      margin-left: -$spacing-a-small;
      margin-right: -$spacing-a-small;
      //   transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
      // max-height: 0;
      // transition: max-height 0.03s ease-out;
      flex-direction: column;
      /* gap: 8px; */
      padding: $spacing-a-small;
      display: flex;
      opacity: 0;
      position: relative;

      .filter-options {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        cursor: pointer;
        color: $surface-black-color;
        padding-bottom: 8px;

        .clinic-menu {
          position: absolute;
          width: 100%;
          background-color: #eff3f6;
          border-radius: 5px;
          top: 18px;
          box-shadow: 3px 2px 4px -1px rgba(0, 0, 0, 0.12);
          z-index: 34456445;
          display: none;
          color: #8ca1b4;

          .clinic {
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 2px solid $surface-white;
            padding: 4px;

            &:hover {
              background-color: #fdfdfe;
              border-bottom: 2px solid $base-color;
            }
          }
        }

        .clinic-menu-active {
          display: block;
        }

        // .clinic-menu-options {
        //   @extend .clinic-menu;

        //   .clinic {
        //     @extend .clinic;
        //   }
        // }
      }

      .filter-options-active {
        font-weight: 600;
      }

      .bottom-close-button {
        position: absolute;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -10px;

        span {
          background: $bodyBackground;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
          border-radius: 50px;
          padding: 4px 12px;
          color: $surface-black-color;
          cursor: pointer;
        }
      }
    }

    .filter-active {
      //   display: flex;
      opacity: 1;
      // max-height: 500px;
      // transition: max-height 0.05s ease-in;
    }
  }

  .notifications {
    padding-top: 34px;
    span {
      background: $background-color;
      border-radius: 5px;
      padding: 4px 12px;
      font-size: 12px;
      color: $surface-black-color;
    }

    .notification-link-text {
      @extend .link-text;
      font-family: "Inter-Regular";
      background: transparent;
    }

    .notification-image-link {
      border-radius: 50%;
      background-color: $bodyBackground;
      padding: $spacing-a-smallest;
    }

    .notification-card {
      display: flex;
      gap: 12px;
      padding: 12px;
      margin-left: -$spacing-a-small;
      margin-right: -$spacing-a-small;

      img {
        width: $spacing-a-medium;
        height: $spacing-a-medium;
        border-radius: 50%;
      }

      b {
        background-color: transparent;
        font-weight: 600;
      }

      .notification-card-body {
        width: 73%;
        .notification-message {
          font-size: $spacing-a-smaller;
          color: $surface-black-color;
        }
        .meta-info {
          font-size: 10px;
          color: $grey-intense-color;
        }
      }

      &:hover {
        background-color: $bodyBackground;
        border-left: 2px solid $base-color;
        .options-menu {
          display: flex;
        }
      }

      .options-menu {
        width: 22px;
        height: 15px;
        background-color: $surface-white;
        border-radius: 5px;
        justify-content: center;
        cursor: pointer;
        display: none;
        position: relative;

        span {
          background-color: transparent;
          border-radius: 0;
        }

        img {
          background-color: transparent;
          border-radius: 0;
          width: 11px;
          height: $spacing-a-small;
        }

        .dropdown-options-container {
          position: absolute;
          top: 20px;
          left: 21px;
        }
      }

      .options-menu-active {
        background-color: $primary-color;
      }
    }

    .bottom-border {
      border: 1px solid $background-color;
      margin-left: -$spacing-a-small;
      margin-right: -$spacing-a-small;
    }
  }
}

.noti-show {
  box-shadow: -2px 0px 4px -1px rgba(0, 0, 0, 0.12);
  animation: notificationOpenAnimation 0.5s ease-in-out forwards;
  display: block;
}

.noti-hide {
  visibility: hidden;
  transition: all 0.3s ease-out;
}

// TODO: closing animation
.notification-close {
  //   transition: opacity 0.3s ease-out;
  //   animation: openAnimation 0.5s ease-in-out forwards;
  display: none;
  // animation: closeAnimation 0.5s ease-in-out forwards;
}

@keyframes notificationOpenAnimation {
  0% {
    transform: translateX(60%);
    // opacity: 0;
  }
  100% {
    transform: translateX(0) cubic-bezier(0.95, -0.08, 0.47, 1.45);
    // opacity: 1;
  }
}

@keyframes cardOpenAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes closeAnimation {
  0% {
    transform: translateX(0%);
    visibility: visible;
  }
  100% {
    transform: translateY(-60%) cubic-bezier(0.95, -0.08, 0.47, 1.45);
    visibility: hidden;
  }
}
