.show-media {
  width: 100%;
  background-color: rgba($color: $base-color, $alpha: 0.2);
  margin: auto;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  .modal-container {
    position: absolute;
    margin: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .media-modal {
      @include drop-shadow(0, 2px, 4px, -1px, 0.12);
      overflow-y: auto;
      max-height: 80vh;
      background-color: rgba($color: $surface-white, $alpha: 1);
      border-radius: $border-radius;
    }
  }
}
