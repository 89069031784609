.accordion {
  display: flex;
  width: 100%;
  height: 100%;
}

.accordion-summary {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: 1px solid transparent;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid $light-gray;
    background-color: $light-gray;
    border-radius: 8px;
  }

  :after {
    content: "";
    position: absolute;
    left: 8px;
    right: 8px;
    bottom: 0;
    height: 1px;
    background: $light-gray;
  }
}

.accordion-item {
  display: flex;
  flex-direction: column;
  border: none;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.accordion-title {
  font-weight: bold;
  color: $base-color;
  margin-bottom: 0;

  &-gray {
    color: $grey-intense-color;
  }
}

.accordion-content {
  display: flex;
  width: 100%;
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 15px;
  overflow-y: scroll;
  flex-direction: column;
}
