.rc-slider-mark {
  left: -1.5px;
}

.rc-slider-value-indicator {
  margin-top: 25px;
}

.rc-slider-disabled {
  background-color: none !important;
}
