.programs-module-wrapper {
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;

  .programs-module-container {
    display: flex;
    flex-direction: column;
    background: $surface-white;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
    border-radius: $border-radius;
    padding: $spacing-a-xsmall;

    .search-wrapper {
      margin-top: $spacing-a-xsmall;
    }

    .programs-icon {
      width: 18px;
      height: 20px;
      margin-right: $spacing-a-smaller;
    }

    button {
      display: flex;
      align-items: center;
    }

    .programs-list {
      padding-right: $spacing-a-small;
      padding-left: $spacing-a-small;
      padding-top: $spacing-a-medium;

      .programs-list-labels-wrapper {
        padding-bottom: $spacing-a-small;

        .order-img-container {
          margin-left: 4px;
          height: 14px;
          width: 14px;
          background-color: $surface-white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 2px;
          border-radius: 50%;
          cursor: pointer;

          &.active {
            background-color: $background-color;
          }

          img {
            height: 4px;
            width: 6px;
          }
        }
      }

      .program-info-details-wrapper {
        margin: 0 -16px;
        padding: 0 12px;
        border-radius: $border-radius;
        border: 1px solid transparent;
        border-bottom: 1px solid $background-color;

        &:last-child:not(.open) {
          border-bottom: none;
        }

        &.open {
          border-color: $background-color;
          margin-bottom: $spacing-a-small;
        }

        .program-info-wrapper {
          align-items: center;
          padding-top: 10px;
          padding-bottom: 10px;

          .pointer {
            cursor: pointer;
          }

          &.open {
            background-color: $background-color;
            border-radius: $border-radius;

            .quaternary-button {
              background-color: $surface-white;

              &:hover {
                &:not([disabled]) {
                  background-color: #dbe7ee;
                }
              }
            }
          }
        }

        .program-details-wrapper {
          padding: $spacing-a-small $spacing-a-smaller 0 $spacing-a-smaller;

          .protocols-list-labels-wrapper {
            padding-bottom: $spacing-a-small;

            .order-img-container {
              margin-left: 4px;
              height: 14px;
              width: 14px;
              background-color: $surface-white;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 2px;
              border-radius: 50%;
              cursor: pointer;

              &.active {
                background-color: $background-color;
              }

              img {
                height: 4px;
                width: 6px;
              }
            }
          }

          .protocol-info-wrapper {
            padding: 10px 0;
            align-items: center;
            border-bottom: 1px solid $background-color;

            &:last-child {
              border-bottom: none;
              .pointer {
                cursor: pointer;
              }
            }
          }
        }
      }

      .loading-wrapper {
        flex-direction: row;
        display: flex;
        position: relative;
        margin-top: 75px;
        align-items: center;
        justify-content: center;
      }

      .message-wrapper {
        margin-top: 75px;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .added-module-info {
      margin: 0 0;
      padding: $spacing-a-smaller 0;
      border-bottom: 1px solid $background-color;
      align-items: center;

      &:last-child {
        border-bottom: none;
      }

      img {
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }
  }
}
