//green bold 32px
h1 {
  font-family: $font-family-bold;
  font-size: $font-s-xlarge;
  color: $secondary-color;
}

//blue extra-nold 42px
h2 {
  font-family: $font-family-extra-bold;
  font-size: $font-s-xxlarge;
  color: $base-color;
}

// blue extra-bold 32px
h3 {
  font-family: $font-family-extra-bold;
  font-size: $font-s-xlarge;
  color: $base-color;
}

h4 {
  font-family: $font-family-bold;
  font-size: $font-s-large;
  color: $base-color;
}

.small-title {
  font-size: $font-s-medium;
  font-family: $font-family-bold;
  color: $base-color;
}

.smaller-title {
  font-size: $font-s-regular;
  font-family: $font-family-bold;
  color: $base-color;
}

.semi-bold-medium-title {
  font-size: $font-s-medium;
  font-family: $font-family-semi-bold;
  color: $base-color;
}

.input-label-text {
  font-size: $font-s-regular;
  font-family: $font-family-regular;
  color: $dark-grey-color;
  padding-bottom: $spacing-a-smallest;
  &.with-error {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.list-label {
  font-family: "Inter-Bold";
  font-size: $font-s-small;
  color: $secondary-color;
}

.regular-text {
  font-size: $font-s-regular;
  font-family: $font-family-regular;
  color: $dark-grey-color;
  line-height: 16px;
  &.warning {
    color: $warning-color;
  }
  &.smaller {
    font-size: $font-s-small;
  }
  &.medium {
    font-size: $font-s-medium;
  }
}

.medium-base-color-text {
  font-size: $font-s-medium;
  font-family: $font-family-regular;
  color: $base-color;
}

.regular-semibold-base-text {
  font-size: $font-s-regular;
  font-family: $font-family-semi-bold;
  color: $base-color;
}

.big-semibold-base-text {
  font-size: $font-s-big;
  font-family: $font-family-semi-bold;
  color: $base-color;
}

.medium-semibold-text {
  font-size: $font-s-regular;
  font-family: $font-family-semi-bold;
}

.link-text {
  font-size: $font-s-regular;
  font-family: $font-family-bold;
  color: $primary-hover-color;
  display: inline-block;
  cursor: pointer;
}

.link-text-regular {
  font-size: $font-s-regular;
  color: $primary-hover-color;
  display: inline-block;
  cursor: pointer;
}

.link-text-normal {
  font-size: $font-s-regular;
  font-family: $font-family-regular;
  color: $primary-hover-color;
  display: inline-block;
  cursor: pointer;
}

.bold-text {
  font-family: $font-family-bold;
}

/* Text align */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// end

/* COLOR CLASSES */

.text--grey-intense {
  color: $grey-intense-color;
}

.surface-black-color {
  color: $surface-black-color;
}

.primary-normal-color {
  color: #508cc0;
}

.under-line {
  text-decoration: underline;
}

// end
