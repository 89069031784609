.tooltip {
  z-index: 3;
  background-color: $base-color !important;
  color: $surface-white !important;
  font-size: $font-s-regular !important;
  font-family: $font-family-regular !important;
  max-width: 300px !important;
}

.tooltip-btn {
  padding: 4px 8px !important;
  z-index: 3;
  background-color: $base-color !important;
  color: $surface-white !important;
  font-size: $font-s-regular !important;
  font-family: $font-family-regular !important;
  max-width: 300px !important;
}
