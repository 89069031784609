.drop-down-box {
  padding: $spacing-a-smallest;
  background-color: $background-color;
  max-height: 300px;
  border-radius: 5px;
  z-index: 9999;
  margin-bottom: 32px;
  animation: fadeIn 1s;
  overflow: scroll;
}

.right-icon {
  position: absolute;
  left: 10px;
  top: 9px;
  height: 15px;
  width: auto;
  z-index: 9999;
}

.arrow-img {
  position: absolute;
  top: 12px;
  right: 17px;
  cursor: pointer;
  width: auto;
  height: 8px;
}

.items {
  display: flex;
  flex-direction: column;
  padding: $spacing-a-smallest;
  cursor: pointer;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: space-between;

  &:hover {
    border-radius: 5px;
    background-color: $background-hover-color;
  }

  span {
    display: flex;
    justify-content: space-between;
  }
}

.custom-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 32px;
  margin-bottom: 8px;
  overflow: scroll;
  overflow-y: hidden;
  cursor: default;
  max-width: 100% !important;
  background: #eff3f6 !important;

  .tag {
    display: flex;
    list-style: none;
    background-color: #dbe7ee;
    border-radius: 50px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    justify-content: space-between;

    img {
      cursor: pointer;
    }
  }
}

.loading {
  position: absolute;
  top: $spacing-a-small;
  right: $spacing-a-medium;
  cursor: none;
}

.no-result {
  @extend .items;
  pointer-events: none;
  color: #255986;
}

.multi-select {
  color: transparent;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
