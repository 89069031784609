.medicines-module-wrapper {
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;
}

.mkinetikos-medicines-modules-box {
  @include box(20px 32px);
  background-color: $surface-white;
  @include drop-shadow(0, 2px, 4px, -1px, 0.12);
  .medicine {
    @include list-item(8px);
    .expired-date {
      width: fit-content;
      border-radius: $border-radius2;
      background-color: $background-color;
      padding: 3px 18px 2px;
    }

    div span {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.prescription-dropdown {
  overflow-y: hidden;
  width: 190px;
  height: 164px;
  z-index: 10;
  position: absolute;
  padding: 10px;
  background-color: $background-color;
  border: 1px solid #508cc0;
  border-radius: $border-radius;
}

.reset-button {
  width: 52px;
  cursor: pointer;
}
