@mixin button(
  $font-family,
  $background_color,
  $text_color: $surface-white,
  $border_color: transparent
) {
  font-family: $font-family;
  background-color: $background_color;
  color: $text_color;
  border: 1px solid $border_color;
  border-radius: $border-radius;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: 0.3s;

  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  &.transparent {
    color: transparent;
  }
  &.margin-right {
    margin-right: 12px;
  }
}

.button {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  white-space: nowrap;
}

.primary-button {
  @include button($font-family-medium, $primary-color);
  &:hover {
    &:not([disabled]) {
      background-color: $primary-hover-color;
    }
  }
}

.secondary-button {
  @include button($font-family-medium, $secondary-color);
  &:hover {
    &:not([disabled]) {
      background-color: $secondary-hover-color;
    }
  }
}

.tertiary-button {
  @include button(
    $font-family-regular,
    $surface-white,
    $base-color,
    $base-color
  );
  &:hover {
    &:not([disabled]) {
      background-color: $base-color;
      color: $surface-white;
    }
  }
}

.quaternary-button {
  @include button(
    $font-family-regular,
    $background-color,
    $surface-black-color
  );
  &:hover {
    &:not([disabled]) {
      background-color: #dbe7ee;
    }
  }
}

.min-content {
  width: max-content;
}

.filter-button {
  @extend .flex-d-column;
  position: relative;
  width: 130px;
}

.transparent-button {
  @include button($font-family-regular, transparent, $cornflower-blue-color);
}

.transparent-bordered-button {
  @include button($font-family-regular, transparent, $base-color, $base-color);
}

.marked-button {
  @include button($font-family-medium, $base-color);
}

.background-button {
  @include button($font-family-regular, $background-color, $base-color);
  &:hover {
    &:not([disabled]) {
      background-color: $background-hover-color;
    }
  }
}

.warning-button {
  @include button(
    $font-family-regular,
    transparent,
    $warning-color,
    $warning-color
  );
  &:hover {
    &:not([disabled]) {
      background-color: $warning-color;
      color: $surface-white;
    }
  }
}

.warning-button-with-bg {
  @include button(
    $font-family-regular,
    transparent,
    $warning-color,
    $warning-color
  );
  background-color: $warning-color;
  color: $surface-white;
  &:hover {
    &:not([disabled]) {
      background-color: $warning-hover-color;
    }
  }
}

.small-button-2 {
  font-size: $font-s-regular;
  padding: 5px 17px;
  line-height: 14px;
}
.small-button {
  font-size: $font-s-regular;
  padding: 8px 12px;
  line-height: 14px;
}

.small-wider-button {
  font-size: $font-s-regular;
  padding: 8px 20px;
  line-height: 14px;
}

.big-button {
  font-size: $font-s-medium;
  padding: 8px 30px;
  line-height: 17px;
}

.back-button-wrapper {
  @include button($font-family-regular, transparent, $dark-grey-color);
  font-size: $font-s-regular;
}

.back-button-wrapper img {
  height: 12px;
  margin-right: 5px;
  width: auto;
}

.add-note-button {
  display: block;
  padding: 8px;
  font-size: 12px;
}
