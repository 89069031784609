.days-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -($spacing-a-smallest/2);

  .day-container {
    border-radius: $border-radius2;
    background-color: $background-color;
    padding: 3px $spacing-a-smaller 2px;
    margin: $spacing-a-smallest/2;
    &.active {
      background-color: $primary-color;
      color: $surface-white;
    }
  }
}
