.patient-my_care_team-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-left: $spacing-a-medium;
  padding-right: $spacing-a-medium;

  .title-container {
    display: flex;
    align-self: flex-start;
    align-items: center;
    margin-bottom: $spacing-a-biggest;
    cursor: pointer;

    img {
      margin-right: $spacing-a-small;
    }

    h4 {
      margin: 0;
    }
  }

  .chats-list-wrapper {
    min-height: 100px;
    padding-bottom: $spacing-a-medium;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .chat-list-item-wrapper {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      transition: 0.3s;

      &.open {
        padding-right: 3px;
        background-color: $base-color;
      }

      .chat-list-item {
        background: #fdfdfe;
        border-radius: 5px;
        padding: $spacing-a-smaller $spacing-a-small;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 0.3s;

        &.open {
          padding-right: $spacing-a-small - 3px;
        }

        .chat-avatar {
          height: 48px;
          width: 48px;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
          border-radius: 5px;

          &.circle {
            border-radius: 50%;
          }
        }

        .new-messages-container {
          height: 30px;
          width: 30px;
          border-radius: $border-radius;
          background-color: $primary-color;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .chat-participants-container {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          margin-top: 4px;

          .chat-participant-avatar {
            height: 18px;
            width: 18px;
            border-radius: 50%;
            border: 1px solid $surface-white;
            margin-right: -6px;
          }
        }
      }
    }

    .new-chat-wrapper {
      display: flex;
      flex-direction: column;

      .new-chat-header {
        align-self: flex-start;
        display: flex;
        align-items: center;
        background: $secondary-color;
        border-radius: $border-radius;
        padding: 4px 4px 4px + 20px $spacing-a-small;
        margin-bottom: -20px;

        .close-icon {
          cursor: pointer;
          margin-left: 4px;
        }
      }

      .new-chat-container {
        padding: 20px $spacing-a-smaller;
        background: $surface-white;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
        border-radius: $border-radius;
        margin-bottom: $spacing-a-medium;
        display: flex;
        align-items: center;

        .new-chat-participants {
          position: relative;
          flex-wrap: wrap;
          display: flex;
          flex: 1;
          align-items: center;
          margin-top: -2px;
          padding-bottom: 6px;
          border-bottom: 1px solid #255986;

          .current-patient {
            opacity: 60%;
          }

          .new-chat-participant-container {
            display: flex;
            align-items: center;
            background-color: $primary-color;
            border-radius: $border-radius2;
            padding: 3px $spacing-a-smallest;
            margin-right: $spacing-a-smaller;
            margin-top: $spacing-a-smallest;

            .remove-icon {
              cursor: pointer;
              margin-left: $spacing-a-smallest;
              transition: 0.2s;

              &:hover {
                opacity: 0.7;
              }
            }
          }

          .add-participant-input {
            outline: 0;
            padding: 3px $spacing-a-smallest;
            margin-top: $spacing-a-smallest;
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
          }

          .dropdown-options {
            padding: $spacing-a-smallest 4px 0;
            border-radius: $border-radius;
            z-index: 1 !important;
            position: absolute;
            top: 110%;
            width: 100%;
            background-color: $surface-white;
            @include drop-shadow(0, 2px, 15px, 0, 0.2);

            button {
              display: flex;
              align-items: center;
              border-radius: $border-radius;
              padding: 4px $spacing-a-smallest;
              background-color: transparent;
              margin-bottom: $spacing-a-smallest;
              &:hover {
                background-color: $background-color;
              }
            }

            .loading {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: $border-radius;
              padding: 4px $spacing-a-smallest;
              background-color: transparent;
              margin-bottom: $spacing-a-smallest;
              &:hover {
                background-color: $background-color;
              }
            }

            .dropdown-btn-img {
              height: 24px;
              width: 24px;
              border-radius: 50%;
              margin-right: $spacing-a-smallest;
            }
          }
        }
      }
    }
  }

  .chat-module-wrapper {
    padding-bottom: $spacing-a-medium;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .chat-container {
      background: $surface-white;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 50%;
      overflow-y: auto;

      .chat-header {
        padding: $spacing-a-smallest $spacing-a-xsmall $spacing-a-small;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 4px solid #eff3f6;

        .chat-title {
          font-size: $font-s-small;
          color: $grey-intense-color;
        }

        .chat-title-img {
          margin-left: $spacing-a-small;
          cursor: pointer;
          transition: 0.3s;

          &:hover:not([disabled]) {
            opacity: 0.7;
          }

          &[disabled] {
            cursor: auto;
          }
        }

        .chat-title-name {
          color: $secondary-color;
        }

        .participants-avatars-container {
          margin-left: $spacing-a-smaller;
          display: flex;
          flex-direction: row-reverse;
          cursor: pointer;
          position: relative;

          .participant-avatar-img {
            height: 24px;
            width: 24px;
            border: 1px solid $surface-white;
            border-radius: 50%;
            margin-right: -8px;
          }

          .dropdown-options {
            padding: $spacing-a-smallest 4px 0;
            border-radius: $border-radius;
            z-index: 1 !important;
            position: absolute;
            top: 110%;
            left: 0;
            right: auto;
            width: max-content;
            background-color: $surface-white;
            @include drop-shadow(0, 2px, 15px, 0, 0.2);

            button {
              display: flex;
              align-items: center;
              border-radius: $border-radius;
              padding: 4px $spacing-a-smallest;
              background-color: transparent;
              margin-bottom: $spacing-a-smallest;
              &:hover {
                background-color: $background-color;
              }
            }

            .dropdown-btn-img {
              height: 24px;
              width: 24px;
              border-radius: 50%;
              margin-right: $spacing-a-smallest;
            }
          }
        }

        .add-participants-container {
          position: relative;
          flex-wrap: wrap;
          display: flex;
          flex: 1;
          align-items: center;
          height: 20px;
          max-width: 200px;
          min-width: 100px;
          padding-bottom: 6px;
          border-bottom: 1px solid #255986;

          .add-participant-input {
            outline: 0;
            padding: 0 6px;
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
          }

          .dropdown-options {
            padding: $spacing-a-smallest 4px 0;
            border-radius: $border-radius;
            z-index: 1 !important;
            position: absolute;
            top: 110%;
            width: 100%;
            background-color: $surface-white;
            @include drop-shadow(0, 2px, 15px, 0, 0.2);

            button {
              display: flex;
              align-items: center;
              border-radius: $border-radius;
              padding: 4px $spacing-a-smallest;
              background-color: transparent;
              margin-bottom: $spacing-a-smallest;
              &:hover {
                background-color: $background-color;
              }
            }

            .loading {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: $border-radius;
              padding: 4px $spacing-a-smallest;
              background-color: transparent;
              margin-bottom: $spacing-a-smallest;
              &:hover {
                background-color: $background-color;
              }
            }

            .dropdown-btn-img {
              height: 24px;
              width: 24px;
              border-radius: 50%;
              margin-right: $spacing-a-smallest;
            }
          }
        }

        .add-participant-close {
          cursor: pointer;
          margin-top: -6px;
          margin-bottom: -6px;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      .chat-messages-container {
        flex: 1;
        display: flex;
        flex-direction: column-reverse;
        overflow-y: auto;
        padding: $spacing-a-medium $spacing-a-xsmall 0;
      }

      .chat-section-header-wrapper {
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        .section-line {
          flex: 1;
          height: 1px;
          background-color: $background-color;
        }

        .section-header-container {
          margin: 0 $spacing-a-smallest;
          background-color: $background-color;
          border-radius: $border-radius;
          padding: 4px $spacing-a-smaller;
        }
      }

      .chat-msg-wrapper {
        max-width: 80%;
        align-self: end;
        display: grid;
        grid-template-columns: auto max-content;
        margin-bottom: $spacing-a-small;

        &.msg-from-patient {
          align-self: start;
          grid-template-columns: max-content auto;
        }

        .msg-avatar-img {
          height: 36px;
          width: 36px;
          border-radius: 50%;
          align-self: flex-end;
          margin-left: $spacing-a-smallest;
          margin-right: 0;

          &.msg-from-patient {
            margin-right: $spacing-a-smallest;
            margin-left: 0;
          }
        }

        .msg-container {
          background-color: $primary-color;
          border-radius: $border-radius;
          padding: $spacing-a-smallest $spacing-a-smaller;
          color: $surface-white;
          justify-content: center;

          &.msg-from-patient {
            background-color: $background-color;
            color: $surface-black-color;
            justify-content: start;
          }
        }

        .msg-bottom-info {
          grid-column-start: 1;
          grid-column-end: 2;
          margin-top: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &.msg-from-patient {
            grid-column-start: 2;
            grid-column-end: 3;
          }

          .msg-date {
            color: $dark-grey-color;
            font-size: $font-s-small;
            font-family: $font-family-regular;
            margin-right: $spacing-a-smaller;
          }

          .msg-seen-container {
            display: flex;
            position: relative;

            .msg-seen-img {
              height: 12px;
              width: 12px;
              border-radius: 50%;
            }

            .msg-seen-info {
              position: absolute;
              right: 0;
              top: 110%;
              padding: 4px $spacing-a-smaller;
              background-color: $background-color;
              display: none;
              box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
              border-radius: $border-radius;
              transition: 0.3s;
            }

            &:hover {
              .msg-seen-info {
                display: inline;
              }
            }
          }
        }
      }

      .chat-input-wrapper {
        margin: $spacing-a-smallest;

        .message-input {
          margin-bottom: 0;
          padding: $spacing-a-smaller $spacing-a-small;
          padding-right: $spacing-a-medium;
        }

        .message-input-img {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 10px;
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            opacity: 0.7;
          }

          &.disabled {
            cursor: auto;
            opacity: 0.3;
          }
        }

        .message-input-loading {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 28px;
          display: none;
        }
      }
    }
  }
}
