@mixin dropdown(
  $background_color,
  $text_color: $surface-white,
  $border_color: transparent
) {
  border: 1px solid $border_color;
  outline: none;
  background: $background-color url("../../assets/images/arrow-down.svg")
    no-repeat center right $spacing-a-small;
  min-width: 150px;
  max-width: 450px;
  padding: 8px 12px;
  padding-right: $spacing-a-xsmall;
  line-height: 14.5px;
  border-radius: $border-radius;
  background-color: $background-color;
  font-family: $font-family-regular;
  font-size: $font-s-regular;
  color: $text_color;
  border: 1px solid $border_color;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.error {
    border: 1px solid $warning-color;
  }
}

.dropdown {
  @include dropdown($background-color, $surface-black-color);
  margin-bottom: $spacing-a-xsmall;
  &.notSelected {
    color: $grey-intense-color;
    font-size: $font-s-small;
  }
  &:focus {
    background-color: $background-color;
    border: 1px solid $primary-hover-color;
    box-shadow: none;
  }
}

.form-dropdown {
  @include dropdown($background-color, $surface-black-color);
  margin-bottom: $spacing-a-xsmall;
  &.notSelected {
    color: $grey-intense-color;
    font-size: $font-s-small;
  }
  &:focus {
    background-color: $background-color;
    border: 1px solid $primary-hover-color;
    box-shadow: none;
  }
}

.dropdown1 {
  @include dropdown(transparent, $surface-black-color, $background-color);
  &:focus {
    background-color: transparent;
    border: 1px solid $primary-hover-color;
    box-shadow: none;
  }
}

.country-code-dropdown {
  @extend .dropdown;
  @extend .mb-0;
  @extend .mr-2;

  min-width: 90px;
}
