.dropdown-options {
  padding: 6px;
  float: right;
  border-radius: $border-radius;
  z-index: 1 !important;
  position: absolute;
  right: 0;
  top: 100%;
  width: 180px;
  background-color: $surface-white;
  @include drop-shadow(0, 2px, 15px, 0, 0.2);

  button {
    border-radius: $border-radius;
    border: none;
    width: 100%;
    padding-left: 11px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 20px;
    background-color: transparent;
    text-align: start;
    &:hover {
      background-color: $background-color;
    }
  }
}
