.form-auth-wrapper-1 {
  background-color: $surface-white;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
}

.form-title-container {
  position: relative;
  margin-top: $spacing-a-smallest;
  margin-right: $spacing-a-smallest;
  margin-left: $spacing-a-smallest;
  background-color: $background-color;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  display: flex;
  flex-direction: row;
}

.form-title-container-2 {
  position: relative;
  background-color: $background-color;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  display: flex;
  flex-direction: row;
}

.form-title-step {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: $base-color;
  font-family: $font-family-semi-bold;
  font-size: $font-s-medium;
  padding: $spacing-a-small;

  &.pointer {
    cursor: pointer;
  }
}

.form-title-step:hover {
  cursor: pointer;
  background-color: #DBE7EE;
  border-radius: 5px 5px 0 0;
}
