@mixin drop-shadow($x, $y, $blur, $spread, $opacity) {
  -moz-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $opacity);
  -webkit-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $opacity);
  box-shadow: $x $y $blur $spread rgba(0, 0, 0, $opacity);
}

@mixin box($padding) {
  border-radius: $border-radius;
  padding: $padding;
  flex-wrap: wrap;
  border: 1px solid $background-color;
  min-width: 200px;
}

@mixin list-item($padding) {
  border: $border-radius;
  align-items: center;
  padding-top: $padding;
  padding-bottom: $padding;
  border-bottom: 1px solid $background-color;
  &:last-child {
    border-bottom: none;
  }
}
