@mixin spinner($size, $border-size, $color: white, $border-color: white) {
  //   display: inline-block;
  width: $size;
  height: $size;
  // vertical-align: -0.125em;
  border: $border-size solid $border-color;
  border-right-color: transparent;
  border-radius: 50%;
  color: $color;
  animation: 0.75s linear infinite spinner-border;
}
.spinner {
  &.small {
    @include spinner($spacing-a-small, 2px);
  }

  &.small-blue {
    @include spinner($spacing-a-small, 2px, $border-color: $primary-color);
  }

  &.big-blue {
    @include spinner($spacing-a-large, 2px, $border-color: $primary-color);
  }

  &.biggest-blue {
    @include spinner(78px, 3px, $border-color: $primary-color);
  }

  &.small-warning {
    @include spinner($spacing-a-small, 2px, $border-color: $warning-color);
  }

  &.small-waiting {
    @include spinner($spacing-a-small, 2px, $border-color: $orange);
  }
}

.spinner-center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.loading-wrapper {
  flex-direction: row;
  display: flex;
  position: relative;
  margin-top: 75px;
  align-items: center;
  justify-content: center;
}
