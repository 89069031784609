.company-info-body {
  font-size: 10px;
  color: #8ca1b4;
  width: 19%;
  min-width: 250px;
}

.align-center-text {
  text-align: center;
}
