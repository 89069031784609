.support-detail-container {
  display: flex;
  flex: 1;
  max-height: 90%;
}

// Main Components

.support-detail-header-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 4px;
  height: 10%;
}

.support-detail-content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 85%;
}

.support-detail-reply-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 5%;
  margin-top: 25px;
}

// Header
.support-detail-header-title {
  width: 100%;
  margin-top: 0.5rem;
}

.support-detail-goback-icon {
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-right: 12px;
}

// Content

// -- Details
.support-detail-details-container {
  display: flex;
  width: 100%;
  margin-top: 27px;
  padding-right: 0;
  padding-left: 0;
}

.support-detail-item-list {
  margin-top: 32px;
}

.support-detail-item-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.support-detail-item-title {
  color: $dark-grey-color;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 16px;

  &-no-margin-bottom {
    margin-bottom: 0;
  }
}

.support-detail-item-value {
  color: $surface-black-color;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

// -- Messages
.support-detail-messages-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: 27px;
  padding-right: 0;
  padding-left: 0;
  max-height: 100%;
  overflow-y: hidden;
}

.support-detail-accordion-title-gray {
  color: $grey-intense-color;
}

.support-detail-messages-list-container {
  margin: 0;
}

.support-detail-messages-list-item {
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
}

.support-detail-scroll-down-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 100;
  background-color: $background-color;
  border: none;
}

.support-detail-scroll-up-button {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 100;
  background-color: $background-color;
  border: none;
  opacity: 1;
}

// No messages
.support-detail-nomessages-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;
  height: 100%;
}

.support-detail-nomessages-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.support-detail-nomessages-title {
  color: $grey-intense-color;
  margin-bottom: 24px;
  text-align: center;
}

.support-detail-reply-input {
  margin-bottom: 0;
}

.support-detail-send-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
