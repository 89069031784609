.header-box {
  margin: auto;
  border: 1px solid $warning-color;
  text-align: center;
  width: 40%;
  padding: $spacing-a-smaller;
  border-radius: $border-radius;
  min-width: 400px;
}

.body-text-wrapper {
  margin: auto;
  text-align: center;
  padding: $spacing-a-small $spacing-a-small 0 $spacing-a-small;
  min-width: 400px;

  .title {
    padding: $spacing-a-xsmall;
  }

  .body-text {
    padding: $spacing-a-medium $spacing-a-biggest $spacing-a-small
      $spacing-a-biggest;
    text-align: justify;
    white-space: pre-line;
  }
}

.tac-footer {
  .accept-btn {
    // width: 205px;
    margin: auto;
    min-width: 205px;
  }

  .kinetikos-logo {
    width: 235px;
    margin: auto;
    margin-top: $spacing-a-large;
  }
}
