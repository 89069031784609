.card-container {
  width: 143px;
  min-width: 143px;
  max-height: 162px;
  min-height: 100px;
}

.license-card {
  width: 117px;
  max-width: 117px;
  box-shadow: 0px 3px 12px rgba(37, 89, 134, 0.43);
  background-color: #fdfdfe;
  border-radius: 5px;
  padding: 8px;
  font-size: 12px;
  text-align: center;
  font-family: "Inter-Regular";
  color: #071e31;
  margin: 0 auto;
  margin-top: $spacing-a-small;
  margin-bottom: $spacing-a-small;

  .hr-border {
    border: 1px solid #eff3f6;
    opacity: 0.5;
    margin: 8px 0;
  }

  .license-limit {
    padding: 4px;
  }

  .available-license {
    font-size: 16px;
  }
}

.active-license {
  background-color: #508cc0;
  box-shadow: 0px 3px 12px rgba(37, 89, 134, 0.43);
  color: #eff3f6;

  .hr-border {
    border: 1px solid #eff3f6;
    opacity: 1;
    margin: 8px 0;
  }
}

.disable {
  color: #4c657abd;
}
