.profile-body {
  height: 100vh;
  padding: 5px $spacing-a-medium $spacing-a-medium $spacing-a-medium;
  overflow: auto;
}

.personal-data-container {
  margin-left: 60px;

  @include media-breakpoint-up(md) {
    margin-left: 0;
  }
}

.profile-mandatory-fields-wrapper {
  padding-top: 26px;
  padding-bottom: 46px;
}

.profile-institution-photo {
  width: 295px;
  height: 295px;
  object-fit: cover;
  border: 1px solid $background-color;
  border-radius: $border-radius;
  @include drop-shadow(0, 2px, 4px, -1px, 0.12);
}
.profile-personal-data {
  overflow: auto;
  width: 100%;
  padding: $spacing-a-medium;
  border-radius: $border-radius;
  border: 2px solid $background-color;
  .header {
    align-items: center;
    .name {
      color: $base-color;
      font-size: $font-s-xbig;
      font-family: $font-family-extra-bold;
    }
    .edit-button {
      cursor: pointer;
      font-size: $font-s-regular;
      padding: 8px 30px;
      line-height: 14px;
    }
  }
  .body {
    padding-top: 50px;
    padding-bottom: 12px;
    margin-left: 2px;
    .left-wrapper {
      margin-top: 22px;
      margin-right: $spacing-a-medium;
      .username-image-wrapper {
        display: flex;
        flex-direction: column;
        width: 110px;
        .profile-image {
          border-radius: $border-radius;
          width: 110px;
          height: 110px;
        }
        .username {
          text-align: center;
          word-wrap: break-word;
          margin-top: 30px;
          @extend .regular-text;
        }
      }
    }
  }
}

.profile-institutions {
  padding-top: 76px;
  .body {
    padding-top: 25px;
    padding-bottom: 25px;
    .institution-wrapper {
      flex-wrap: wrap;
      display: flex;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 5px;
      border-radius: $border-radius;
      align-items: center;
      justify-content: space-between;
      background-color: $background-color;
      margin-bottom: $spacing-a-small;
      .photo-name-wrapper {
        @extend .col;
        display: flex;
        flex-direction: row;
        align-items: center;
        .institution-photo {
          width: $spacing-a-medium;
          height: $spacing-a-medium;
          margin-right: $spacing-a-small;
          border-radius: $border-radius;
          @include drop-shadow(0, 2px, 4px, -1px, 0.12);
        }
        .institution-name {
          font-family: $font-family-regular;
          font-size: $font-s-medium;
          color: $surface-black-color;
        }
      }
      .options-default-wrapper {
        @extend .col;
        padding-right: 0;
        align-items: center;
        flex-direction: row;
        display: flex;
        justify-content: flex-end;
        .default-text {
          margin-right: 5px;
          @extend .regular-text;
        }
      }
    }
  }
}

.profile-institution {
  padding-top: 50px;
  .header-wrapper {
    align-items: center;
    padding-bottom: $spacing-a-smallest;
    border-bottom: 2px solid $background-color;
  }
}

.institution-form-body-wrapper {
  flex-wrap: wrap;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: $spacing-a-medium;
  padding-right: $spacing-a-medium;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  border-left: 2px solid $background-color;
  border-right: 2px solid $background-color;
  border-bottom: 2px solid $background-color;

  .drop-down-wrapper {
    max-width: 450px;
  }
}

.institution-form-body-wrapper-2 {
  flex-wrap: wrap;
  padding: $spacing-a-medium;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  border-left: 2px solid $background-color;
  border-right: 2px solid $background-color;
  border-bottom: 2px solid $background-color;
}

.package-plan {
  border-radius: $border-radius;
  background-color: $background-color;
  @include drop-shadow(0, 2px, 4px, -1px, 0.12);
  padding-top: $spacing-a-xsmall;
  padding-bottom: $spacing-a-medium;
  padding-right: $spacing-a-small;
  padding-left: $spacing-a-small;
  .title {
    font-family: $font-family-bold;
    font-size: 18px;
    color: $base-color;
  }
  .contact-for-pricing {
    margin-top: 8px;
    font-family: $font-family-light;
    color: $surface-black-color;
    font-size: $font-s-medium;
  }
  .body {
    overflow-y: auto;
    height: 290px;
  }
  .plan-feature {
    display: flex;
    flex-direction: row;
  }
  .plan-comment {
    text-align: center;
    color: $base-color;

    &:empty::after {
      content: "\200b";
    }
  }

  .contact-button {
    @extend .tertiary-button;
    background-color: transparent;
  }
}

.collaborators-items-list {
  li {
    @include list-item($spacing-a-smaller);
    &:last-child {
      border-bottom: none;
    }
  }
}

.profile-view-institution-body {
  padding-top: $spacing-a-medium;
  .colaborators-box-wrapper {
    @include box(19px);
  }
  .contact-info {
    .title {
      @extend .regular-text;
      @extend .base-color;
    }
    .contact {
      margin-top: 6px;
      @extend .regular-text;
      @extend .black-color;
      .at-sign {
        font-family: $font-family-semi-bold;
        font-size: $font-s-regular;
        color: $base-color;
      }
    }
  }
  .profile-image {
    width: 295px;
    height: 295px;
    object-fit: cover;
    border: 1px solid $background-color;
    border-radius: $border-radius;
    @include drop-shadow(0, 2px, 4px, -1px, 0.12);
  }
  .plan-wrapper {
    align-items: center;
    justify-content: center;
    padding: 3.5px 12px;
    border-radius: 50px;
    &.dashboard {
      background-color: $primary-color;
      color: $surface-white;
    }
    &.clinik {
      background-color: $secondary-color;
      color: $surface-white;
    }
    &.clinikPro,
    .premium {
      background-color: #eede4d;
    }
  }
}

.profile-privacy {
  padding-top: 90px;
  display: block;
  .privacy-box {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    display: flex;
    margin-bottom: $spacing-a-medium;
    padding: $spacing-a-small;
    border-radius: $border-radius;
    border: 2px solid $background-color;
    .title {
      vertical-align: middle;
      font-size: $font-s-medium;
      font-family: $font-family-bold;
      color: $base-color;
    }
  }
}

.profile-security {
  padding-top: 73px;
  .password-wrapper {
    border-radius: $border-radius;
    border: 2px solid $background-color;
    padding-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 140px;
    .password-body {
      padding-top: $spacing-a-medium;
      padding-bottom: 4px;
    }
  }
  .change-password-wrapper {
    @include drop-shadow(0, 2px, 4px, -1px, 0.12);
    border-radius: $border-radius;
    border: 2px solid $background-color;
    padding: 15px;
    .change-password-body {
      padding-top: $spacing-a-small;
      .inputs-wrapper {
        padding-top: $spacing-a-medium;
        padding-bottom: $spacing-a-smallest;
      }
    }
  }
  /* @include media-breakpoint-up(lg) {
    .password-wrapper,
    .change-password-wrapper {
      width: 100%;
    }
  }
  @include media-breakpoint-up(xl) {
    .password-wrapper,
    .change-password-wrapper {
      width: 50%;
    }
  } */
  .password-footer {
    @extend .flex-d-row;
    justify-content: flex-end;
  }
}

.profile-evaluation-templates {
  // min-width: 350px;
  padding-top: 76px;
  .search-filter-wrapper {
    padding-top: $spacing-a-xsmall;

    .filter-by-text {
      @extend .regular-text;
      margin-top: $spacing-a-smallest;
      color: $grey-intense-color;
      text-align: right;
    }
  }

  .evaluation-templates-list {
    padding-right: $spacing-a-small;
    padding-left: $spacing-a-small;
    padding-top: $spacing-a-medium;
    .template-label-wrapper {
      padding-bottom: $spacing-a-small;
    }
    .template-info-wrapper {
      @include list-item(5px);
      .favourite-text {
        @extend .regular-text;
        margin-right: $spacing-a-xsmall;
      }
    }
  }
}

.profile_create_edit_templates {
  padding-top: 76px;
  .input-title-wrapper {
    @extend .input-image-wrapper;
    margin: 0;
    width: 320px;
  }

  .save-cancel-wrapper {
    margin-top: 70px;
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    button {
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .modules-body {
    margin-top: 24px;
  }
}

.profile-notifications-box {
  align-items: center;
  border-radius: $border-radius;
  padding: $spacing-a-small;
  flex-wrap: wrap;
  border: 2px solid $background-color;
  @include media-breakpoint-up(xs) {
    width: fit-content;
  }

  @include media-breakpoint-up(lg) {
    width: 725px;
  }
}

.profile-notifications-input-group-plus-minus {
  @extend .input-group;
  width: auto;
  align-items: center;
  .plus-minus {
    border: 1px solid $background-color;
    height: 32px;
    width: 48px;
    color: $base-color;
    background-color: $background-color;
  }
  .button-minus {
    border-radius: 5px 0 0 5px;
  }
  .button-plus {
    border-radius: 0 5px 5px 0;
  }
  .quantity-field {
    height: 32px;
    width: 56px;
    border: 1px solid $background-color;
    -moz-appearance: textfield;
    -webkit-appearance: none;
    font-size: $font-s-medium;
    color: $dark-grey-color;
  }
}

.profile-notification-patient-box {
  width: 100%;
  padding-top: $spacing-a-medium;
  padding-bottom: $spacing-a-medium;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  border-left: 2px solid $background-color;
  border-right: 2px solid $background-color;
  border-bottom: 2px solid $background-color;
  .header {
    @extend .regular-text;
    color: $surface-black-color;
    margin-left: $spacing-a-small;
    margin-right: $spacing-a-small;
  }
  .patients {
    overflow-y: scroll;
    height: 200px;
    .patient {
      flex-wrap: wrap;
      margin-left: $spacing-a-smallest;
      margin-right: $spacing-a-smallest;
      border-radius: $border-radius;
      padding: 8px 12px;
      border-bottom: 1px solid $background-color;
      &.active {
        border: none;
        background-color: $background-color;
      }
      &:last-child {
        border: none;
      }
    }
  }
}

.profile-notification-patient-wrapper-left {
  @include media-breakpoint-up(lg) {
    padding-right: 0;
  }
}

.profile-notification-patient-wrapper-right {
  @include media-breakpoint-up(lg) {
    padding-left: 0;
  }
}

.profile-notification-customization {
  @include drop-shadow(0, 2px, 4px, -1px, 0.12);
  overflow: auto;
  height: 90vh;
  width: 90vw;
  background-color: rgba($color: $surface-white, $alpha: 1);
  padding: 24px 32px;
  border-radius: $border-radius;
}

.horizontal-line {
  width: 100%;
  padding-bottom: $border-radius;
  border-bottom: 2px solid $background-color;
}

.search-input-loading {
  position: absolute;
  right: $spacing-a-xsmall;
  top: $spacing-a-small;
}

.radio-container {
  // width: 35%;
  // margin: 0 auto;
  @extend .license-card;
  background-color: #ffff;
  box-shadow: none;
}

.license-body {
  width: 64%;
  .border-box {
    border: 2px solid $background-color;
    border-radius: 4px;
    padding: $spacing-a-medium;
    width: 100%;
    font-size: $spacing-a-smaller;
    font-family: "Inter-Regular";
    color: $surface-black-color;
    min-width: 200px;
  }

  .box-footer {
    display: flex;
    flex-direction: row;
    justify-content: right;
  }

  .button-container {
    min-width: 63px;
  }

  .tabs {
    border-bottom: 2px solid $background-color;
    span {
      margin-left: $spacing-a-smallest;
      cursor: pointer;
    }
    .tab-active {
      border-bottom: 2px solid $base-color;
      animation: fadeInFromNone 0.5s ease-out;
    }

    @keyframes fadeInFromNone {
      0% {
        border-bottom: none;
        --border-size: 0;
        opacity: 0;
      }

      1% {
        border-bottom: 2px solid $base-color;
        opacity: 0;
      }

      100% {
        border-bottom: 2px solid $base-color;
        --border-size: 2px;
        opacity: 1;
      }
    }
  }
}

.email-validation-img {
  min-width: 48px;
}

.email-validation {
  color: $dark-grey-color;
  padding: $spacing-a-small;

  .email-list {
    padding: $spacing-a-smallest;
    color: $surface-black-color;
    border-bottom: 1px solid $background-color;
  }
}
.loading-container-btn {
  width: 54px;
  height: 14px;
}

.manage-patients-wrapper {
  width: 86%;
  .pointer {
    cursor: pointer;
  }
  .manage_patients_header {
    justify-content: center;
    align-items: center;
  }
  .title-wrapper {
    width: 97%;
  }
  .more-btn {
    opacity: 0.4;
    pointer-events: none;
  }
  .more-btn-active {
    opacity: 1;
    pointer-events: all;
  }
  .patients-list {
    display: flex;
    flex-direction: column;
    padding: $spacing-a-smaller;
    padding-bottom: $spacing-a-medium;
    .patients-info-wrapper {
      align-items: center;
      border-bottom: 1px solid $background-color;
      // padding-bottom: 10px;
      // padding-top: $spacing-a-xsmall;
    }
    .order-img-container {
      align-items: center;
      background-color: $surface-white;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 2px;
      height: 14px;
      justify-content: center;
      margin-left: 4px;
      width: 14px;

      .active {
        background-color: $background-color;
      }
    }
    .check-box-width {
      width: 4%;
      font-size: medium;
    }

    .tags-wrapper {
      align-items: center;
      -webkit-column-gap: $spacing-a-smallest;
      column-gap: $spacing-a-smallest;
      display: flex;
      flex-wrap: wrap;
      row-gap: $spacing-a-smallest;

      .tag-container {
        border-radius: 50px;
        padding: 3.5px 12px;
      }

      .expired {
        background-color: $background-color;
      }

      .pending {
        background-color: #f8e3bc;
      }
      .expiring-soon {
        background-color: #f5bd84;
        color: $surface-white;
      }
      .in-use {
        background-color: $secondary-color;
        color: $surface-white;
      }
    }
  }
  .license-type {
    justify-content: center;
  }

  .loading-container {
    height: 200px;
  }

  .renew-licence-modal {
    padding-bottom: $spacing-a-smaller;
    overflow: scroll;
    max-height: 200px;

    .title {
      padding-bottom: $spacing-a-smaller;
    }
    .licence-row {
      align-items: center;
      border-bottom: 1px solid $background-color;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  .no-auto-renew {
    background-color: #f3aca7;
  }

  .auto-renew-bg {
    background-color: #c2e0b3;
  }
}

.first-consent-container {
  display: flex;
  gap: $spacing-a-small;

  .check-box {
    input:checked:after {
      color: white;
      content: "\2713";
      text-align: center;
      position: relative;
      display: block;
      top: 1px;
      left: 0;
      font-size: large;
    }
  }
}

.main-tabs {
  border-bottom: 2px solid $background-color;
  span {
    margin-left: $spacing-a-smallest;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
  }
  .tab-active {
    color: #255986;
    border-bottom: 2px solid $base-color;
    animation: fadeInFromNone 0.5s ease-out;
    font-weight: 600;
  }

  @keyframes fadeInFromNone {
    0% {
      border-bottom: none;
      --border-size: 0;
      opacity: 0;
    }

    1% {
      border-bottom: 2px solid $base-color;
      opacity: 0;
    }

    100% {
      border-bottom: 2px solid $base-color;
      --border-size: 2px;
      opacity: 1;
    }
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .license-body {
    width: 100%;
  }
  .licenses-left {
    display: none;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .license-body {
    width: 100%;
  }
  .licenses-left {
    display: none;
  }
}
