.not-found-container {
  justify-content: center;
  position: relative;
  margin-top: 32px;

  .container-head {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -55px;
    color: $base-color;

    .big-text {
      font-size: 66px;
    }
  }

  .container-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 32px;

    img {
      max-width: 100%;
    }
  }
}
