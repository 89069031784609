.support-edit-container {
  display: flex;
  flex: 1;
}

// Header
.support-edit-header-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 0;
}

.support-edit-header-title {
  width: 100%;
  margin-top: 0.5rem;
}

.support-edit-goback-icon {
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-right: 12px;
}

.support-edit-header-actions-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0;
}

.support-edit-cancel-button {
  font-size: 13px;
  text-decoration: underline;

  &:hover {
    text-decoration: unset;
  }
}

// Details
.support-edit-details-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 27px;
  padding-right: 0;
  padding-left: 8px;
}

.support-edit-item-list {
  margin-top: 22px;
}

.support-edit-item-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.support-edit-item-title {
  color: $dark-grey-color;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 16px;
}

.support-edit-item-value {
  color: $surface-black-color;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.support-edit-input-field {
  background-color: $background-color;
  border-radius: 5px;
  border: none;
  height: 108px;
  padding: 12px 15px;
  font-size: 12px;
  resize: none;

  &-single-line {
    height: 34px;
    padding: 9px 15px;
    overflow: auto;
  }

  &-double-line {
    height: 65px;
  }
}
