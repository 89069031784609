.evaluations-module-wrapper {
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;

  .evaluations-module-container {
    display: flex;
    flex-direction: column;
    background: $surface-white;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
    border-radius: $border-radius;
    padding: $spacing-a-xsmall;

    .search-wrapper {
      margin-top: $spacing-a-xsmall;
    }

    .eval-icon {
      width: 18px;
      height: 20px;
      margin-right: $spacing-a-smaller;
    }

    button {
      display: flex;
      align-items: center;
    }

    .evaluations-list {
      padding-right: $spacing-a-small;
      padding-left: $spacing-a-small;
      padding-top: $spacing-a-medium;

      .eval-list-labels-wrapper {
        padding-bottom: $spacing-a-small;

        .order-img-container {
          margin-left: 4px;
          height: 14px;
          width: 14px;
          background-color: $surface-white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 2px;
          border-radius: 50%;
          cursor: pointer;

          &.active {
            background-color: $background-color;
          }

          img {
            height: 4px;
            width: 6px;
          }
        }
      }

      .eval-info-wrapper {
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $background-color;

        &:last-child {
          border-bottom: none;
        }

        .pointer {
          cursor: pointer;
        }

        .tags-wrapper {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          column-gap: $spacing-a-smallest;
          row-gap: $spacing-a-smallest;

          .tag-container {
            border-radius: $border-radius2;
            background-color: $background-color;
            padding: 3.5px $spacing-a-smaller;
          }
        }
      }

      .loading-wrapper {
        flex-direction: row;
        display: flex;
        position: relative;
        margin-top: 75px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.create-eval-modal-wrapper {
  display: flex;
  background-color: rgba($color: $base-color, $alpha: 0.2);
  margin: auto;
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  .create-eval-modal-sidebar-margin {
    width: 256px;
    height: 100%;
  }

  .create-eval-modal-shadow {
    flex: 1;
    display: flex;
    z-index: 4;
    align-items: center;
    justify-content: center;

    .create-eval-modal-container {
      max-height: 90%;
      overflow-y: auto;
      @include drop-shadow(0, 2px, 4px, -1px, 0.12);
      background-color: rgba($color: $surface-white, $alpha: 1);
      padding: 20px 31px;
      border-radius: $border-radius;

      @include media-breakpoint-up(sm) {
        width: 400px;
      }

      @include media-breakpoint-up(lg) {
        width: 600px;
      }

      .title-text {
        font-family: $font-family-bold;
        font-size: $font-s-medium;
        color: $base-color;
        margin-bottom: 39px;
      }

      .pointer {
        cursor: pointer;
      }

      .tags-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: $spacing-a-smaller;
        row-gap: $spacing-a-smaller;
        margin-bottom: $spacing-a-medium;

        .tag-container {
          border-radius: $border-radius2;
          background-color: $background-color;
          padding: 3.5px $spacing-a-smaller;
          transition: 0.3s;

          &:hover {
            background-color: $primary-hover-color;
            color: $surface-white;
          }

          &.active {
            background-color: $primary-color;
            color: $surface-white;
          }
        }
      }

      .footer-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 10px;
      }
    }
  }
}
