.ev-mod-date-input-container {
  font-family: $font-family-regular;
  font-size: $font-s-regular;
  color: $surface-black-color;
  line-height: 14.5px;

  min-width: 250px;
  max-width: 450px;

  display: flex;
  align-items: center;

  padding: $spacing-a-smallest $spacing-a-smaller;
  background-color: $background_color;
  border: 1px solid transparent;
  border-radius: $border-radius;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  position: relative;

  &:focus {
    background-color: $background-color;
    border: 1px solid $primary-color;
  }

  &:focus-within {
    background-color: $background-color;
    border: 1px solid $primary-color;
  }

  &.error {
    border: 1px solid $warning-color;
  }

  input {
    border: 0;
    background-color: transparent;
    display: flex;
    width: 3ch;

    &.year {
      width: 5ch;
    }
  }

  .date-input-select {
    z-index: 5;
    position: absolute;
    top: 115%;
    left: 0;

    width: 100%;
    background-color: $background-color;
    border-radius: $border-radius;
    border: 1px solid $primary-color;

    .react-calendar {
      max-width: 100%;
    }
    .react-calendar--doubleView {
      width: 700px;
    }
    .react-calendar--doubleView .react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;
    }
    .react-calendar--doubleView .react-calendar__viewContainer > * {
      width: 50%;
      margin: 0.5em;
    }
    .react-calendar,
    .react-calendar *,
    .react-calendar *:before,
    .react-calendar *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    .react-calendar button {
      margin: 0;
      border: 0;
      outline: none;
    }
    .react-calendar button:enabled:hover {
      cursor: pointer;
    }
    .react-calendar__navigation {
      display: flex;
      height: 44px;
      margin-bottom: 1em;
      font-family: $font-family-bold;
      text-transform: capitalize;
      font-size: $font-s-medium;
    }
    .react-calendar__navigation button {
      min-width: 44px;
      background: none;
      border-radius: $border-radius;
    }
    .react-calendar__navigation__label__labelText {
      display: inline-block;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: $background-hover-color;
    }
    .react-calendar__navigation button[disabled] {
      background-color: #f0f0f0;
    }
    .react-calendar__month-view__weekdays {
      text-align: center;

      font-size: $font-s-medium;
      text-transform: capitalize;
      margin-bottom: 16px;
      border-bottom: 1px solid $primary-color;

      abbr {
        cursor: default;
        text-decoration: none;
      }
    }
    .react-calendar__month-view__weekdays__weekday {
      padding: 0.5em;
    }
    .react-calendar__month-view__weekNumbers .react-calendar__tile {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75em;
      font-weight: bold;
      padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
    }
    .react-calendar__month-view__days__day--weekend {
      color: $surface-black-color;
    }
    .react-calendar__month-view__days__day--neighboringMonth {
      color: #757575;
    }
    .react-calendar__year-view .react-calendar__tile,
    .react-calendar__decade-view .react-calendar__tile,
    .react-calendar__century-view .react-calendar__tile {
      padding: 2em 0.5em;
    }
    .react-calendar__tile {
      max-width: 100%;
      text-align: center;
      padding: 0.75em 0.5em;
      background: none;
      border-radius: $border-radius;
      font-size: $font-s-medium;
      text-transform: capitalize;
    }
    .react-calendar__tile:disabled {
      background-color: #f0f0f0;
    }
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: $background-hover-color;
    }
    .react-calendar__tile--now {
      background: transparent;
    }
    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
      background: $background-hover-color;
    }
    .react-calendar__tile--hasActive {
      background: $primary-color;
      color: $surface-white;
    }
    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar__tile--hasActive:enabled:focus {
      background: $primary-hover-color;
      color: $surface-white;
    }
    .react-calendar__tile--active {
      background: $primary-color;
      color: $surface-white;
    }
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
      background: $primary-color;
    }
    .react-calendar--selectRange .react-calendar__tile--hover {
      background-color: $background-hover-color;
    }
  }

  .month-year-input-select {
    z-index: 5;
    position: absolute;
    top: 115%;
    left: 0;

    width: 100%;
    height: 240px;
    background-color: $background-color;
    border-radius: $border-radius;
    border: 1px solid $primary-color;
    display: flex;

    padding: 6px;

    .month-section {
      flex: 3;
      height: 100%;
      padding: 6px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
    }

    .year-section {
      flex: 1;
      height: 100%;
      padding: 6px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
    }

    .item-card {
      padding: $spacing-a-smallest 0;
      text-align: center;
      background-color: $background-color;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
      border-radius: $border-radius;
      min-height: 32px;
      text-transform: capitalize;

      margin-bottom: 4px;

      cursor: pointer;

      &:hover:not(.selected) {
        background-color: $background-hover-color;
      }

      &.selected {
        background-color: $primary-color;
        color: $surface-white;
        box-shadow: 0;
      }
    }
  }
}
