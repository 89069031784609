@mixin input(
  $background_color,
  $text_color: $surface-white,
  $border_color: transparent
) {
  padding: 8px 12px;
  line-height: 14.5px;
  border-radius: $border-radius;
  background-color: $background_color;
  font-family: $font-family-regular;
  font-size: $font-s-regular;
  color: $text_color;
  border: 1px solid $border_color;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &.error {
    border: 1px solid $warning-color;
  }
}

//GENERAL
.form-input {
  @include input($background-color, $surface-black-color);
  width: 100%;
  margin-bottom: $spacing-a-xsmall;
  &:focus {
    background-color: $background-color;
    border: 1px solid $primary-hover-color;
    box-shadow: none;
  }
}

.form-input-2 {
  @include input(transparent, $surface-black-color, $background-color);
  width: 100%;
  margin-bottom: $spacing-a-xsmall;
  &:focus {
    border: 1px solid $primary-hover-color;
    box-shadow: none;
  }
}

.form-input-3 {
  padding: 0px 4px;
  line-height: 14.5px;
  background-color: transparent;
  font-family: $font-family-regular;
  font-size: $font-s-medium;
  color: $base-color;
  border: none;
  border-bottom: 2px solid $background-color;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.search-input {
  @include input($background-color, $dark-grey-color);
  padding-left: 30px;
  &:focus {
    outline: none;
  }
}

.with-input-sizing {
  width: 100%;
  @include media-breakpoint-up(sm) {
    width: 320px;
  }
}

textarea:focus,
input:focus {
  outline: none;
}

input:placeholder-shown {
  text-overflow: ellipsis;
}

// CHECKBOXS

.check-box-wrapper {
  flex-direction: row;
  display: flex;
  padding-bottom: $spacing-a-small;
  align-items: center;
  margin-top: 3px;
  .input-wrapper {
    align-items: center;
    flex-direction: row;
    display: flex;
  }
}

input[type="checkbox"] {
  &:not(.form-check-input) {
    vertical-align: middle;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-color: $background-color;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    border: none;
    box-shadow: none !important;
    border-radius: $border-radius-small;
    position: relative;
    &.error {
      border: 1px solid $warning-color;
    }
    &:checked {
      background-color: $primary-hover-color;
      border: none;
      // .grey {
      //   background-color: #eff3f6;
      // }
      // .orange{

      // }
    }
  }
}

label[type="checkbox"] {
  display: inline-block;
  margin-left: 12px;
  line-height: 10px;
}

//SWITCH
.form-switch .form-check-input {
  height: 24px;
  width: 44px;
  cursor: pointer;
  background-color: $grey-intense-color;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
  border: none;
}
.form-switch .form-check-input:focus {
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");

  // background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>");
}
.form-switch .form-check-input:checked {
  background-color: $primary-color;
  border-color: $primary-color;
  border: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}

input[type="checkbox"]:not(.form-check-input):checked:after {
  color: white;
  content: "\2713";
  text-align: center;
  position: absolute;
  display: block;
  left: 3px;
  top: -3px;
}

.input-image-wrapper {
  width: auto;
  position: relative;
  margin-bottom: $spacing-a-xsmall;
  .form-input,
  .form-input-2,
  .form-input-3 {
    margin-bottom: 0;
  }
  &.image-right {
    input {
      padding-right: 30px;
    }
  }
}

//PHONE

.PhoneInput {
  width: 100%;

  &.PhoneInput--focus {
    box-shadow: none;
  }

  .PhoneInputCountry {
    margin-right: $spacing-a-small;
    border-radius: 5px;
    border: 0 !important;
    @include input($background-color, $surface-black-color);
  }

  .PhoneInputInput {
    border: 0;
    padding-left: 50px !important;
    border-radius: 5px;
    background-color: transparent;
    @include input($background-color, $surface-black-color);
  }

  .PhoneInputCountryIcon {
    position: relative;
    border: none;
    width: 25px;
    height: 16px;
    box-shadow: none;
  }

  .PhoneInputCountryIcon--border {
    box-shadow: none !important;
  }

  .PhoneInputCountrySelectArrow {
    border-color: $dark-grey-color !important;
    color: $dark-grey-color !important;
    border-bottom-width: 2px !important;
    border-right-width: 2px !important;
    width: 9px;
    height: 9px;
    margin-left: 6px;
    opacity: 1 !important;
  }
}

.countryCode {
  position: absolute;
  left: 90px;
  top: 50%;
  transform: translateY(-50%);

  p {
    font-size: 10px;
    margin-bottom: 0;
    color: #4c657a;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $grey-intense-color;
  font-size: $font-s-small;
  text-overflow: ellipsis;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $grey-intense-color;
  font-size: $font-s-small;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $grey-intense-color;
  font-size: $font-s-small;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* don't show the eye for password inputs */
::-ms-reveal {
  width: 0;
  height: 0;
}
