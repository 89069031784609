.profile-sidebar {
  background-color: $background-color;
  padding: 15px 15px;
  width: 250px;
  overflow-y: auto;
  display: none;
  flex-direction: column;
  height: 100vh;
  border-radius: 0px 20px 20px 0px;
  z-index: 10; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;

  &.show {
    display: flex;
    position: absolute;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    position: relative;
    padding: 15px 25px;

    &.show {
      position: relative;
    }
  }

  .logo-primary {
    height: 45px;
    width: auto;
  }
  .back-dashboard-wrapper {
    padding-top: 30px;
  }

  .back-home-container {
    padding-top: 35px;
    padding-bottom: 25px;
    border-bottom: 0.5px solid $base-color;
  }
  .delete-container {
    width: 100%;
    padding-bottom: 5px;
    border-bottom: 0.5px solid $base-color;
  }

  .contact-container {
    padding-top: 5px;
  }

  .options-container {
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 39px;
    padding-bottom: 39px;
    display: inline-block;
  }
  button {
    width: 100%;
    text-align: start;
    padding-right: 12px;
    img {
      height: 20px;
      width: 20px;
      margin-right: 15px;
      margin-bottom: 2px;
    }
    @include button($font-family-regular, transparent, $base-color);
    @extend .small-button;
    &.active {
      background-color: $base-color;
      color: $surface-white;
    }

    &:hover:not(.active):not([disabled]) {
      background-color: $background-hover-color;
    }
  }
}
