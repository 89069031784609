.checkbox-container {
  display: flex;
  flex-direction: column;
  font-family: $font-family-regular;
  font-size: $font-s-regular;

  .checkbox-question {
    color: $dark-grey-color;
    margin-bottom: $spacing-a-small;
  }

  .checkbox-options-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: -$spacing-a-smaller;

    &.vertical {
      flex-direction: column;
    }

    .checkbox-option-container {
      margin-top: $spacing-a-smaller;
      margin-right: $spacing-a-medium;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $surface-black-color;
      position: relative;

      .option-box {
        height: 24px;
        width: 24px;
        border-radius: $border-radius;
        background-color: $background-color;

        display: flex;
        justify-content: center;
        align-items: center;

        margin-left: $spacing-a-small;

        &:hover:not(.disabled):not(.checked) {
          background-color: $background-hover-color;
        }

        &.checked {
          background-color: $primary-color;
        }
      }

      &.disabled {
        cursor: auto;
        opacity: 0.6;
      }
    }
  }
}
