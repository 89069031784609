.chatbox-container {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding-bottom: 14px;

  &.right-aligned {
    justify-content: flex-end;
  }
}

.message-container {
  display: flex;
  flex-direction: column;
  margin-left: 12px;

  &.right-aligned {
    margin-right: 12px;
  }
}

.message {
  background-color: $background-color;
  border-radius: 5px;
  width: 413px;
  padding: 10px 16px;

  &.right-aligned {
    background-color: $primary-color;
  }
}

.message-title {
  margin-bottom: 0;
  font-size: 12px;

  &.right-aligned {
    color: $surface-white;
  }
}

.message-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $background-color;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.message-sentdate {
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
  font-size: 9px;
  color: $dark-grey-color;
}
