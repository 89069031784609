.drop-list-container {
  font-family: $font-family-regular;
  font-size: $font-s-regular;
  color: $surface-black-color;

  min-width: 250px;
  max-width: 450px;
  background-color: $background-color;
  border-radius: $border-radius;
  border: 1px solid transparent;
  padding: $spacing-a-smallest/2 $spacing-a-small;

  display: flex;
  align-items: center;

  position: relative;

  &:focus {
    border: 1px solid $primary-color;
  }
  .drop-list-selected-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    overflow-x: auto;

    .drop-list-selected-container {
      display: flex;
      align-items: center;
      padding: $spacing-a-smallest/2 $spacing-a-smaller;
      border-radius: $border-radius2;
      margin-right: $spacing-a-smallest;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.multiple {
        background-color: $background-hover-color;
      }

      .d-l-remove-selected {
        margin-left: $spacing-a-smallest;
        cursor: pointer;
      }
    }
  }

  .drop-list-options-container {
    z-index: 5;
    position: absolute;
    top: 110%;
    left: 0;

    width: 100%;
    max-height: 200px;
    background-color: $background-color;
    border-radius: $border-radius;

    padding: $spacing-a-smallest;

    display: flex;
    flex-direction: column;

    overflow-y: auto;

    .d-l-option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $spacing-a-smallest;
      cursor: pointer;

      &.selected {
        color: $base-color;
      }

      &:hover {
        background-color: $background-hover-color;
        border-radius: $border-radius;
      }
    }
  }
}
