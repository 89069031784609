//Vendor
@import "vendor/__vendor-dir";

//Abstracts
@import "abstracts/__abstracts-dir";

//Base Styles
@import "base/__base-dir";

//Components
@import "components/__components-dir";

//Layout
@import "layouts/__layouts-dir";

//Pages
@import "pages/__pages-dir";

.body-wrapper {
  height: 100%;
  display: flex;
  padding-top: $spacing-a-xmedium;
  padding-bottom: $spacing-a-xmedium;
}

a {
  text-decoration: none;
}
