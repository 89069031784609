.patient-sidebar {
  background: linear-gradient(180deg, #255986 0%, #114575 100%);
  padding: 15px 15px;
  width: 256px;
  overflow-y: auto;
  display: none;

  &.show {
    display: flex;
    position: absolute;
  }

  flex-direction: column;
  align-items: flex-start;
  height: 100vh;
  color: $surface-white;
  border-radius: 0px 20px 20px 0px;
  z-index: 10; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;

  @include media-breakpoint-up(md) {
    display: flex;
    position: relative;
    padding: 15px 25px;

    &.show {
      position: relative;
    }
  }

  a {
    text-decoration: none;
    cursor: auto;
  }

  .logo {
    height: 45px;
    width: auto;
    margin-bottom: $spacing-a-medium;
  }

  .back-home-container {
    margin-bottom: $spacing-a-medium;
    width: 100%;

    button {
      padding-left: $spacing-a-xsmall;
      &:hover {
        background-color: $base-color;
        color: $surface-white;
        border-radius: 5px;
      }
    }
  }

  .patient-avatar-name {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 64px;
      width: 64px;
      border-radius: 50%;
      margin-bottom: $spacing-a-small;
      filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.15));
    }

    .patient-name {
      font-family: $font-family-medium;
      font-size: $font-s-big;
      text-align: center;
      margin-bottom: $spacing-a-small;
    }
  }

  .patient-info-wrapper {
    align-self: center;
    display: flex;
    flex-direction: column;
    font-size: $font-s-medium;
    font-family: $font-family-regular;
    border-radius: $border-radius;
    border: 1px solid $surface-white;
    color: $surface-white;

    &.closed {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: $spacing-a-small;
      margin-bottom: $spacing-a-medium;
      padding: $spacing-a-smallest $spacing-a-smaller;
      font-size: $font-s-regular;

      img {
        margin-right: $spacing-a-smaller;
        height: 12px;
        width: 12px;
      }
    }

    &.open {
      align-self: stretch;
      margin-top: 0;
      margin-right: -9px;
      margin-left: -9px;
      padding-left: $spacing-a-xsmall;
      padding-right: $spacing-a-xsmall;
      padding-bottom: $spacing-a-medium;
      background-color: $base-color;
      box-shadow: 0px 3px 4px rgba(1, 61, 114, 0.8);
      border-radius: $border-radius1;

      .closed {
        margin-bottom: 0;
      }

      .info-field-title {
        font-family: $font-family-bold;
        font-size: $spacing-a-smaller;
        color: $background-color;
        opacity: 0.6;
        margin-top: $spacing-a-medium;
      }

      .info-field-text {
        margin-top: $spacing-a-smallest;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .medical-conditions {
          white-space: normal;
        }
      }
    }
  }

  .patient-nav-section {
    align-self: stretch;
    border-top: 0.5px solid $surface-white;
    padding-top: $spacing-a-small;
    padding-bottom: $spacing-a-small;

    .section-title {
      flex: 1;
      color: rgba(253, 253, 254, 0.5);
      font-size: $font-s-small;
      font-family: $font-family-semi-bold;
      margin-bottom: $spacing-a-small;
    }

    .section-item {
      margin-bottom: $spacing-a-smallest;

      &:hover:not(.active) {
        background-color: $base-color;
        color: $surface-white;
        // img {
        //   filter: brightness(0) saturate(100%) invert(28%) sepia(94%)
        //     saturate(397%) hue-rotate(168deg) brightness(89%) contrast(94%);
        // }
      }
    }

    .programs-expandable-container {
      background-color: $base-color;
      border-radius: 5px;
      height: 0px;
      overflow: hidden;
      transition: 0.3s;

      &.open {
        padding-top: 8px;
        padding-bottom: 8px;
        margin-bottom: 8px;
        height: 90px;
      }
    }

    .medicines-expandable-container {
      background-color: $base-color;
      border-radius: 5px;
      height: 0px;
      overflow: hidden;
      transition: 0.3s;

      &.open {
        padding-top: 8px;
        padding-bottom: 8px;
        margin-bottom: 8px;
        height: 48px;
      }
    }

    .btn-follow {
      font-size: $font-s-regular;
      font-family: $font-family-regular;
      border-radius: $border-radius;
      border: 1px solid $surface-white;
      color: $surface-white;
      cursor: pointer;
      margin-top: -5px;
      margin-bottom: $spacing-a-small;
      padding: 4px $spacing-a-smaller;
    }

    .follow-tooltip {
      box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }

    img {
      margin-top: -2px;
      height: 18px;
      width: 18px;
      margin-left: 13px;
      cursor: pointer;

      &.collapse-img {
        margin-top: -2px;
        height: 22px;
        width: 26px;
        margin-left: 0px;
        margin-right: 0px;
        padding: 8px;
      }

      &.xmargin-img {
        margin-left: $spacing-a-xsmall;
      }
    }
  }

  .dissociate-wrapper {
    align-self: stretch;
    border-bottom: 0.5px solid $surface-white;
    padding-bottom: $spacing-a-xsmall;

    img {
      height: 24px;
      width: 24px;
      margin-right: 10px;
    }

    button {
      font-size: $font-s-medium;
    }
  }

  .delete-patient {
    &:hover {
      span {
        color: $surface-white !important;
      }
    }
  }

  .options-wrapper {
    width: 100%;
    position: relative;

    .right-arrow {
      height: 25px;
      width: 26px;
      margin-left: 0px;
      margin-right: 0px;
      padding: $spacing-a-smallest;
    }

    .options-content {
      position: absolute;
      width: 100%;
      padding: $spacing-a-smallest;
      background-color: $surface-white;
      border-radius: 5px;
      display: none;
      bottom: 50px;
      top: auto;
      // min-height: 122px;
      // max-height: 0;
      transition: all 0.3s ease-out;

      button {
        color: $surface-black-color;
        &:hover {
          background-color: $base-color;
          color: $surface-white;
          border-radius: 5px;
        }
      }
    }

    .options-open {
      display: block;
      animation: openAnimation 0.2s ease-in backwards;
    }

    .options-close {
      display: none;
    }

    .opitons-active {
      background-color: $base-color;
      color: $surface-white;
      border-radius: 5px;
    }

    .option-btn {
      button {
        padding-left: $spacing-a-xsmall;
      }
    }

    &:hover:not(.opitons-active) .option-btn {
      background-color: $base-color;
      color: $surface-white;
      border-radius: 5px;
    }

    @keyframes openAnimation {
      0% {
        transform: translateY(30%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  .bottom-border {
    align-self: stretch;
    border-bottom: 0.5px solid $surface-white;
    padding-bottom: $spacing-a-xsmall;
  }

  .contact-wrapper {
    width: 100%;
    padding-top: 5px;
    font-family: $font-family-medium;
    font-size: $font-s-medium;

    button {
      padding-left: $spacing-a-xsmall;
      &:hover {
        background-color: $base-color;
        color: $surface-white;
        border-radius: 5px;
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: start;
    padding-right: 12px;
    img {
      height: 18px;
      width: 18px;
      margin-right: 18px;
    }
    @include button($font-family-regular, transparent, $surface-white);
    @extend .small-button;
    &.active {
      background-color: $surface-white;
      color: $base-color;
    }
  }

  .autorenew-reminder {
    font-size: 12px;
    background-color: #f5bd84;
    border-radius: 5px;
    box-shadow: 0px 3px 4px rgba(1, 61, 114, 0.8);
    color: $surface-black-color;
    min-height: 29px;
    text-align: center;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
    margin-bottom: $spacing-a-smallest;

    .renew-btn {
      text-decoration: underline;
      cursor: pointer;
      color: $base-color;
    }

    .renew-btn.text-white {
      color: white;
    }
  }

  .autorenew-reminder.background-red {
    background-color: $warning-color;
  }

  .autorenew-reminder.text-white {
    color: white;
  }
}

.patient-sidebar-modals-wrapper {
  .message-modal {
    @include media-breakpoint-up(sm) {
      width: 540px;
    }
    @include media-breakpoint-up(lg) {
      width: 740px;
    }
  }
}
