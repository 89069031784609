.reports-page {
  .error-message-container {
    width: 50%;
    height: 60px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    background-color: $surface-white;
    z-index: 222;
    border-radius: $border-radius;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      width: 100%;
      text-align: left;
    }
  }

  .category-buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -($spacing-a-smallest);

    .category-container {
      cursor: pointer;
      @extend .regular-text;

      border-radius: $border-radius2;
      background-color: $surface-white;
      padding: 4px $spacing-a-small;
      border: 1px solid $base-color;
      margin: $spacing-a-smallest;
      &:hover {
        background-color: $primary-color;
        color: $surface-white;
      }
      &.active {
        background-color: $primary-color;
        color: $surface-white;
        &:hover {
          background-color: $primary-hover-color;
          color: $surface-white;
        }
      }
    }
  }
  .boxGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(128px, 1fr));
    grid-gap: 12px;
    .box {
      background: white;
      padding: 16px;
      box-shadow: 0 2px 5px 0 rgba(213, 213, 213, 0.2);
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-title {
        font-size: 13.5px;
        color: #535f7f;
        padding-bottom: 8px;
      }
      &-value {
        font-size: 14px;
        color: #3589e8;
      }
    }
  }
}
