.prescriptions-module-wrapper {
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;
}

.mkinetikos-prescriptions-modules-box {
  @include box(20px 32px);
  background-color: $surface-white;
  @include drop-shadow(0, 2px, 4px, -1px, 0.12);
  .prescription {
    @include list-item(8px);
    &.viewing {
      background-color: $background-color;
    }
    :last-child {
      border-bottom: none;
    }
    .show-hide-prescription {
      @extend .quaternary-button;
      padding: 7px;
      &.viewing {
        background-color: $surface-white;
      }
    }
  }
  .inactive:not(.dropdown-options) {
    opacity: 0.5 !important;
  }
}

.medicines-list-wrapper {
  border-radius: $border-radius;
  border: 1px solid $background-color;
  padding-top: $spacing-a-medium;
  padding-left: 10px;
  padding-right: 10px;
  .medicine {
    @include list-item(10px);
    :last-child {
      border-bottom: none;
    }
  }
}
