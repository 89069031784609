.navbar-wrapper {
  background-color: $base-color;
  padding: $spacing-a-smallest;
  justify-content: center;
  position: relative;
}

.nav-transparent {
  background-color: transparent;
  padding: 8px 0;
  justify-content: center;
}

.nav-bar-components {
  .items-container {
    padding: 8px 10px;
    flex-direction: row;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 5px;
    cursor: pointer;
    &.nav-blue {
      border: 1px solid $surface-white;
    }
    &.nav-white {
      border: 1px solid $base-color;
    }
  }
  .navbar-logo {
    width: auto;
    height: 44px;
  }

  .institution-logo {
    width: 36px;
    height: 36px;
    border-radius: $border-radius;
  }

  .profile-image {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  .institution-logo-listing {
    width: 30px;
    height: 30px;
    border-radius: $border-radius;
  }

  .back-button {
    border-bottom: 1px solid $background-color;
  }
}

.flash-msg-wrapper {
  background-color: #fdfdfe;
  box-shadow: inset 0px -1px 4px rgba(37, 89, 134, 0.19);
  font-size: $spacing-a-smaller;
  color: $surface-black-color;
  display: flex;
  align-items: center;
  animation-name: flash-msg, 0.5s, ease;

  img {
    width: $spacing-a-large;
    max-width: 10%;
    padding: $spacing-a-smaller;
    margin-left: $spacing-a-smaller;
  }

  .btn-wrapper {
    display: flex;
    justify-content: end;
  }
}

.risk-assessment-modal {
  display: flex;
  gap: 12px;
  align-items: center;

  img {
    width: $spacing-a-medium;
    height: $spacing-a-medium;
    border-radius: 50%;
  }
  .meta-info {
    font-size: 10px;
    color: $grey-intense-color;
    font-family: "Inter-Regular";
  }
}

@keyframes flash-msg {
  0% {
    top: -100px;
  }

  100% {
    top: 0px;
  }
}
