.mkinetikos-report {
  .axis {
    font-family: $font-family-regular;
    font-size: $font-s-small;
    color: $dark-grey-color;
  }

  .dot {
    cursor: pointer;
    width: 8px;
    height: 8px;
    &.test {
      fill: #508cc0;
    }
    &.survey {
      fill: #f5bd84;
    }
    &.triage_score {
      fill: #67d2a5;
    }
    &.medicines {
      fill: #d76d68;
    }
    &.events {
      fill: #7663e9;
    }
    &.tapping {
      fill: #ffd953;
    }
    &.tug {
      fill: #b382f1;
    }
    &.brady {
      fill: #66cde3;
    }
  }

  // .activity-area {
  //   fill: rgba($base-color, 0.2);
  // }

  .line {
    stroke-width: 2px;
    fill: none;
    &.test {
      stroke: #508cc0;
    }
    &.survey {
      stroke: #f5bd84;
    }
    &.triage_score {
      stroke: #67d2a5;
    }
    &.medicines {
      stroke: #d76d68;
    }
    &.events {
      stroke: #7663e9;
    }
    &.tapping {
      stroke: #ffd953;
    }
    &.tug {
      stroke: #b382f1;
    }
    &.brady {
      stroke: #66cde3;
    }
  }

  // .prescription-area {
  //   fill: rgba(#d76d68, 0.2);
  // }

  .exercise-line {
    stroke: #67d2a5;
  }

  .area {
    &.test {
      fill: rgba(#508cc0, 0.03);
    }
    &.survey {
      fill: rgba(#f5bd84, 0.03);
    }
    &.triage_score {
      fill: rgba(#64c1b4, 0.03);
    }
    &.medicines {
      fill: rgba(#d76d68, 0.03);
    }
    &.events {
      fill: rgba(#7663e9, 0.03);
    }
    &.tapping {
      fill: rgba(#ffd953, 0.03);
    }
    &.tug {
      fill: rgba(#b382f1, 0.03);
    }
    &.brady {
      fill: rgba(#66cde3, 0.03);
    }
  }

  .dash {
    stroke-dasharray: 5, 15;
    stroke-width: 5;
    stroke-linecap: round;
    border-radius: 2px;
    width: 3px;
    height: 6px;
    &.test {
      stroke: #508cc0;
    }
    &.survey {
      stroke: #f5bd84;
    }
    &.triage_score {
      stroke: #67d2a5;
    }
    &.medicines {
      stroke: #d76d68;
    }
    &.events {
      stroke: #7663e9;
    }
    &.tapping {
      stroke: #ffd953;
    }
    &.tug {
      stroke: #b382f1;
    }
    &.brady {
      stroke: #66cde3;
    }
  }
  .tooltip-it {
    fill: $surface-white;
    width: 134px;
    height: 84px;
    &.test {
      stroke: #508cc0;
    }
    &.survey {
      stroke: #f5bd84;
    }
    &.triage_score {
      stroke: #67d2a5;
    }
    &.medicines {
      stroke: #d76d68;
    }
    &.events {
      stroke: #7663e9;
    }
    &.tapping {
      stroke: #ffd953;
    }
    &.tug {
      stroke: #b382f1;
    }
    &.brady {
      stroke: #66cde3;
    }
  }

  .text-title {
    stroke-width: 1px;
    font-family: $font-family-regular;
    font-size: $font-s-small;
    &.test {
      stroke: #508cc0;
    }
    &.survey {
      stroke: #f5bd84;
    }
    &.triage_score {
      stroke: #67d2a5;
    }
    &.medicines {
      stroke: #d76d68;
    }
    &.events {
      stroke: #7663e9;
    }
    &.tapping {
      stroke: #ffd953;
    }
    &.tug {
      stroke: #b382f1;
    }
    &.brady {
      stroke: #66cde3;
    }
  }

  .text-info {
    stroke-width: 0.2px;
    font-family: $font-family-regular !important;
    font-size: $font-s-regular;
    stroke: $surface-black-color;
  }

  .text-info-small {
    stroke-width: 0.1px;
    font-family: $font-family-regular !important;
    font-size: $font-s-small;
    stroke: $surface-black-color;
  }
}
