.create-institution-box {
  background-color: $surface-white;
  padding: $spacing-a-medium;
  border-radius: $border-radius;
  @include drop-shadow(0, 2px, 4px, -1px, 0.12);
}

.create-institution-wrapper {
  justify-content: center;
  position: relative;
  margin-top: 80px;
}
