.tooltip-container {
  position: absolute;
  border: 1px solid #d76d68;
  border-radius: 5px;
  opacity: 0;
  padding: 12px;
  font-family: $font-family-regular !important;
  text-align: center;
  color: $surface-black-color;
  min-width: 150px;
  background-color: $surface-white;

  .tooltip-title {
    font-family: $font-family-bold;
    font-size: 10px;
    color: #d76d68;
    font-weight: 800;
    margin-bottom: 8px;
  }

  .tooltip-body {
    stroke-width: 0.2px;
    font-size: $font-s-regular;
    font-weight: bolder;
  }

  .tooltip-footer {
    stroke-width: 0.1px;
    font-size: $font-s-small;
  }
}

.activity-chart {
  width: 100%;

  .bars {
    fill: #64c1b4;
  }

  .dash {
    stroke-dasharray: 5, 15;
    stroke-width: 5;
    stroke-linecap: round;
    border-radius: 2px;
    width: 3px;
    height: 6px;
  }
  .tooltip-act {
    fill: $surface-white;
    width: 134px;
    height: 84px;
    stroke: #d76d68;
  }

  .x-y-label {
    fill: $base-color;
    font-size: $font-s-small;
    font-family: $font-family-regular;
  }

  .text-title {
    stroke-width: 1px;
    font-family: $font-family-regular;
    font-size: $font-s-small;
    stroke: #d76d68;
  }

  .text-info {
    stroke-width: 0.2px;
    font-family: $font-family-regular !important;
    font-size: $font-s-regular;
    stroke: $surface-black-color;
  }
}
