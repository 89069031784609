.add-medicine-modal {
  @include drop-shadow(0, 2px, 4px, -1px, 0.12);
  overflow-y: auto;
  height: auto;
  max-height: 90vh;
  max-width: 90vw;
  background-color: rgba($color: $surface-white, $alpha: 1);
  padding: 24px 32px;
  border-radius: $border-radius;
}
