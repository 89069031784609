.radio-buttons-grid-wrapper {
  display: flex;
  flex-direction: column;
  font-family: $font-family-regular;
  font-size: $font-s-regular;

  .rd-btn-grid-question-title {
    color: $dark-grey-color;
  }

  .rd-btn-grid-container {
    display: grid;
    grid-template-columns: minmax(100px, 200px) auto;
    width: 100%;
    overflow-x: auto;

    .rd-btn-question-container {
      padding: $spacing-a-smaller $spacing-a-smallest;
      position: sticky;
      left: 0;
      background-color: $surface-white;
      z-index: 4;

      &.question-odd {
        &::before {
          content: "";
          height: 100%;
          width: 100%;
          background-color: $background-color;
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }
      }
    }

    .rd-btn-option-header {
      width: 110px;
      padding: $spacing-a-small $spacing-a-smallest;
      text-align: center;

      &.disabled {
        opacity: 0.6;
      }
    }

    .rd-btn-option-wrapper {
      display: flex;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      background-color: $surface-white;

      &.question-odd {
        background-color: $background-color;
      }

      .rd-btn-option-container {
        width: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;

        &::before {
          content: "";
          height: 18px;
          width: 18px;
          border-radius: 50%;
          border: 1px solid $primary-color;
        }

        &.checked {
          &::after {
            content: "";
            position: absolute;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: $primary-color;
          }
        }

        &.disabled {
          cursor: auto;
          opacity: 0.6;
        }
      }
    }
  }
}
