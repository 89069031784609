.surveys-module-wrapper {
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;

  .surveys-module-container {
    display: flex;
    flex-direction: column;
    background: $surface-white;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
    border-radius: $border-radius;
    padding: $spacing-a-xsmall;

    .search-wrapper {
      margin-top: $spacing-a-xsmall;
    }

    .filter-button {
      @extend .flex-d-column;
      position: relative;
      width: 130px;
    }

    .filter-by-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .filter-by-text {
      @extend .regular-text;
      margin-top: $spacing-a-smallest;
      color: $grey-intense-color;
      text-align: right;
    }

    .surveys-list {
      padding-right: $spacing-a-small;
      padding-left: $spacing-a-small;
      padding-top: $spacing-a-medium;
    }

    .filter-by-text {
      @extend .regular-text;
      margin-top: $spacing-a-smallest;
      color: $grey-intense-color;
      text-align: right;
    }

    .surveys-list {
      padding-right: $spacing-a-small;
      padding-left: $spacing-a-small;
      padding-top: $spacing-a-medium;
    }

    .surveys-list-labels-wrapper {
      padding-bottom: $spacing-a-small;

      .order-img-container {
        margin-left: 4px;
        height: 14px;
        width: 14px;
        background-color: $surface-white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .survey-info-wrapper {
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;

      &:last-child:not(:nth-child(2)) {
        border-bottom: none;
      }

      .pointer {
        cursor: pointer;
      }

      .help-img {
        margin-left: $spacing-a-smallest;
        margin-top: -4px;
      }
    }

    .loading-wrapper {
      flex-direction: row;
      display: flex;
      position: relative;
      margin-top: 75px;
      align-items: center;
      justify-content: center;
    }

    .survey-info {
      padding-right: $spacing-a-small;
      padding-left: $spacing-a-small;
      margin-bottom: $spacing-a-medium;
      border-bottom: 1px solid $background-color;
    }
  }
}
