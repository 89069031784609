.ev-mod-file-upload-container {
  &.error {
    border: 1px solid $warning-color;
    border-radius: 5px;
  }

  .filled-container {
    border: 2px solid #eff3f6;
    border-radius: 5px;

    .filled-header {
      background-color: $background-color;
      border-radius: $border-radius;

      display: flex;
      align-items: center;

      padding: 4px 4px 4px 16px;
    }

    .filled-uploaded-files-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      overflow-x: auto;

      width: 100%;
      max-height: 100px;
      padding: 8px 16px;

      .filled-uploaded-files {
        padding: 6px 0;
        margin-right: 60px;

        font-size: $font-s-regular;
        font-family: $font-family-regular;
        color: $surface-black-color;
        line-height: 15px;
      }
    }
  }
}
