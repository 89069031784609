.mkinetikos-report {
  width: 100%;
  .report-box {
    @include box(20px 32px);
    background-color: $surface-white;
    min-height: 400px;

    @include drop-shadow(0, 2px, 4px, -1px, 0.12);
    .chart-box {
      overflow-x: auto;
      @include box(100px 50px 80px 50px);
      background-color: $surface-white;
      @include drop-shadow(0, 2px, 4px, -1px, 0.12);
    }
  }
  .chart-container {
    width: 66%;
  }
  .activity-box {
    @include box(20px 32px);
    background-color: $surface-white;
    min-height: 400px;

    @include drop-shadow(0, 2px, 4px, -1px, 0.12);
    .chart-box {
      overflow-x: auto;

      @include box(100px 70px 80px 70px);
      background-color: $surface-white;
      @include drop-shadow(0, 2px, 4px, -1px, 0.12);
    }
  }
}
.report-select-date-input {
  @extend .small-wider-button;
  @include button($font-family-regular, transparent, $dark-grey-color);
  border-bottom: 2px solid #eff3f6;
}

.metrics-name-container {
  position: relative;

  .help-container {
    position: absolute;
    margin-left: $spacing-a-smallest;
    bottom: $spacing-a-smallest;
    cursor: pointer;
  }
}
