.add-modules-box {
  // min-width: 200px;
  background-color: $surface-white;
  margin-top: $spacing-a-smaller;
  @include box($spacing-a-medium);
  @include drop-shadow(0, 2px, 4px, -1px, 0.12);
  .search-wrapper {
    position: relative;
    margin-top: $spacing-a-smallest;
  }
  .module-type {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px 3px;
    background-color: $background-color;
    width: 114px;
    height: 86px;
    border-radius: $border-radius;
    padding: 10px;
    position: relative;
    .text-bottom {
      @extend .regular-text, .black-color;
      position: absolute;
      bottom: 14px;
      width: fit-content;
      right: 0;
      left: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .module-type-list {
    flex-wrap: wrap;
    background-color: $background-color;
    margin-top: $spacing-a-smallest;
    border-radius: $border-radius;
    padding: $spacing-a-smallest;
    .header {
      @extend .row-space-between;
      padding-bottom: $spacing-a-medium;
      .module-image {
        width: 30px;
        height: auto;
      }
    }
    .list {
      max-height: 300px;
      overflow: auto;
      .list-item {
        @extend .row-space-between, .regular-text, .black-color;
        border-bottom: 1px solid $surface-white;
        padding-bottom: 6px;
        padding-top: 6px;

        .add-button {
          @extend .button;
          @include button(
            $font-family-medium,
            transparent,
            $base-color,
            $base-color
          );
          font-size: $font-s-regular;
          padding: 2px 12px;
          line-height: 14px;
          &:hover {
            color: $surface-white;
            background-color: $base-color;
          }
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
