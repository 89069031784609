@mixin label(
  $font-family,
  $background_color,
  $text_color: $surface-white,
  $border_color: transparent
) {
  font-family: $font-family;
  background-color: $background_color;
  color: $text_color;
  font-size: 12px;
  border: 1px solid $border_color;
  border-radius: 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: min-content;

  &:hover {
    background-color: $background_color !important;
  }
}

.label {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.primary-label {
  @include label($font-family-medium, $primary-color);
}

.secondary-label {
  @include label($font-family-medium, $yellow, $base-color);
}

.tertiary-label {
  @include label($font-family-medium, $secondary-color);
}

.small-label {
  font-size: $font-s-regular;
  padding: 4px 12px;
  line-height: 14px;
}

.rounded {
  border-radius: 50px !important;
}
