// html,body{margin:0;padding:0;height:100%;}

.main-wrapper {
  height: 100vh;
  min-height: 100vh;
  overflow-y: auto;
  background-color: $background-color;
}

.main-container {
  @extend .container-fluid;
  height: 100%;
}
