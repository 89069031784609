.patient-home-container {
  height: 100vh;
  overflow-y: auto;
  padding-left: $spacing-a-medium;
  padding-right: $spacing-a-medium;
}

.patient-edit-container {
  height: 100vh;
  overflow-y: auto;
  padding-left: $spacing-a-medium;
  padding-right: $spacing-a-medium;
}

.patient-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-left: $spacing-a-medium;
  padding-right: $spacing-a-medium;
  padding-bottom: $spacing-a-medium;

  .title-container {
    display: flex;
    align-self: flex-start;
    align-items: center;
    margin-bottom: $spacing-a-biggest;
    cursor: pointer;

    img {
      margin-right: $spacing-a-small;
    }

    h4 {
      margin: 0;
    }
  }
}

.programs-filter-container {
  margin-top: $spacing-a-small;
  margin-bottom: $spacing-a-xmedium;
  display: flex;
  align-items: center;

  .programs-filter-btn {
    margin-right: $spacing-a-smaller;
    border-radius: $border-radius2;
    background-color: $surface-white;
    padding: 6px $spacing-a-small;
    color: $base-color;
    font-size: $font-s-regular;
    font-family: $font-family-semi-bold;
    border: 1px solid $base-color;
    line-height: 15px;
    cursor: pointer;
    transition: 0.3s;

    &.active {
      background-color: $base-color;
      color: $surface-white;
    }

    &:hover {
      background-color: $primary-hover-color;
      border: 1px solid $primary-hover-color;
      color: $surface-white;
    }
  }

  .programs-filter-close-btn {
    cursor: pointer;
  }
}
