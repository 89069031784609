.background-modal-container {
  width: 100%;
  background-color: rgba($color: $base-color, $alpha: 0.2);
  margin: auto;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.message-modal-container {
  position: absolute;
  margin: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .button-container {
    min-width: 63px;
    min-height: 28px;
  }
}

.message-modal-close {
  position: absolute;
  top: $spacing-a-smallest;
  right: $spacing-a-smallest;
  cursor: pointer;
  width: $spacing-a-large;
  height: $spacing-a-large;
}

.message-modal {
  @include drop-shadow(0, 2px, 4px, -1px, 0.12);
  overflow-y: auto;
  max-height: 80vh;
  background-color: rgba($color: $surface-white, $alpha: 1);
  padding: 16px;
  border-radius: $border-radius;

  &--large {
    padding: $spacing-a-xsmall;
  }

  .title-text {
    font-family: $font-family-bold;
    font-size: $font-s-medium;
    color: $base-color;
  }
  .message-body-wrapper {
    padding-top: $spacing-a-medium;
    padding-bottom: $spacing-a-large;
    @extend .regular-text;
  }

  .footer-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .padding-right {
      padding-right: 10px;
    }
  }
  @include media-breakpoint-up(sm) {
    width: 400px;

    &--large {
      width: 600px;
    }
  }
  @include media-breakpoint-up(lg) {
    width: 600px;

    &--large {
      width: 1000px;
    }
  }
}
