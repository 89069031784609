.event-matrix {
  .axis {
    font-family: "Inter-Regular";
    font-size: $font-s-small;
  }

  .background {
    fill: $background-color;
  }

  .pointer {
    cursor: pointer;
  }

  .inner-circle {
    fill: $primary-color;
    r: 7px;
  }

  .circle-text {
    cursor: pointer;
    fill: $surface-white;
    font-size: $font-s-small;
  }

  .circle-axis {
    font-size: $font-s-small;
    r: 15;
  }

  .circle-axis-line {
    stroke: $dark-grey-color;
  }

  .circle-lable {
    r: 9;
    fill: $primary-color;
  }

  .lable-text {
    fill: $dark-grey-color;
  }

  .lable-text-last {
    fill: $surface-white;
  }

  .event-popup {
    position: absolute;
    display: inline-block;
    background-color: $surface-white;
    border: 1px solid $primary-color;
    border-radius: 6px;
    z-index: 1;

    font-size: $font-s-regular;
    .event-list-header {
      width: 200px;
      .title {
        flex: 1;
        border: 1px solid $base-color;
        border-radius: 6px;

        background-color: $base-color;
        font-size: 12px;
        color: $surface-white;
      }
    }
    .event-list {
      overflow-y: scroll;
      margin-top: 2px;
      max-height: 17rem;
      color: $base-color;
      .event-item {
        img {
          width: 1.5rem;
          height: 1.5rem;
        }
        .time {
          color: $surface-black-color;
        }
        .registered {
          color: $dark-grey-color;
        }
      }

      hr {
        color: $base-color;
        width: 9px;
        margin-top: $spacing-a-smallest;
        margin-bottom: $spacing-a-smallest;
      }
      :last-child hr {
        display: none;
      }
    }
  }
}
