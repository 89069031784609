.days-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -($spacing-a-smallest);

  .day-container {
    cursor: pointer;
    @extend .regular-text;
    border-radius: $border-radius2;
    background-color: $background-color;
    padding: 4px $spacing-a-small;
    margin: $spacing-a-smallest;
    &:hover {
      background-color: $primary-color;
      color: $surface-white;
    }
    &.active {
      background-color: $primary-color;
      color: $surface-white;
      &:hover {
        background-color: $primary-hover-color;
        color: $surface-white;
      }
    }
  }

  .day-container-static {
    @extend .regular-text;
    border-radius: $border-radius2;
    background-color: $background-color;
    padding: 4px $spacing-a-small;
    margin: $spacing-a-smallest;
    &.active {
      background-color: $primary-color;
      color: $surface-white;
    }
  }
}
