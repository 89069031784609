.sidebar-hamburger-container {
  position: absolute;
  left: 0;
  z-index: 1;
  top: $spacing-a-small;
  padding-left: 0;
  width: max-content;
}

.sidebar-hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px !important;
  height: $spacing-a-xmedium;
  background-color: $background-hover-color !important;
  border: none;
  cursor: pointer;
  z-index: 1;
  border-radius: 0 $spacing-a-smallest $spacing-a-smallest 0;

  &.showLeftRadius {
    border-radius: $spacing-a-smallest;
  }

  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

.sidebar-hamburger-inner {
  display: block;
  position: relative;
  width: $spacing-a-xsmall;
  height: $spacing-a-small;

  span {
    display: block;
    width: 100%;
    height: 3px;
    margin-bottom: 4px;
    position: relative;
    background: $base-color;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
