.choose-institution-box {
  background-color: $surface-white;
  padding: $spacing-a-medium;
  border-radius: $border-radius;
  @include drop-shadow(0, 2px, 4px, -1px, 0.12);
  .institution {
    @extend .regular-text;
    @extend .black-color;
    cursor: pointer;
    border-radius: $border-radius;
    width: 100%;
    padding: 8px;
    background-color: $background-color;
    align-items: center;
    &.isSelected {
      background-color: $primary-color;
      color: $surface-white;
    }
    &.hidden {
      opacity: 20%;
    }
    .photo {
      border-radius: $border-radius;
      width: 32px;
      height: 32px;
      margin-right: 20px;
      border-radius: $border-radius;
    }
  }

  .bottom-buttons {
    margin-top: 90px;
    @extend .flex-d-row;
    justify-content: flex-end;
    align-items: center;
  }
}

.institution-loading-box {
  background-color: $surface-white;
  padding: 100px;
  border-radius: $border-radius;
  @include drop-shadow(0, 2px, 4px, -1px, 0.12);
}

.choose-institution-wrapper {
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 100px;
}

.logo {
  width: 127px;
  height: auto;
}

.kinetikos-bottom-information-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
  margin-bottom: 16px;
  .kinetikos-bottom-information {
    width: 232px;
    height: auto;
  }
}
