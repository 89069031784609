.radio-buttons-container {
  display: flex;
  flex-direction: column;
  font-family: $font-family-regular;
  font-size: $font-s-regular;

  .rd-btn-question {
    color: $dark-grey-color;
    margin-bottom: $spacing-a-small;
  }

  .rd-btn-options-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: -$spacing-a-smaller;

    &.vertical {
      flex-direction: column;
    }

    .rd-btn-option-container {
      margin-top: $spacing-a-smaller;
      margin-right: $spacing-a-medium;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $surface-black-color;
      position: relative;

      &::before {
        content: "";
        height: 18px;
        width: 18px;
        border-radius: 50%;
        border: 1px solid $primary-color;
        margin-right: $spacing-a-smaller;
      }

      &.checked {
        &::after {
          content: "";
          position: absolute;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background-color: $primary-color;
          left: 4px;
        }
      }

      &.disabled {
        cursor: auto;
        &::before {
          opacity: 0.6;
        }
      }
    }
    .rd-btn-option-container-center {
      margin: 0 auto;
      margin-top: $spacing-a-smaller;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $surface-black-color;
      position: relative;

      &::before {
        content: "";
        height: 18px;
        width: 18px;
        border-radius: 50%;
        border: 1px solid $primary-color;
        margin-right: $spacing-a-smaller;
      }

      &.checked {
        &::after {
          content: "";
          position: absolute;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background-color: $primary-color;
          left: 4px;
        }
      }

      &.disabled {
        cursor: auto;
        &::before {
          opacity: 0.6;
        }
      }
    }
  }
}
