a[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

li[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.5;
  transition: opacity 500ms ease-out;
}

span[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

img[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

.row-space-between {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-d-column {
  display: flex;
  flex-direction: column;
}

.flex-d-row {
  display: flex;
  flex-direction: row;
}

.flex-d-one {
  flex: 1;
}

.stretch-align {
  align-items: stretch;
}

ul {
  padding: 0;
  list-style-type: none;
}

.grey-border {
  border: 1px solid $bodyBackground;
  border-radius: $border-radius;
}

.line-border {
  height: 2px;
  width: 100%;
  background-color: $background-color;
}

.cursor-pointer {
  cursor: pointer;
}

.center-justify {
  justify-content: center;
}

.disabled-container {
  opacity: 0.5 !important;
  transition: opacity 500ms ease-out;
}

.search-filter-wrapper {
  padding-top: $spacing-a-xsmall;
  .filter-button {
    @extend .flex-d-column;
    position: relative;
    width: 130px;
  }
  .filter-by-text {
    @extend .regular-text;
    margin-top: $spacing-a-smallest;
    color: $grey-intense-color;
    text-align: right;
  }
}

.list-content {
  min-height: 100px;
}

.message-wrapper {
  margin-top: 75px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grey-label {
  width: fit-content;
  border-radius: $border-radius2;
  background-color: $background-color;
  padding: 3px 18px 2px;
  cursor: default;
}

//##### Margins ######
.m-0 {
  margin: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}

//smaller 12px
.m-1 {
  margin: $spacing-a-smaller !important;
}
.mr-1 {
  margin-right: $spacing-a-smaller !important;
}
.ml-1 {
  margin-left: $spacing-a-smaller !important;
}
.mt-1 {
  margin-top: $spacing-a-smaller !important;
}
.mb-1 {
  margin-bottom: $spacing-a-smaller !important;
}

//small 16px
.m-2 {
  margin: $spacing-a-small !important;
}
.mr-2 {
  margin-right: $spacing-a-small !important;
}
.ml-2 {
  margin-left: $spacing-a-small !important;
}
.mt-2 {
  margin-top: $spacing-a-small !important;
}
.mb-2 {
  margin-bottom: $spacing-a-small !important;
}

//xsmall 24px
.m-3 {
  margin: $spacing-a-xsmall !important;
}
.mr-3 {
  margin-right: $spacing-a-xsmall !important;
}
.ml-3 {
  margin-left: $spacing-a-xsmall !important;
}
.mt-3 {
  margin-top: $spacing-a-xsmall !important;
}
.mb-3 {
  margin-bottom: $spacing-a-xsmall !important;
}

//medium 32px
.m-4 {
  margin: $spacing-a-medium !important;
}
.mr-4 {
  margin-right: $spacing-a-medium !important;
}
.ml-4 {
  margin-left: $spacing-a-medium !important;
}
.mt-4 {
  margin-top: $spacing-a-medium !important;
}
.mb-4 {
  margin-bottom: $spacing-a-medium !important;
}

//large 48px
.m-5 {
  margin: $spacing-a-large !important;
}
.mr-5 {
  margin-right: $spacing-a-large !important;
}
.ml-5 {
  margin-left: $spacing-a-large !important;
}
.mt-5 {
  margin-top: $spacing-a-large !important;
}
.mb-5 {
  margin-bottom: $spacing-a-large !important;
}

.m-6 {
  margin: $spacing-a-biggest !important;
}
.mr-6 {
  margin-right: $spacing-a-biggest !important;
}
.ml-6 {
  margin-left: $spacing-a-biggest !important;
}
.mt-6 {
  margin-top: $spacing-a-biggest !important;
}
.mb-6 {
  margin-bottom: $spacing-a-biggest !important;
}

//##### Paddings ######
.p-0 {
  padding: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}

.p-1 {
  padding: $spacing-a-smaller !important;
}
.pr-1 {
  padding-right: $spacing-a-smaller !important;
}
.pl-1 {
  padding-left: $spacing-a-smaller !important;
}
.pt-1 {
  padding-top: $spacing-a-smaller !important;
}
.pb-1 {
  padding-bottom: $spacing-a-smaller !important;
}

//small 16px
.p-2 {
  padding: $spacing-a-small !important;
}
.pr-2 {
  padding-right: $spacing-a-small !important;
}
.pl-2 {
  padding-left: $spacing-a-small !important;
}
.pt-2 {
  padding-top: $spacing-a-small !important;
}
.pb-2 {
  padding-bottom: $spacing-a-small !important;
}

//xsmall 24px
.p-3 {
  padding: $spacing-a-xsmall !important;
}
.pr-3 {
  padding-right: $spacing-a-xsmall !important;
}
.pl-3 {
  padding-left: $spacing-a-xsmall !important;
}
.pt-3 {
  padding-top: $spacing-a-xsmall !important;
}
.pb-3 {
  padding-bottom: $spacing-a-xsmall !important;
}

//medium 32px
.p-4 {
  padding: $spacing-a-medium !important;
}
.pr-4 {
  padding-right: $spacing-a-medium !important;
}
.pl-4 {
  padding-left: $spacing-a-medium !important;
}
.pt-4 {
  padding-top: $spacing-a-medium !important;
}
.pb-4 {
  padding-bottom: $spacing-a-medium !important;
}

//large 48px
.p-5 {
  padding: $spacing-a-large !important;
}
.pr-5 {
  padding-right: $spacing-a-large !important;
}
.pl-5 {
  padding-left: $spacing-a-large !important;
}
.pt-5 {
  padding-top: $spacing-a-large !important;
}
.pb-5 {
  padding-bottom: $spacing-a-large !important;
}
