.mkinetikos-exercises-modules-box {
  @include box(20px 32px);
  background-color: $surface-white;
  @include drop-shadow(0, 2px, 4px, -1px, 0.12);
  .exercise {
    @include list-item(8px);
    :last-child {
      border-bottom: none;
    }
    .expired-date {
      width: fit-content;
      border-radius: $border-radius2;
      background-color: $background-color;
      padding: 3px 18px 2px;
    }
  }
}

.create-exercise-box {
  @include box(20px 32px);
  background-color: $surface-white;
  @include drop-shadow(0, 2px, 4px, -1px, 0.12);
  min-height: 4rem;
  flex-shrink: 0;
  &.with-modal-open {
    opacity: 0.8 !important;
    transition: opacity 500ms ease-out;
    background-color: none;
    background: linear-gradient(90deg, $surface-white 0%, transparent 100%);
  }
}

.exercises-add-existing-modal {
  z-index: 10;
  overflow-y: auto;
  position: absolute;
  float: left;
  top: 0;
  right: 0;
  width: 450px;
  height: 100%;
  background-color: $surface-white;
  border: 1px solid $background-color;
  @include drop-shadow(0, 2px, 4px, -1px, 0.12);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: $spacing-a-xsmall $spacing-a-medium;
}

.exercise-item-list {
  border: 1px solid $base-color;
  border-radius: $border-radius;
  padding: 10px 8px 10px 16px;
  .title {
    font-family: $font-family-medium;
    color: $base-color;
    size: $font-s-medium;
  }
  .header-button {
    margin-right: 8px;
  }
  .exercise-info-button-img {
    width: 10px;
    height: auto;
    margin-right: 0;
    margin-left: 4px;
  }
}

.exercise-details-input {
  width: 190px;
}

.exercise-show-img-video {
  border-radius: $border-radius;
  border: 2px solid $background-color;
  padding: 12px;
  align-items: center;
}

.exercise-edit-img-video {
  @extend .regular-text;
  color: $base-color;
  border-radius: $border-radius;
  background-color: $background-color;
  padding: 12px;
  align-items: center;
}
