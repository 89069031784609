.file-upload-body {
  .upload-wrapper {
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $spacing-a-medium;
    background-color: $background-color;
    cursor: pointer;

    .upload-image {
      cursor: pointer;
    }
  }
  .details-text {
    padding-top: 12px;
    font-size: $font-s-small;
    font-family: $font-family-regular;
    color: $dark-grey-color;
  }
}
