@import "rc-time-picker/assets/index.css";

.rc-time-picker {
  display: flex !important;
  background-color: #eff3f6;
  border-radius: $border-radius;
  padding-right: 8px;
}

.rc-time-picker-input {
  @extend .form-input;
  height: fit-content;
}
