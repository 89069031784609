.ev-mod-separator {
  font-size: $font-s-regular;
  font-family: $font-family-regular;
  color: $surface-black-color;
  line-height: 15px;
  white-space: pre-wrap;

  h1 {
    font-size: $font-s-medium;
    font-family: $font-family-semi-bold;
    color: $base-color;
    text-transform: uppercase;

    margin-bottom: $spacing-a-small;
  }

  h2 {
    font-size: $font-s-medium;
    font-family: $font-family-regular;
    color: $base-color;
    text-transform: uppercase;

    margin-bottom: $spacing-a-small;
  }

  h3 {
    font-size: $font-s-regular;
    font-family: $font-family-semi-bold;
    color: $base-color;

    margin-bottom: $spacing-a-small;
  }
}
