.warning-color {
  color: $warning-color !important;
}

.black-color {
  color: $surface-black-color !important;
}

.transparent-color {
  color: transparent !important;
}

.base-color {
  color: $base-color !important;
}

.primary-hover-color {
  color: $primary-hover-color !important;
}

.surface-white-color {
  color: $surface-white;
}

.dark-grey-color {
  color: $dark-grey-color;
}

.secondary-color {
  color: $secondary-color;
}

.primary-color {
  color: $primary-color;
}

.light-green {
  color: $light-green;
}

.light-gray {
  color: $light-gray;
}

.grey-intense {
  color: $grey-intense-color;
}

.orange-color {
  color: $orange;
}

.light-orange {
  color: $orange-light !important;
}

// BG's
.light-green-bg {
  background-color: $light-green;
}

.light-gray-bg {
  background-color: $light-gray;
}
