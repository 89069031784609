.center-align {
  align-items: center;
}
.search-icon {
  position: absolute;
  left: 10px;
  top: 9px;
  height: 15px;
  width: auto;
}
.search-clear-icon {
  position: absolute;
  right: 10px;
  top: 9px;
  height: 15px;
  width: auto;
  cursor: pointer;
}
.title-search-wrapper {
  align-items: center;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px 11px;
}
.patient-connection-requests-wrapper {
  align-items: center;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: $spacing-a-medium;
  padding: 0px 11px;
  .pointer {
    cursor: pointer;
  }

  .connection-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.need-license-container {
  background-color: $primary-color !important;
  color: $surface-white;
  padding: 8px 12px;
  margin-left: 8px;
  border-radius: 4px;
}
.patient-connection-requests-button {
  margin-right: $spacing-a-smaller;
  border-radius: $border-radius2;
  background-color: $surface-white;
  padding: 6px $spacing-a-small;
  color: $base-color;
  font-size: $font-s-regular;
  font-family: $font-family-semi-bold;
  border: 1px solid $base-color;
  line-height: 15px;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  gap: $spacing-a-smallest;

  &.active {
    background-color: $base-color;
    color: $surface-white;
  }

  &:hover {
    background-color: $primary-hover-color;
    border: 1px solid $primary-hover-color;
    color: $surface-white;

    .patient_connection_requests_count_container {
      background-color: $surface-white;
    }

    .patient_connection_requests_count {
      color: $primary-color;
    }
  }
  &--clicked {
    background-color: $primary-hover-color;
    border: 1px solid $primary-hover-color;
    color: $surface-white;

    .patient_connection_requests_count_container {
      background-color: $surface-white;
    }

    .patient_connection_requests_count {
      color: $primary-color;
    }
  }
}
.patient_connection_requests_count_container {
  border-radius: 50%;
  background-color: $primary-color;
  padding: 3px 6px;
}
.patient_connection_requests_count {
  color: $surface-white;
  font-size: $font-s-regular;
}
.patient-list-wrapper {
  margin-top: $spacing-a-xmedium;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $surface-white;
  border-radius: $border-radius;
  min-width: 400px;

  .col-w-39 {
    width: 39%;
  }
}

.patient-connection-requests-header {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-a-medium;
  margin-left: $spacing-a-smaller;
  margin-right: $spacing-a-smaller;
  padding: 0px 11px;
}

.patient-connection-no-request-border {
  padding-bottom: 35px;
  border-bottom: 1px solid $bodyBackground;
}

.patient-connection-see-requests {
  margin-right: 4px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.patient-labels {
  margin-top: $spacing-a-medium;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px 11px;
}

.patient-list {
  display: flex;
  flex-direction: row;
  margin-top: $spacing-a-xsmall;
  justify-content: center;
  align-items: center;
}

.connection-request-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.patient-wrapper {
  :hover:not(.mkinetikos-wrapper):not(.mkinetikos-wrapper-split) {
    background-color: $background-color;
  }
}

.patient {
  padding: 5px 11px;
  margin-left: 0px;
  margin-right: 0px;
  align-items: center;
}

.connection-request-container {
  margin-left: 0px;
  margin-right: 0px;
  align-items: center;
  margin-bottom: 3px;
}

.transparent-border {
  border: 1px solid $surface-white;
}

.connection-request-content {
  display: flex;
  line-height: unset;
  padding: 5px 0 11px 11px;
  border-radius: 5px;
  margin-right: 11px;

  &--selected {
    background-color: $background-color;
  }
}

.connection-modal-body {
  font-size: unset !important;
  line-height: unset !important;
}

.home-buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.approve-button {
  color: $primary-color;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
}

.reject-button {
  color: $warning-color;
  font-size: $font-s-regular;

  &:hover {
    text-decoration: underline;
  }
}

.patient-name {
  align-items: center;
}

.new-patinet-tag {
  background-color: $secondary-color;
  border-radius: 5px;
  color: $surface-white;
  font-size: $font-s-regular;
  box-shadow: 0px 2px 12px rgba(37, 89, 134, 0.43);
  min-width: 32px;
  min-height: 16px;
  text-align: center;
  pointer-events: none;
  margin-left: $spacing-a-smaller;
}

.patient-info-text {
  margin-left: $spacing-a-smaller;
  margin-right: $spacing-a-smallest;
  color: $surface-black-color;
  font-family: $font-family-regular;
  font-size: $font-s-regular;
  word-wrap: break-word;
  cursor: pointer;
}

.ipm-ml-8 {
  margin-left: $spacing-a-smallest;
}

.border-wrapper {
  height: 1px;
  margin: 0px 0px;
  padding: 0px 24px;
}

.hr-border {
  border-bottom: 1px solid #c3cfe8;
  opacity: 0.5;
}

.hr-border-base {
  border-bottom: 1px solid $base-color;
  opacity: 0.5;
}

.arrow {
  height: 12px;
  width: auto;
  margin-left: 5px;
  display: block;
}

.triage {
  height: 100%;
  width: 10px;
  border-radius: 7px;
}

.no-data-text {
  text-align: center;
  font-size: $font-s-medium;
  color: $base-color;
  margin-top: $spacing-a-biggest;
  margin-bottom: $spacing-a-biggest;

  span {
    padding: 10px;
  }
}

.prescriptions-summary-wrapper {
  @extend .patient-list-wrapper;
  padding-bottom: 0;
}

.prescriptions-summary-header {
  @extend .title-search-wrapper;
}

.prescriptions-summary-footer {
  display: flex;
  width: max-content;
  justify-content: space-between;
  border-radius: 5px;
  align-items: center;
  margin-top: $spacing-a-smaller;
  font-size: 0.625rem;
  background-color: #eff3f6;
  padding: 6px 8px;
}

.prescription-summary-footer-item {
  display: flex;
  margin-right: 16px;
  gap: 6px;
  font-size: 0.625rem;

  &:last-of-type {
    margin-right: 0;
  }
}

.prescriptions-summary-body {
  @extend .patient-labels;
  font-size: 0.75rem;
  margin: 0;
  padding: 0;
  .title {
    padding: 0;
    margin: 0;
  }

  .week-days {
    display: flex;
    justify-content: space-around;
    padding: 8px 0;
  }

  .week-day-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .week-day-title {
    margin: 0;
    padding: 2px 4px;
    border-radius: 4px;
    border: 1px solid transparent;

    &.isToday {
      border: 1px solid $base-color;
    }
  }

  .medicine-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    color: #071e31;
  }
  .medicine-time {
    display: block;
    color: #8ca1b4;
  }
  .status-box {
    width: 5%;
  }
  img {
    width: 100%;
  }
}

.medicine-info {
  padding-left: $spacing-a-smaller;
}

.medicine-box {
  background-color: #eff3f6;
  border-radius: 5px;
  height: 32px;
  align-items: center;
  margin: auto;
  padding: 0;

  &.big {
    height: 40px;
  }

  &.white {
    background-color: #fdfdfe;
  }
}

.medicine-status {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
}

.prescription-usage-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.prescription-usage-icon {
  width: 50%;
  height: 50%;
}

.prescription-coloured-icon {
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 4px;

  &-start {
    left: calc(50% - 4px);
  }

  &-end {
    left: calc(50% + 4px);
  }
}

.medicine-message {
  margin: 0;
}

.medicine-spinner {
  padding: 4%;
  position: relative;
}

.header-date {
  margin: 0;
  padding: 0;
}

.right-text {
  text-align: right;
}

@media screen and (min-width: 750px) {
  .mkinetikos-wrapper {
    width: 90px;
    height: 18px;
    border-radius: 10px;
    text-align: center;
    line-height: 18px;
    font-family: "Inter-Regular";
    font-size: $font-s-small;
  }
}
@media screen and (max-width: 750px) {
  .mkinetikos-wrapper {
    width: 25px;
    height: 18px;
    border-radius: 10px;
    font-size: 0px;
  }
}

@media screen and (min-width: 1500px) {
  .mkinetikos-wrapper-split {
    width: 25px;
    height: 18px;
    border-radius: 10px;
    text-align: center;
    line-height: 18px;
    font-family: "Inter-Regular";
    font-size: $font-s-small;
  }
}
@media screen and (max-width: 1500px) {
  .mkinetikos-wrapper-split {
    width: 25px;
    height: 18px;
    border-radius: 10px;
    font-size: 0px;
  }
}

.patient-data {
  width: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  margin-top: $spacing-a-xmedium;

  .pointer {
    cursor: pointer;
  }

  .data-card {
    margin-bottom: $spacing-a-small;
    padding: $spacing-a-small;
    background-color: $surface-white;
    border-radius: $border-radius;
    color: $surface-black-color;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
  }

  .data-icon {
    width: 18px;
    height: 20px;
    margin-right: $spacing-a-smaller;
  }

  .no-data {
    text-align: center;
    font-size: $font-s-big;
    color: $surface-black-color;
    line-height: $spacing-a-xsmall;
  }

  .events-info {
    .event-matrix {
      width: 65%;
      position: relative;
    }

    .events-list {
      font-size: $font-s-regular;
      width: 35%;

      hr {
        color: #c3cfe8;
        margin-top: $spacing-a-smallest;
        margin-bottom: $spacing-a-smallest;
      }
      :last-child hr {
        display: none;
      }

      .event-value {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $surface-white;
        background-color: $grey-intense-color;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
      }

      .event-name {
        max-width: 80%;
        overflow-wrap: break-word;
      }
    }
  }

  .risk-assessment {
    .details {
      color: $surface-black-color;
      background-color: $background-color;
      border: 1px solid $background-color;
      border-radius: 50px;
      padding-left: $spacing-a-small;
      padding-right: $spacing-a-small;
      padding-top: 4px;
      padding-bottom: 4px;
      transition: 0.3s;

      &:hover {
        background-color: $background-hover-color;
      }
    }

    .risk-details-container {
      animation: fadeIn 0.92s;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .risk-item {
      font-size: $font-s-medium;
      color: $surface-black-color;
      line-height: $spacing-a-xsmall;

      span {
        border-bottom: 1px solid $surface-black-color;
      }
    }

    .list-container {
      list-style-type: none;
    }

    .details-html {
      margin-top: -30px;
    }

    .citation {
      font-size: $font-s-small;
    }
  }
}

.new-patient-modal-wrapper {
  .add-patient-modal {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }
  .border-box {
    border: 2px solid #eff3f6;
    width: 130px;
    height: 115px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
  }

  .label-wrapper {
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    padding: 16px;
    color: $base-color;
  }

  .email-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .hide-warning {
    display: none;
  }

  .show-warning {
    display: block;
  }
}

.notification-modal {
  margin-bottom: $spacing-a-medium;

  .notification-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .patient-info {
    display: flex;
    gap: $spacing-a-smallest;

    .meta-info {
      font-size: 10px;
      color: $grey-intense-color;
    }

    .notification-image-link {
      border-radius: 50%;
      background-color: $bodyBackground;
      padding: $spacing-a-smallest;
    }
  }
  .img {
    height: $spacing-a-medium;
    width: $spacing-a-medium;
    border-radius: 50%;
    // filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.15));
  }
  .notification-action {
    display: flex;
    gap: $spacing-a-smaller;
    align-items: center;

    img {
      margin-right: $spacing-a-smaller;
    }

    .link-text {
      @extend .link-text;
      font-family: "Inter-Medium";
      color: $primary-color;

      img {
        margin-right: $spacing-a-smallest;
      }
    }

    .archive-btn {
      @extend .button, .small-button, .primary-button;
      background-color: $bodyBackground;
      color: $surface-black-color;

      &:hover {
        background-color: $background-hover-color !important;
      }
    }
  }

  .notification-body {
    margin-top: $spacing-a-small;
    border: 1px solid $bodyBackground;
    border-radius: 5px;
    padding: $spacing-a-smaller;
    color: $surface-black-color;
    min-height: $spacing-a-biggest;

    .quote {
      font-size: $spacing-a-xsmall;
    }

    .message {
      margin-top: 28px;
      width: 100%;
    }
  }

  .notification-footar {
    display: flex;
    justify-content: center;
    margin-top: $spacing-a-medium;
  }
}
