html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background-color: #eff3f6;
}

#root,
.App {
  height: 100%;
}

// * {
//   word-break: break-word;
// }
