.patient-evaluations {
  .evaluation-details {
    padding-top: 76px;

    .box {
      position: relative;
      @include box(28px 16px 28px 32px);
      background-color: $surface-white;
      @include drop-shadow(0, 2px, 4px, -1px, 0.12);
      .name {
        font-family: $font-family-regular;
        color: $base-color;
        font-size: $font-s-medium;
      }
      .expand-container {
        bottom: -20px;
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        left: 50%;
        transform: translate(-50%, 0);
        .expand-collapse {
          @extend .regular-text;
          cursor: pointer;
          border-radius: $border-radius2;
          background-color: $background-color;
          padding: 4px $spacing-a-small;
          margin: $spacing-a-smallest;
          @include drop-shadow(0, 2px, 4px, -1px, 0.12);
          &.active {
            background-color: $primary-color;
            color: $surface-white;
          }
        }
      }
    }
  }
}
