.report-content {
  display: grid;
  grid-template-rows: min-content auto;
  align-content: flex-start;
  //grid-gap: 1em;
  padding-bottom: 1em;
  grid-template-columns: 1fr;

  .line {
    margin-bottom: 0;
  }
}

.indicator-value {
  font-size: 26px;
}

.report-card .card-body {
  // margin: 0px 20px !important;
  position: relative;

  img {
    max-height: 90%;
    max-width: 100%;
  }

  textarea {
    resize: none;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * WRAPPER * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

.report-content {
  grid-gap: 0.5em;
}

.wrapper {
  width: 100%;
  // padding: 0em 1em;
  /* height:                     100%; */
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  /*grid-auto-rows:             4em;*/
  grid-auto-rows: 6em;
  grid-gap: 1em;

  .card {
    padding: 6px;
    grid-template-rows: min-content;
    //position: relative;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * *  cards  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

.card {
  display: grid;
  grid-gap: 0em;
  background-color: white;
  margin: 0;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);

  -moz-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);

  /*margin: 0px auto 1em auto;*/
  padding: 1em;

  animation: $slide-in-animation;

  /* inherit from design-system block class */
  background: #fcfdfe;
  //box-shadow: 0 2px 5px 0 rgba(212, 212, 212, 0.2);
  border-radius: 5px;
}

.report-card {
  .details-button {
    border-radius: $border-radius2;
    background-color: $background-color;
    padding: 3.5px $spacing-a-smaller;
    font-size: $font-s-regular;
    color: $base-color;
    cursor: pointer;
  }

  .tooltip-test {
    /* position: absolute;
    display: none;
    background-color: red;
    z-index: 10; */
    z-index: 3;
    position: absolute;
    background-color: $base-color !important;
    color: $surface-white !important;
    font-size: $font-s-regular !important;
    font-family: $font-family-regular !important;
    max-width: 300px !important;
    min-width: 200px !important;
    border: 1px solid $base-color;
    border-radius: 5px;
    padding: 3px;
    display: none;
    cursor: default;
  }
}

.card-title {
  font-size: 1.2em;
  font-weight: bold;
  color: $kgray-color;
  margin: 0;
}

.simple-card {
  background-color: white;
  border: $border;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}

#overlay-card .card {
  padding: 0;
  //border-left: 0.3em solid $kblue-color;
  grid-template-rows: min-content;
  position: relative;
}

.type1 {
  padding: 0;
  //border-left: 0.3em solid $kblue-color;
  grid-template-rows: min-content;
  position: relative;
  grid-area: span 5 / span 5;
}

.card-header {
  /* display: grid;
  grid-template-columns: 2.5fr 1fr 0.2fr; */
  padding: 0.5em;
  background-color: $surface-white;
  border-bottom: none;
  /* display: flex;
  flex-direction: row; */
  align-items: center;
  // justify-content: space-between;
  p {
    margin-bottom: 0;
  }

  .title-wrapper {
    font-size: 1.1em;
    font-weight: bold;
    align-items: center;

    /*white-space: nowrap;*/
    display: inline-block;

    .actions {
      /* grid-gap: 1em; */

      .button {
        border-radius: 50%;
        width: 2em;
        height: 2em;
        color: white;
        font-size: 1em;
        display: grid;
        align-items: center;
        justify-items: center;
        background-color: #ccc;
        box-shadow: $box-shadow;
      }
    }
  }

  .ht {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    position: relative;

    img {
      height: 0.625rem;
      width: 0.625rem;
      align-self: flex-start;
    }

    /* .tooltip {
       
    } */
  }
}

.actions .button:hover {
  background: $kblue-color;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
}

.delete-card-button {
  position: absolute;
  right: 0.5em;
  top: 0.7em;
  cursor: pointer;
  display: none;
}

//.ghost {
//    opacity: 0.3;
//}

/* Important: move to scss after refactor */

.card-header {
  .help-icon,
  .actions {
    display: inline-flex;
    gap: $spacing-a-smaller;
    /* display: grid; */
    /* justify-self: right; */
    /*     grid-template-columns: 1fr 1fr 2.5fr;
    grid-gap: 5px;
 */
    /* align-items: center; */
  }

  .help-icon {
    display: grid;
  }

  .selectInput {
    margin-bottom: 0px;
  }
}

.card.help {
  grid-template-rows: min-content auto min-content;

  span {
    align-self: center;
  }

  &.show .fa-question-circle {
    padding: 0.1em 0;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* CARD TYPES  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

.report-card {
  &:not([data-card_type="1"]):not([data-card_type="14"]) {
    //overflow: hidden;
    padding-bottom: 0.5em;
  }

  .card-header {
    //overflow: hidden;
  }

  &[data-card_type="19"] .card-body,
  &[data-card_type="5"] .card-body {
    display: grid;
    grid-template-rows: auto min-content;
    // grid-gap: 1em;
    padding: 0 !important;
    margin: 0 !important;
    // padding: 0 1em 0.5em 1em;

    .areaInput {
      background-color: $background-color;
      padding: $spacing-a-small;
      margin: 4px;
      border: none;
      color: $surface-black-color;
      font-size: $font-s-regular;
    }
  }

  &[data-card_type="6"] .card-body {
    display: grid;
    padding: 0 1em 0.5em 1em;
    min-height: 0;
  }

  //&[data-card_type='25'] textarea, &[data-card_type='19'] textarea, &[data-card_type='5'] textarea {
  //    resize: none;
  //    background-color: $background-color;
  //    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  //    color: #454e59;
  //    border: 1px solid rgba(0, 0, 0, 0.2);
  //    outline: none;
  //    box-shadow: $box-shadow;
  //    border-radius: $border-radius;
  //    padding: 0.5em;
  //}

  &[data-card_type="6"] .card-body span {
    resize: none;
    background-color: $background-color;
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #454e59;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    padding: 0.5em;
    overflow-y: auto;
  }

  //&[data-card_type='25'] textarea, &[data-card_type='19'] textarea, &[data-card_type='5'] textarea {
  //    --focus-color:$kblue-color;
  //}
  //
  //&[data-card_type='25'] textarea, &[data-card_type='19'] textarea:focus, &[data-card_type='5'] textarea:focus {
  //    border-bottom: 2px solid $focus-color;
  //}

  &[data-card_type="1"] .card-body .line {
    fill: none;
    stroke: $kgreen-color;
    stroke-width: 5;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  &[data-card_type="14"] .card-body {
    .line {
      fill: none;
      stroke: $kgreen-color;
      stroke-width: 5;
      stroke-linecap: round;
      stroke-linejoin: round;
    }

    .domain {
      stroke: lightgrey;
      stroke-opacity: 0.7;
      fill: none;
    }
  }

  &[data-card_type="1"] .card-body .domain {
    stroke: lightgrey;
    stroke-opacity: 0.7;
    fill: none;
  }

  &[data-card_type="14"] .card-body .svg-div,
  &[data-card_type="1"] .card-body .svg-div {
    position: relative;
  }

  &[data-card_type="9"] .card-body .plot-tooltip {
    min-height: 2em;
  }

  &[data-card_type="14"] .card-body .plot-tooltip,
  &[data-card_type="9"] .card-body .plot-tooltip,
  &[data-card_type="1"] .card-body .plot-tooltip {
    position: absolute;
    text-align: center;
    /* width: 10em; */
    max-width: 12em;
    height: fit-content;
    padding: 0.5em 0.8em;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    pointer-events: none;
    --left: 50%;
    border-radius: 5px;
  }

  &[data-card_type="14"] .card-body .chart-line,
  &[data-card_type="1"] .card-body .chart-line {
    fill: none;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-width: 0.4em;
  }

  &[data-card_type="14"] .card-body .chart-circle,
  &[data-card_type="1"] .card-body .chart-circle {
    //fill: white;
    stroke-width: 0;
  }

  &[data-card_type="14"] .card-body .plot-tooltip,
  &[data-card_type="9"] .card-body .plot-tooltip,
  &[data-card_type="1"] .card-body .plot-tooltip {
    background-color: white;
  }
}

.card-body .tick line,
.tick text {
  stroke: lightgrey;
  stroke-opacity: 0.7;
}

.report-card {
  .card-body .grid {
    line {
      stroke: lightgrey;
      stroke-opacity: 0.7;
      shape-rendering: crispEdges;
      z-index: -1;
    }

    path {
      stroke-width: 0;
    }
  }

  &[data-card_type="14"] .card-body .hover-line,
  &[data-card_type="1"] .card-body .hover-line {
    stroke-width: 2px;
    stroke-dasharray: 3, 3;
  }

  &[data-card_type="8"] .card-body {
    display: grid;
    grid-template-rows: min-content auto min-content;
    padding-bottom: 1em;
    grid-gap: 1em;
    min-height: 0;
  }

  &[data-card_type="1"] .card-body,
  &[data-card_type="14"] .card-body {
    display: grid;
    grid-template-rows: auto;
    padding-bottom: 1em;
    grid-gap: 1em;
    min-height: 0;
  }

  &[data-card_type="8"] .card-body .events-plot-dropdown {
    @extend .dropdown;
    width: 20%;
    justify-self: flex-end;
  }

  &[data-card_type="3"],
  &[data-card_type="4"],
  &[data-card_type="9"] .card-body {
    min-height: 0;
  }

  &[data-card_type="16"] {
    min-height: 0;

    .card-body {
      min-height: 0;
    }
  }

  &[data-card_type="10"] .card-body {
    min-height: 0;
  }

  &[data-card_type="1"] .card-body .svg-div,
  &[data-card_type="14"] .card-body .svg-div,
  &[data-card_type="20"] .card-body .svg-div,
  &[data-card_type="2"] .card-body .svg-div,
  &[data-card_type="8"] .card-body .svg-div,
  &[data-card_type="9"] .card-body .svg-div,
  &[data-card_type="10"] .card-body .svg-div {
    display: grid;
    min-height: 0;
  }

  &[data-card_type="2"] .card-body .svg-div,
  &[data-card_type="9"] .card-body .svg-div,
  &[data-card_type="10"] .card-body .svg-div {
    height: 100%;
  }

  &[data-card_type="20"] .card-body .svg-div {
    svg {
      height: 100%;
    }

    padding-bottom: 0em;
  }

  &[data-card_type="2"] .card-body .svg-div {
    padding: 2em 3em;
    height: 100%;
  }

  &[data-card_type="2"] .card-body .svg-div-modal {
    padding: 2rem 0.5rem !important;
    min-height: 250px;
  }

  &[data-card_type="1"] .card-body svg,
  &[data-card_type="2"] .card-body svg,
  &[data-card_type="8"] .card-body svg,
  &[data-card_type="9"] .card-body svg,
  &[data-card_type="10"] .card-body svg {
    width: 100%;
    height: 100%;
  }

  &[data-card_type="14"] .card-body svg {
    width: 95%;
    height: 95%;
    display: block;
    margin: auto;
  }

  &[data-card_type="16"] iframe {
    width: 100%;
    height: 100%;
  }

  &[data-card_type="2"] .card-body {
    padding: 0.5em;
  }

  &[data-card_type="20"] .card-body {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 0.5em;

    //> span:not(.division):not(.description):not(.main-value) {
    //    font-size: 1.2em;
    //    font-weight: bold;
    //    padding: 0.5em;
    //}
  }

  &[data-card_type="2"] .card-body .svg-div {
    background-color: $background-color;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  &[data-card_type="20"] .card-body svg .bar,
  &[data-card_type="2"] .card-body svg .bar {
    stroke: $kgray-color;
    stroke-width: 0.5px;
  }

  &[data-card_type="20"] .card-body svg .score-bar,
  &[data-card_type="2"] .card-body svg .score-bar {
    fill: $kgreen-color;
    stroke: $kgray-color;
    stroke-width: 0.5px;
  }

  &[data-card_type="20"] .card-body svg .background-bar {
    fill: rgba(0, 0, 0, 0.1);
  }

  &[data-card_type="2"] .card-body svg {
    .background-bar {
      fill: rgba(0, 0, 0, 0.1);
    }

    //.bar-text {
    //    font-size: 0.9em;
    //
    //    /* letter-spacing: 0.03em; */
    //}
  }

  &[data-card_type="20"] .card-body svg .axis-text,
  &[data-card_type="2"] .card-body svg .axis-text {
    font-size: 0.8em;
    fill: $kgray-color;
  }

  &[data-card_type="1"] svg path:not(.domain),
  &[data-card_type="14"] svg path:not(.domain),
  &[data-card_type="10"] svg path:not(.domain),
  &[data-card_type="8"] svg path:not(.domain) {
    vector-effect: non-scaling-stroke;
  }

  .domain {
    fill: none;
    stroke: lightgray;
    stroke-opacity: 0.7;
  }

  &[data-card_type="8"] svg .interactive-plot-line {
    stroke-width: 1.5px;
  }

  &[data-card_type="10"] svg {
    .event-plot-line,
    .event-plot-normal-line {
      stroke-width: 1.5px;
    }
  }
}

/* .report-card[data-card_type = '1'] .card-body, */

/* .report-card[data-card_type = '8'] .card-body{
    grid-template-rows: min-content min-content auto min-content;
} */

/* .report-card[data-card_type = '20'] .card-body .svg-div, */

/* .report-card[data-card_type = '16'] .card-body svg .tick text,
.report-card[data-card_type = '16'] .card-body svg .mpld3-text{
    font-size: 0.75em !important;
} */

/* .report-card[data-card_type = '20'] .card-body .svg-div, */

/* .report-card[data-card_type = '20'] .card-body svg .bar-text, */

.bold-line {
  stroke-width: 3px !important;
  visibility: visible !important;
}

.report-card {
  &[data-card_type="10"] svg {
    .event-plot-normal-line {
      fill: none;
      stroke: #0e6d24;
      opacity: 1;
    }

    .event-plot-normal-area {
      fill: #aeffc8;
      stroke: #aeffea;
      opacity: 0.5;
    }
  }
  &[data-card_type="mk"] svg {
    .hide-tags {
      display: none;
    }

    .grow {
      cursor: pointer;
    }
  }

  &[data-card_type="mk"] .interactive-plot-labels {
    display: grid !important;
    grid-auto-flow: column;
    overflow-x: auto;
    min-height: 2.6em;
    /* this is necessary to make the plot render in a more aproximate size to it's final one */
    grid-gap: 1em;
    padding: 0 0.5em;
    justify-content: center;

    span.disabled.wait-mouseout {
      opacity: 0.2;
    }
    span.disabled.wait-mouseout:hover {
      opacity: 1;
    }

    .deselect-button {
      color: white;
      border-radius: 50%;
      box-shadow: $box-shadow;
      width: 1.4em;
      height: 1.4em;
      padding: 0.2em;
      display: grid;
      align-items: center;
      justify-items: center;
    }

    .interactive-plot-label-container,
    deselect-labels {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 0.2em;
      cursor: pointer;
      //grid-column-gap: 0.8em;

      span {
        color: white;
        border-radius: 4px;
        box-shadow: $box-shadow;
        width: auto;
        height: 1.8em;
        padding: 0.2em;
        display: grid;
        align-items: center;
        justify-items: center;

        &:nth-child(even):not(.deselect-button) {
          justify-self: flex-end;
        }

        &:nth-child(odd) {
          justify-self: flex-start;
        }
      }

      .label-container-title {
        text-align: center;
        grid-area: 1 / span 2;
      }
    }
  }
  &[data-card_type="mk"] .interactive-plot-label-container {
    span {
      box-shadow: none;
    }
  }
  &[data-card_type="mk"] .card-body .plot-tooltip {
    min-height: 2em;
    padding: 0.2em 0.8em 0.2em 0.8em;
    box-shadow: none;
    height: fit-content;
    white-space: nowrap;
    width: fit-content;
    margin: auto;
    background-color: white;
    position: absolute;
    display: none;
    border-radius: 4px;
  }

  &[data-card_type="mk"] .plot-actions {
    width: fit-content;
  }

  &[data-card_type="8"] .actions-row {
    display: flex;
    .interactive-plot-label {
      color: $surface-white;
      min-width: 2.5rem;
      border-radius: 50px;
      text-align: center;
      min-height: 24px;
      cursor: pointer;
    }
  }

  &[data-card_type="8"] .interactive-plot-labels {
    flex: 1;
    display: grid;
    overflow-x: auto;
    min-height: 2.6em;
    grid-template-columns: repeat(auto-fill, 7.5em);
    // grid-template-columns: repeat(auto-fill, minmax(max(200px, 12.5%, 1fr));
    // grid-template-columns: minmax(4fr, 8fr);

    /* this is necessary to make the plot render in a more aproximate size to it's final one */
    grid-gap: 2rem 0.25rem;
    padding: 0 0.5em;
    padding-right: 100px;
  }
  &[data-card_type="10"] .events-plot-labels {
    display: grid;
    grid-auto-flow: column;
    overflow-x: auto;
    min-height: 2.6em;

    /* this is necessary to make the plot render in a more aproximate size to it's final one */
    grid-gap: 0.25em;
    // padding: 0 0.5em;
  }

  .deselect-button {
    background: $bodyBackground;
    color: $surface-black-color !important;
  }

  &[data-card_type="10"] {
    .events-plot-labels {
      display: inline-flex;
      flex-flow: wrap;
      gap: 1rem;
      min-height: 2.4em;
      align-items: center;
    }

    .events-plot-label-container {
      color: $surface-white;
      min-width: 2.5rem;
      border-radius: 50px;
      text-align: center;
      cursor: pointer;
    }

    .plot-disabled {
      // background-color: $grey-intense-color !important;
      opacity: 40%;
    }
  }

  &[data-card_type="10"] .events-plot-labels span {
    color: white;
    border-radius: 20%;
    box-shadow: $box-shadow;
    width: 1.4em;
    height: 1.4em;
    padding: 0.2em;
    display: grid;
    align-items: center;
    justify-items: center;
  }

  &[data-card_type="10"] .events-plot-labels span.disabled,
  &[data-card_type="8"] .interactive-plot-labels span.disabled {
    /* color: $kgray-color; */
    opacity: 0.2;
  }

  &[data-card_type="10"] .events-plot-labels span:hover,
  &[data-card_type="8"] .interactive-plot-labels span:hover {
    opacity: 1;

    /* cursor: pointer; */
  }

  &[data-card_type="10"] .events-plot-labels span.disabled.wait-mouseout:hover {
    opacity: 0.2;
  }

  &[data-card_type="8"] .interactive-plot-labels {
    span.disabled.wait-mouseout:hover {
      opacity: 0.2;
    }

    .deselect-button {
      /* color: white;
      border-radius: 50%;
      box-shadow: $box-shadow;
      width: 1.4em;
      height: 1.4em;
      padding: 0.2em;
      display: grid;
      align-items: center;
      justify-items: center; */
    }

    .interactive-plot-label-container,
    deselect-labels {
      display: grid;
      align-content: space-between;
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 0.2em;
      grid-column-gap: 0.8em;

      .interactive-plot-label {
        color: $surface-white;
        min-width: 2.5rem;
        border-radius: 50px;
        text-align: center;
        min-height: 24px;
        cursor: pointer;
      }

      .deselect-button {
        background: $bodyBackground;
        color: $surface-black-color !important;
      }

      .plot-disabled {
        opacity: 40%;
      }

      span {
        color: white;
        border-radius: 20%;
        box-shadow: $box-shadow;
        width: 1.4em;
        height: 1.4em;
        padding: 0.2em;
        display: grid;
        align-items: center;
        justify-items: center;

        &:nth-child(even):not(.deselect-button) {
          justify-self: flex-end;
        }

        &:nth-child(odd) {
          justify-self: flex-start;
        }
      }

      .label-container-title {
        text-align: center;
        grid-area: 1 / span 2;
      }

      .middle_label {
        grid-area: span 1 / span 2;
        justify-self: center;
      }
    }
  }

  //&[data-card_type='10'] .plot-actions, &[data-card_type='8'] .plot-actions {
  //    display: grid;
  //    grid-auto-flow: column;
  //    align-items: center;
  //    justify-content: center;
  //    grid-gap: 1em;
  //    padding: 6px;
  //}
  //
  //&[data-card_type='10'] .plot-actions {
  //    /* justify-content: normal; */
  //}

  .plot-actions,
  .line-actions {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1em;
    padding: 6px;
    // background: $light;
    // margin: 16px auto;
    border-radius: 4px;
    text-transform: capitalize;
    background: "transparent" !important;
  }

  &[data-card_type="mk"] {
    .plot-actions,
    .line-actions {
      margin: 4px auto;
    }
  }

  &[data-card_type="mk"] {
    .plot-actions,
    .line-actions {
      margin: 4px auto;
    }
  }

  &[data-card_type="8"] {
    .deselect-labels {
      .line-actions {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        align-items: center;
        justify-content: center;
        grid-gap: 1em;
        // padding: 6px;
        background: transparent !important;
        // margin: 16px auto;
        border-radius: 4px;
      }
    }
    .line-actions,
    .plot-actions {
      grid-template-columns: 1fr 1fr;
      grid-auto-flow: row;

      &.row {
        grid-template-columns: auto;
        grid-auto-flow: column;
        align-items: flex-start;
        padding: 0;
        .plot-disabled {
          // background-color: $grey-intense-color !important;
          opacity: 40%;
        }
      }
    }
  }
}

/* .report-card[data-card_type = '10'] .plot-actions .action{
    margin-left: 1.8em;
} */

.green {
  background-color: $kgreen-color;
}

.red {
  background-color: $mkinetikos-red-color;
}

.blue {
  background-color: $kblue-color;
}

.lightgray {
  background-color: $klightgray-color !important;
  opacity: 0.2;
}

.action {
  cursor: pointer;

  .icon {
    opacity: 1;
  }

  &.disabled {
    .icon {
      opacity: 0.7 !important;
    }
  }
}

.report-card {
  svg .hide-svg-element,
  &[data-card_type="8"] svg .hide-tags {
    display: none;
  }
}

.card-header .help-icon.hide-element {
  display: none;
}

.report-card {
  &[data-card_type="20"] svg {
    .horizontal-line,
    .vertical-line {
      stroke: $kgray-color;
      stroke-width: 2px;
      stroke-dasharray: 3, 3;
    }
  }

  &[data-card_type="2"] svg .tags-vertical-line {
    stroke: $kgray-color;
    stroke-width: 2px;
    stroke-dasharray: 3, 3;
  }

  &[data-card_type="8"] svg {
    .tags-vertical-line {
      stroke: $kgray-color;
      stroke-width: 2px;
      stroke-dasharray: 3, 3;
    }

    .tag-circle {
      fill: none;
      stroke-width: 2px;
    }
  }

  &[data-card_type="10"] .card-body {
    display: grid;

    /* grid-template-rows: auto min-content; */
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 1em;
    grid-gap: 1em;
    min-height: 0;

    .events-plot-side-wrapper {
      display: grid;
      //grid-gap: 1em;
      min-height: 0;
      grid-template-rows: min-content min-content min-content min-content auto min-content;

      .dropdown-div {
        // margin: 0 2em;
        display: grid;
        grid-template-columns: min-content auto;
        grid-gap: 1em;
        align-items: center;

        //span {
        //  font-size: 1.2em;
        //  font-weight: bold;
        //}

        .title {
          font-size: $font-s-regular;
          font-weight: 700;
          color: $base-color;
        }

        .plot-dropdown {
          @extend .dropdown;
          margin: 0;
        }
      }
    }
  }

  .plot-dropdown {
    @extend .dropdown;
    margin: 0;
    min-width: 200px;
  }

  &[data-card_type="7"] {
    .card-body {
      /* padding:0 1em 1em 1em; */
      display: grid;
      //overflow: hidden;

      .video-div {
        display: grid;
        align-self: center;
        justify-items: center;
        height: 100%;
        background: black;
        border: 1px solid black;
        /* align-self: end; */
        border-radius: 4px;
        /*calc(7vh * 6);*/
        //overflow: hidden;
      }
    }

    .card-header .actions {
      i {
        vertical-align: middle;
      }

      div:hover {
        /* cursor: pointer; */
        color: $kblue-color;
      }
    }
  }

  &[data-card_type="12"] .card-body {
    display: grid;
    grid-template-rows: 2fr 2px 1fr 1fr;
    justify-items: center;

    .main-value {
      font-size: 4em;
      font-weight: bold;
      align-self: flex-end;

      span {
        font-size: 0.5em;
      }
    }

    .division {
      background-color: $kblue-color;
      width: 80%;
      border-radius: 4px;
    }

    .description {
      color: $kgray-color;
      width: 80%;
      font-size: 1em;
      padding-top: 0.5em;
      text-align: center;
    }
  }
}

video {
  max-width: 70%;
  margin: auto;
  /* align-self: end; */
  border-radius: 4px;
}

/* video::-webkit-media-controls {
  display:none !important;
} */

.report-card select {
  /*margin: 0 2em;*/
  /*padding: 0.2em 0.5em;*/
  /*font-size: 0.8em;*/
  /*min-height: 1em;*/
  width: max-content;
}

select {
  style: none;
  background-color: white;
  border: $border;
  border-radius: $border-radius;
  //box-shadow: $box-shadow;
}

.report-card {
  &[data-card_type="11"] {
    .card-body {
      padding-bottom: 0.5em;
      display: grid;
      grid-gap: 1em;
      grid-template-rows: auto min-content;
      justify-items: center;
      min-height: 0;

      h3 {
        &:first-child {
          margin: 0;
          margin-bottom: 0.5em;
        }

        &:not(:first-child) {
          margin-top: 1em;
          margin-bottom: 0.5em;
        }
      }

      .metrics {
        overflow-y: auto;
        padding: 0 1em;
        padding-bottom: 0.1em;
      }

      .metrics-wrapper {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        transition: all 0.5s ease-out;

        /* .metric-wrapper {
          display: grid;
          grid-gap: 1em;
          padding: 0.5em;
        } */

        .metric-wrapper {
          align-items: center;
        }

        .metric-name {
          font-weight: bold;
        }
      }
    }

    #downloadMetrcis {
      position: absolute;
      right: 1em;
      top: 1em;
    }
  }

  &.error-card .card-body {
    display: grid;
    grid-template-rows: 3fr 2px 2fr;
    grid-template-columns: 1fr;
    justify-items: center;

    .main-value {
      font-size: 4em;
      font-weight: bold;
      align-self: flex-end;

      span {
        font-size: 0.5em;
      }
    }

    .division {
      background-color: $mkinetikos-red-color;
      width: 80%;
      border-radius: 6px;
    }

    .description {
      color: $kgray-color;
      width: 80%;
      font-size: 0.8em;
      padding-top: 0.5em;
      text-align: center;
    }
  }

  &[data-card_type="15"] .card-body {
    overflow-y: auto;
  }

  &[data-card_type="15"] .card-body ul {
    list-style-type: none;
    display: grid;
    grid-gap: 0.5em;
  }

  &[data-card_type="15"] .card-body a {
    text-decoration: none;

    &:hover {
      border-bottom: 0.2em solid $kgreen-color;
    }
  }

  &[data-card_type="3"] .card-body {
    .contentWrapper {
      display: grid;
      align-content: center;
      justify-content: center;
    }

    i {
      color: #4b4b4b;
    }
  }

  &[data-card_type="17"] .card-body {
    display: grid;
    align-items: center;
    justify-content: center;

    &:hover {
      /* cursor: pointer; */
    }
  }

  &[data-card_type="18"] {
    min-width: 0;

    .card-body {
      display: grid;
      align-items: center;
      justify-items: center;
      grid-template-rows: auto min-content;
      grid-gap: 0.5em;

      input {
        display: none;
      }

      label {
        /* cursor: pointer; */
        width: 80%;
        text-align: center;
      }

      .drop-area {
        background-color: $background-color;
        border: 2px dashed $klightgray-color;
        height: 100%;
        display: grid;
        align-items: center;
        justify-items: center;
        width: 90%;

        &.ready-to-drop {
          background-color: white;
          border-color: $kblue-color;

          &:after {
            content: "\f0ee";

            /* this is your text. You can also use UTF-8 character codes as I do here */
            font-family: FontAwesome;
            font-size: 5em;
            color: $kblue-color;
          }
        }
      }
    }
  }

  &[data-card_type="21"] .card-body {
    max-height: 100%;
    //overflow: hidden;
  }

  &[data-card_type="3"] .card-body {
    display: inherit;
    position: relative;

    /* cursor: pointer; */
    text-align: center;
    -webkit-transition: transform 0.1s ease-out;
    -moz-transition: transform 0.1s ease-out;
    -o-transition: transform 0.1s ease-out;
    transition: transform 0.1s ease-out;
    //overflow: hidden;
  }
}

/* .report-card[data-card_type = '18'] .card-body .drop-area.with-files{
    display: grid;
    grid-template-rows: min-content min-content auto;
    overflow-y: auto;
} */

.circle-div {
  display: inherit;
  position: relative;
  padding: 18px;

  /* cursor: pointer; */
  text-align: center;
  -webkit-transition: transform 0.1s ease-out;
  -moz-transition: transform 0.1s ease-out;
  -o-transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out;
  //overflow: hidden;
}

.report-card[data-card_type="3"] .card-body .fa,
.circle-div .fa {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 5em;
  text-align: center;
  display: grid;
  align-items: center;
  z-index: 2;
  visibility: hidden;
  display: none;
}

.report-card[data-card_type="3"] .card-body:hover,
.circle-div:hover {
  /* transform: scale(1.02); */
}

.report-card[data-card_type="3"] .card-body:hover .fa,
.circle-div:hover .fa {
  visibility: visible;
}

.no-data {
  .fa {
    visibility: visible;
  }

  .circle-plot,
  .score-title {
    /* opacity: 0.3;  */
  }
}

.report-card[data-card_type="3"] .card-body:hover span {
  /* opacity: 0.3;  */
}

.circle-div:hover {
  .circle-plot,
  .score-title {
    /* opacity: 0.3;  */
  }
}

.circle-plot {
  width: 100%;
  height: 100%;
  padding: 0 10% 10% 10%;

  circle {
    stroke-width: 6;
    fill: none;
  }
}

/* .circle-plot text{
    font-size: 4em;
} */

#background {
  stroke: $background-color;

  /* stroke: $kgray-color; */
}

#percentage {
  stroke-linecap: round;
}

.circle-plot {
  #percentage {
    transform: rotate(270deg);
    transform-origin: center;
  }

  fill: $kgray-color;
  stroke-width: 0;
  stroke: $kblue-color;

  /* border-bottom: 0.5em solid $kblue-color; */
}

.header-tabs-fixed.report-header {
  top: 3em;
  grid-gap: 0;
  width: calc(100% - 15em);

  /* padding-left: 14em; */
  right: 0;

  /* background: white; */
  position: fixed;
  z-index: 4;

  /* z-index: 100; */
  /* box-shadow:                 0px 5px 5px rgba(128,128,128,0.4); */
  /* padding:                    0 .5em; */
}

.fullscreen .header-tabs-fixed.report-header {
  width: calc(100vw - 2em);
}

.header-tabs-fixed {
  .header-content {
    /* padding: 0.2em 1em; */
    padding: 0.2em 0.5em;
  }

  &.report-header .header-content {
    .patient-name {
      /* font-size: 1.2em; */
      font-size: 1.1em;
      transition: font-size 0.15s ease-out;
    }

    .report-status .status {
      padding: 0.4em 1em;
      font-size: 0.45em;
      transition: all 0.15s ease-out;
    }

    .patient-metadata {
      display: none;
    }
  }

  .actions {
    display: none;
  }

  ~ #grid-drag-drop {
    padding-top: 9em;
  }

  .header-tabs {
    font-size: 0.9em;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * MOBILE  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .wrapper {
    width: 98vw;
    grid-gap: 0.5em;
  }

  .report-header {
    padding: 0 0.5em;
    --scroll-threshold: 9;

    .header-content {
      margin: 0;
      padding: 1em 0.5em;
      grid-template-columns: auto;
      grid-template-rows: auto min-content min-content min-content;
      grid-gap: 0.5em;
      align-items: center;

      .patient-name {
        font-size: 1.2em;
        text-align: center;
      }

      .report-status {
        grid-gap: 0.5em;
        font-size: 1.2em;
        justify-content: center;
      }

      .actions {
        display: grid;
        justify-content: center;
        grid-auto-flow: column;
        font-size: 0.8em;
        grid-area: 1 / 1 / 2 / 2;
      }

      .patient-metadata {
        grid-area: 3 / 1 / 4 / 2;

        /* justify-content:            center; */

        .metadata-entry {
          font-size: 0.7em;
        }
      }
    }

    .header-tabs .tab {
      padding: 0.3em 0.5em;
    }
  }

  .header-tabs-fixed {
    &.report-header {
      width: initial;
      left: 0;
      grid-template-rows: min-content;

      .header-content {
        display: none;
      }
    }

    ~ #grid-drag-drop {
      padding-top: 12em;
    }
  }

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
  /* OVERLAY * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  .overlay > .card {
    width: 90vw;
  }

  #exam-checklist-card {
    width: 95vw;
    height: 65vh;
  }

  #template-name-card {
    width: 95vw;
  }

  #template-edition-tools {
    left: 0;
    top: initial;
    bottom: 0;
    transform: none;
    -webkit-box-shadow: 0px -5px 20px -6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px -5px 20px -6px rgba(0, 0, 0, 0.75);
    box-shadow: 0px -5px 20px -6px rgba(0, 0, 0, 0.75);
    border: initial;
    border-radius: 0;

    .actions {
      grid-auto-flow: column;
      justify-content: space-around;

      div:nth-child(2) {
        display: none;
      }
    }
  }

  .card {
    &.help,
    &.done {
      width: 80%;
    }
  }

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
  /* WRAPPER * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  #grid-drag-drop {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 5.5em;
  }

  .card:not(.metric-wrapper) {
    grid-area: span 5 / span 2 !important;
  }

  .report-card {
    &[data-card_type="3"],
    &[data-card_type="4"] {
      grid-area: span 4 / span 1 !important;
    }

    &[data-card_type="8"] {
      grid-area: span 8 / span 2 !important;
    }

    &[data-card_type="7"],
    &[data-card_type="11"],
    &[data-card_type="20"],
    &[data-card_type="2"] {
      grid-area: span 6 / span 2 !important;
    }

    &[data-card_type="10"] {
      grid-area: span 15 / span 2 !important;

      .card-body {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        padding-bottom: 0.2em;
        grid-gap: 0.4em;
        min-height: 0;

        .plot-disabled {
          // background-color: $grey-intense-color !important;
          opacity: 40%;
        }

        .events-plot-side-wrapper {
          padding: 0 0.4em;
        }
      }
    }

    &[data-card_type="8"] .interactive-plot-labels span,
    &[data-card_type="10"] .events-plot-labels span {
      font-size: 0.8em;
    }

    &[data-card_type="8"] {
      //.interactive-plot-labels .interactive-plot-label-container .label-container-title {
      //    font-size: 0.8em;
      //}
      .interactive-plot-label-container {
        .line-actions {
          grid-auto-flow: unset;
          grid-template-columns: repeat(2, 1fr);
          margin: auto;
          background-color: "transparent" !important;
        }
      }
      svg .interactive-plot-line {
        stroke-width: 0.8px;
      }
    }

    &[data-card_type="10"] svg {
      .event-plot-line,
      .event-plot-normal-line {
        stroke-width: 0.8px;
      }
    }
  }

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
  /* PLOTS * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  .bold-line {
    stroke-width: 1.2px !important;
  }

  .report-card {
    &[data-card_type="14"] .card-body .hover-line,
    &[data-card_type="1"] .card-body .hover-line {
      stroke-width: 0.8px;
      stroke-dasharray: 4, 2;
    }

    &[data-card_type="20"] .card-body .svg-div,
    &[data-card_type="2"] .card-body .svg-div {
      padding: 0.5em 0.5em;
      height: calc(100% - 1em);
    }

    //&[data-card_type='20'] .card-body svg .bar-text, &[data-card_type='2'] .card-body svg .bar-text {
    //    /* font-size: 0.9em; */
    //    font-size: 0.6em;
    //    letter-spacing: 0.03em;
    //}

    &[data-card_type="11"] .card-body {
      .metrics-wrapper {
        grid-template-columns: repeat(3, 1fr);
      }

      .metric-wrapper {
        grid-gap: 0.5em;
      }

      .metrics-wrapper {
        .metric-name {
          font-size: 0.7em;
        }

        .metric-value {
          align-self: flex-end;
        }
      }
    }

    &[data-card_type="14"] .card-body .plot-tooltip,
    &[data-card_type="1"] .card-body .plot-tooltip {
      padding: 0.2em 0.8em 0.2em 0.8em;
      box-shadow: none;
      bottom: 0.5em;
      height: fit-content;
      white-space: nowrap;
      left: 0 !important;
      right: 0 !important;
      width: 90%;
      margin: auto;
      border: none !important;
      top: inherit !important;
      min-height: 0;
    }

    //&[data-card_type='14'] .card-body .plot-tooltip br, &[data-card_type='1'] .card-body .plot-tooltip br {
    //    display: none;
    //}
    //
    //&[data-card_type='14'] .card-body .plot-tooltip span:first-child, &[data-card_type='1'] .card-body .plot-tooltip span:first-child {
    //    margin-right: 2em;
    //}
    //
    //&[data-card_type='14'] .card-body .plot-tooltip:after, &[data-card_type='1'] .card-body .plot-tooltip:after {
    //    display: none;
    //}
  }

  /* triangle decoration */
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  #grid-drag-drop {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 8.5em;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  #grid-drag-drop {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 8.5em;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  #grid-drag-drop {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-auto-rows: 6em;
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  #grid-drag-drop {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-auto-rows: 6.5em;
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
  }

  /* 10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); }
    20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); }
    30% { -webkit-transform: translate(0px, 2px) rotate(0deg); }
    40% { -webkit-transform: translate(1px, -1px) rotate(1deg); } */
  20% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
  }

  40% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
  }

  60% {
    -webkit-transform: translate(2px, 1px) rotate(-1deg);
  }

  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
  }

  100% {
    -webkit-transform: translate(2px, 2px) rotate(0deg);
  }

  /* 100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); } */
}

.benckmark3-color {
  color: $benchmark-3;
}

.benckmark2-color {
  color: $benchmark-2;
}

.benckmark1-color {
  color: $benchmark-1;
}

.benckmark0-color {
  color: $benchmark-0;
}

.benckmarknone-color {
  color: $benchmark-none;
}

.benckmark1-2std-color {
  color: $benchmark-1-2-std_dev;
}

.benckmark2p-std-color {
  color: $benchmark-2plus-std_dev;
}

.report-card[data-card_type="25"] {
  div {
    overflow-y: auto;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      clear: both;
      padding: 20px;
      border-radius: 30px;
      margin-bottom: 2px;
      font-family: Helvetica, Arial, sans-serif;
    }
  }

  .chat_other {
    background: $klightgreen-color;
    float: left;
  }

  .chat_me {
    float: right;
    background: $kblue-color;
    color: #fff;
  }

  .chat_other + .chat_me {
    border-bottom-right-radius: 5px;
  }

  .chat_me + {
    .chat_me {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .chat_other {
      border-bottom-left-radius: 5px;
    }
  }

  .chat_other + .chat_other {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .div .action {
    text-align: center;
  }
}

.box-blue,
.box-gray,
.box-green,
.box-grey,
.box-red,
.box-yellow {
  /*display:inline-block;*/
  width: 90%;
  padding: 0.25em;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  text-align: center;
}

.box-blue {
  background: rgba(153, 204, 255, 0.5);
  border: 2px solid #afcde3;
}

.box-gray {
  background: rgba(224, 224, 224, 0.5);
  border: 1px solid #bdbdbd;
}

.box-green {
  background: rgba(153, 225, 153, 0.5);
  border: 1px solid #b2ce96;
}

.box-grey {
  background: rgba(224, 224, 224, 0.5);
  border: 1px solid #dddddd;
}

.box-red {
  background: rgba(255, 51, 51, 0.5);
  border: 1px solid #e9b3b3;
}

.box-yellow {
  background: rgba(255, 255, 153, 0.5);
  border: 1px solid #fadf98;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.notransition {
  -webkit-transform: none !important;
  transform: none !important;
  -webkit-transition: none !important;
  transition: none !important;
}
.notransition div {
  -webkit-transform: none !important;
  transform: none !important;
  -webkit-transition: none !important;
  transition: none !important;
}
.noanimation {
  animation: none !important;
}
.noanimation div {
  animation: none !important;
}

.card.help,
.card.done {
  width: 25%;
  grid-template-rows: auto min-content min-content;
  height: 35%;
  display: none;
  box-shadow: 0px 10px 10px rgba(5, 5, 5, 0.5);
  border: $border;
  text-align: center;
  z-index: 1000;
  animation: none;
  padding: 0 0 1em 0;
  position: fixed;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.card.done > .fa,
.card.help > .fa {
  color: white;
  align-items: center;
  height: 100%;
  display: grid;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}
.card.done .fa-check-circle {
  background-color: $kgreen-color;
}
.card.done .fa-times-circle {
  background-color: $mkinetikos-red-color;
}
.card.help.show .fa-question-circle {
  background-color: $kblue-color;
}
.card.help span {
  padding: 0 1em;
}
.card.done span {
  font-weight: bold;
  font-size: 1.5em;
}
.card.help .button,
.card.done .button {
  margin: auto;
  background-color: $kblue-color;
  color: white;
  border-radius: 4px;
  padding: 0.2em 0.8em;
  box-shadow: $box-shadow;
  cursor: pointer;
}

.wrapper {
  /*width: 90%;*/
  margin: auto;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * *  MEDIA  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* PHONES */
//@media (min-width: 320px) and (max-width: 480px) {
//    .wrapper{
//        width: 98%;
//    }
//}
//
///* WIDE-SCREEN */
//@media only screen and (min-width: 1050px) {
//    .wrapper{
//        /*width: 85%;*/
//    }
//    h2{
//        font-size: 25px !important;
//    }
//}
//

.loader-div {
  width: 100%;
  height: 100%;
  position: relative;
}
