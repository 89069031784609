.ev-mod-text-input {
  font-family: $font-family-regular;
  font-size: $font-s-regular;
  color: $surface-black-color;
  line-height: 14.5px;

  min-width: 250px;
  max-width: 450px;

  padding: $spacing-a-smallest $spacing-a-smaller;
  background-color: $background_color;
  border: 1px solid transparent;
  border-radius: $border-radius;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    background-color: $background-color;
    border: 1px solid $primary-color;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.ev-mod-text-area {
  font-family: $font-family-regular;
  font-size: $font-s-regular;
  color: $surface-black-color;
  line-height: 14.5px;

  width: 100%;
  resize: vertical;

  padding: $spacing-a-smallest $spacing-a-smaller;
  background-color: $background_color;
  border: 1px solid transparent;
  border-radius: $border-radius;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    background-color: $background-color;
    border: 1px solid $primary-color;
  }

  &:disabled {
    opacity: 0.5;
  }
}
