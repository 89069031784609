.privacy-element{
    padding-top: 60px;
    .text-wrapper{
        border: 2px solid $background-color;
        border-radius: $border-radius;
        padding-top: 23px;
        padding-right: $spacing-a-medium;
        padding-left: $spacing-a-medium;
        padding-bottom: $spacing-a-medium;
        .title{
            flex-direction: row;
            display: flex;
            justify-content: center;
            @extend .small-title
        }
        .body-text-wrapper{
            padding-top: 40px;
            padding-bottom: 34px;
            .body-text{
                white-space: pre-line;
                @extend .regular-text
            }

        }
    }
}