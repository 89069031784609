.input-right-image {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 12px;
  cursor: pointer;
  &.default_cursor {
    cursor: default;
  }
}

.image-cursor {
  cursor: pointer;
}

.left-arrow {
  cursor: pointer;
  margin-right: $spacing-a-small;
}

.button-icon {
  height: 14px;
  width: auto;
  margin-right: 8px;
}

.more-image-wrapper {
  position: relative;
  padding-left: 5px;
}

.edit-image {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  bottom: -6px;
  right: -8px;
  &:hover {
    transform: scale(1.15);
  }
}

.edit-image > input {
  display: none;
}

.order-img-container {
  margin-left: 4px;
  height: 14px;
  width: 14px;
  background-color: $surface-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 50%;
  cursor: pointer;

  &.active {
    background-color: $background-color;
  }

  img {
    height: 4px;
    width: 6px;
  }
}

.help-img {
  margin-left: $spacing-a-smallest;
  margin-top: -10px;
}

.edit-input {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 4px;
  margin-top: auto;
  margin-bottom: auto;
  right: 10px;
}

i.icon,
i.inputIcon {
  background-color: $kinetikosBlue;
  display: inline-block;
  width: 24px;
  height: 24px;
  opacity: 0.7;
  transition: 200ms opacity;
  mask-size: contain !important;
  -webkit-mask-size: contain !important;
  mask-repeat: no-repeat !important;

  &:hover {
    opacity: 1;
  }
  &.white {
    background-color: $whiteman;
  }
  &.small {
    width: 14px;
    height: 14px;
  }
  &.selected {
    background-color: $accented;
  }
  &.arrow-back {
    mask: url("/assets/images/oldPlatform/arrow-back.svg");
    -webkit-mask: url("/assets/images/oldPlatform/arrow-back.svg");
  }

  &.arrow-up {
    mask: url("/assets/images/oldPlatform/arrow-up.svg");
    -webkit-mask: url("/assets/images/oldPlatform/arrow-up.svg");
  }

  &.arrow-down {
    mask: url("/assets/images/oldPlatform/arrow-down.svg");
    -webkit-mask: url("/assets/images/oldPlatform/arrow-down.svg");
  }

  &.chevron-down {
    mask: url("/assets/images/oldPlatform/chevron-down.svg");
    -webkit-mask: url("/assets/images/oldPlatform/chevron-down.svg");
  }

  &.chevron-up {
    mask: url("/assets/images/oldPlatform/chevron-up.svg");
    -webkit-mask: url("/assets/images/oldPlatform/chevron-up.svg");
  }

  &.chevron-left {
    mask: url("/assets/images/oldPlatform/chevron-left.svg");
    -webkit-mask: url("/assets/images/oldPlatform/chevron-left.svg");
  }

  &.chevron-right {
    mask: url("/assets/images/oldPlatform/chevron-right.svg");
    -webkit-mask: url("/assets/images/oldPlatform/chevron-right.svg");
  }

  &.edit {
    mask: url("/assets/images/oldPlatform/edit.svg");
    -webkit-mask: url("/assets/images/oldPlatform/edit.svg");
  }

  &.expand {
    mask: url("/assets/images/oldPlatform/expand.svg");
    -webkit-mask: url("/assets/images/oldPlatform/expand.svg");
  }

  &.visible {
    mask: url("/assets/images/oldPlatform/eye-outline.svg");
    -webkit-mask: url("/assets/images/oldPlatform/eye-outline.svg");
  }

  &.hidden {
    mask: url("/assets/images/oldPlatform/eye-off.svg");
    -webkit-mask: url("/assets/images/oldPlatform/eye-off.svg");
  }

  &.file-add {
    mask: url("/assets/images/oldPlatform/file-add-outline.svg");
    -webkit-mask: url("/assets/images/oldPlatform/file-add-outline.svg");
  }

  &.plus-circle {
    mask: url("/assets/images/oldPlatform/plus-circle-outline.svg");
    -webkit-mask: url("/assets/images/oldPlatform/plus-circle-outline.svg");
  }

  &.plus {
    mask: url("/assets/images/oldPlatform/plus.svg");
    -webkit-mask: url("/assets/images/oldPlatform/plus.svg");
  }

  &.search {
    mask: url("/assets/images/oldPlatform/search.svg");
    -webkit-mask: url("/assets/images/oldPlatform/search.svg");
  }

  &.settings {
    mask: url("/assets/images/oldPlatform/settings-2-outline.svg");
    -webkit-mask: url("/assets/images/oldPlatform/settings-2-outline.svg");
  }

  &.arrow-back {
    mask: url("/assets/images/oldPlatform/arrow-back.svg");
    -webkit-mask: url("/assets/images/oldPlatform/arrow-back.svg");
  }

  &.help {
    mask: url("/assets/images/oldPlatform/help.svg");
    -webkit-mask: url("/assets/images/oldPlatform/help.svg");
  }

  &.message {
    mask: url("/assets/images/oldPlatform/message.svg");
    -webkit-mask: url("/assets/images/oldPlatform/message.svg");
  }

  &.notification {
    mask: url("/assets/images/oldPlatform/notification.svg");
    -webkit-mask: url("/assets/images/oldPlatform/notification.svg");
  }

  &.notification-cross {
    mask: url("/assets/images/oldPlatform/notification_cross.svg");
    -webkit-mask: url("/assets/images/oldPlatform/notification_cross.svg");
  }

  &.arm {
    mask: url("/assets/images/oldPlatform/arm.svg");
    -webkit-mask: url("/assets/images/oldPlatform/arm.svg");
  }

  &.calendar {
    mask: url("/assets/images/oldPlatform/calendar.svg");
    -webkit-mask: url("/assets/images/oldPlatform/calendar.svg");
  }

  &.delete {
    mask: url("/assets/images/oldPlatform/delete.svg");
    -webkit-mask: url("/assets/images/oldPlatform/delete.svg");
  }

  &.education {
    mask: url("/assets/images/oldPlatform/education.svg");
    -webkit-mask: url("/assets/images/oldPlatform/education.svg");
  }

  &.gender {
    mask: url("/assets/images/oldPlatform/gender.svg");
    -webkit-mask: url("/assets/images/oldPlatform/gender.svg");
  }

  &.height {
    mask: url("/assets/images/oldPlatform/height.svg");
    -webkit-mask: url("/assets/images/oldPlatform/height.svg");
  }

  &.id {
    mask: url("/assets/images/oldPlatform/id.svg");
    -webkit-mask: url("/assets/images/oldPlatform/id.svg");
  }

  &.job {
    mask: url("/assets/images/oldPlatform/job.svg");
    -webkit-mask: url("/assets/images/oldPlatform/job.svg");
  }

  &.language {
    mask: url("/assets/images/oldPlatform/language.svg");
    -webkit-mask: url("/assets/images/oldPlatform/language.svg");
  }

  &.leg {
    mask: url("/assets/images/oldPlatform/leg.svg");
    -webkit-mask: url("/assets/images/oldPlatform/leg.svg");
  }

  &.mail {
    mask: url("/assets/images/oldPlatform/mail.svg");
    -webkit-mask: url("/assets/images/oldPlatform/mail.svg");
  }

  &.minimize {
    mask: url("/assets/images/oldPlatform/minimize.svg");
    -webkit-mask: url("/assets/images/oldPlatform/minimize.svg");
  }

  &.new {
    mask: url("/assets/images/oldPlatform/new.svg");
    -webkit-mask: url("/assets/images/oldPlatform/new.svg");
  }

  &.phone {
    mask: url("/assets/images/oldPlatform/phone.svg");
    -webkit-mask: url("/assets/images/oldPlatform/phone.svg");
  }

  &.printer {
    mask: url("/assets/images/oldPlatform/printer.svg"); //this is file
    -webkit-mask: url("/assets/images/oldPlatform/printer.svg");
  }

  &.remove {
    mask: url("/assets/images/oldPlatform/remove.svg");
    -webkit-mask: url("/assets/images/oldPlatform/remove.svg");
  }

  &.ring {
    mask: url("/assets/images/oldPlatform/ring.svg");
    -webkit-mask: url("/assets/images/oldPlatform/ring.svg");
  }

  &.send {
    mask: url("/assets/images/oldPlatform/send.svg");
    -webkit-mask: url("/assets/images/oldPlatform/send.svg");
  }

  &.star {
    mask: url("/assets/images/oldPlatform/star.svg");
    -webkit-mask: url("/assets/images/oldPlatform/star.svg");
  }

  &.star-full {
    mask: url("/assets/images/oldPlatform/star-full.svg");
    -webkit-mask: url("/assets/images/oldPlatform/star-full.svg");
  }

  &.user {
    mask: url("/assets/images/oldPlatform/user.svg");
    -webkit-mask: url("/assets/images/oldPlatform/user.svg");
  }

  &.weight {
    mask: url("/assets/images/oldPlatform/weight.svg");
    -webkit-mask: url("/assets/images/oldPlatform/weight.svg");
  }

  &.world {
    mask: url("/assets/images/oldPlatform/world.svg");
    -webkit-mask: url("/assets/images/oldPlatform/world.svg");
  }

  &.close {
    mask: url("/assets/images/oldPlatform/close.svg");
    -webkit-mask: url("/assets/images/oldPlatform/close.svg");
  }

  &.time {
    mask: url("/assets/images/oldPlatform/time.svg");
    -webkit-mask: url("/assets/images/oldPlatform/time.svg");
  }

  &.menu {
    mask: url("/assets/images/oldPlatform/menu.svg");
    -webkit-mask: url("/assets/images/oldPlatform/menu.svg");
  }

  &.logout {
    mask: url("/assets/images/oldPlatform/logout.svg");
    -webkit-mask: url("/assets/images/oldPlatform/logout.svg");
  }

  &.kinetikosIcon {
    mask: url("/assets/images/oldPlatform/kinetikosIcon.svg");
    -webkit-mask: url("/assets/images/oldPlatform/kinetikosIcon.svg");
  }

  &.externalLink {
    mask: url("/assets/images/oldPlatform/externalLink.svg");
    -webkit-mask: url("/assets/images/oldPlatform/externalLink.svg");
  }

  &.save {
    mask: url("/assets/images/oldPlatform/save.svg");
    -webkit-mask: url("/assets/images/oldPlatform/save.svg");
  }

  &.check {
    mask: url("/assets/images/oldPlatform/check.svg");
    -webkit-mask: url("/assets/images/oldPlatform/check.svg");
  }

  &.grid {
    mask: url("/assets/images/oldPlatform/grid.svg");
    -webkit-mask: url("/assets/images/oldPlatform/grid.svg");
  }

  &.umbrella {
    mask: url("/assets/images/oldPlatform/umbrella.svg");
    -webkit-mask: url("/assets/images/oldPlatform/umbrella.svg");
  }

  &.home {
    mask: url("/assets/images/oldPlatform/home.svg");
    -webkit-mask: url("/assets/images/oldPlatform/home.svg");
  }

  &.smartphone {
    mask: url("/assets/images/oldPlatform/smartphone.svg");
    -webkit-mask: url("/assets/images/oldPlatform/smartphone.svg");
  }

  &.sliders {
    mask: url("/assets/images/oldPlatform/sliders.svg");
    -webkit-mask: url("/assets/images/oldPlatform/sliders.svg");
  }

  &.crop {
    mask: url("/assets/images/oldPlatform/crop.svg");
    -webkit-mask: url("/assets/images/oldPlatform/crop.svg");
  }

  &.tag {
    mask: url("/assets/images/oldPlatform/tag.svg");
    -webkit-mask: url("/assets/images/oldPlatform/tag.svg");
  }

  &.inbox {
    mask: url("/assets/images/oldPlatform/inbox.svg");
    -webkit-mask: url("/assets/images/oldPlatform/inbox.svg");
  }

  &.database {
    mask: url("/assets/images/oldPlatform/database.svg");
    -webkit-mask: url("/assets/images/oldPlatform/database.svg");
  }

  &.file {
    mask: url("/assets/images/oldPlatform/file.svg");
    -webkit-mask: url("/assets/images/oldPlatform/file.svg");
  }

  &.heart-on {
    mask: url("/assets/images/oldPlatform/heart-on.svg");
    -webkit-mask: url("/assets/images/oldPlatform/heart-on.svg");
  }

  &.file-text {
    mask: url("/assets/images/oldPlatform/file-text.svg");
    -webkit-mask: url("/assets/images/oldPlatform/file-text.svg");
  }

  &.lock {
    mask: url("/assets/images/oldPlatform/lock.svg");
    -webkit-mask: url("/assets/images/oldPlatform/lock.svg");
  }

  &.clipboard {
    mask: url("/assets/images/oldPlatform/clipboard.svg");
    -webkit-mask: url("/assets/images/oldPlatform/clipboard.svg");
  }

  &.video-off {
    mask: url("/assets/images/oldPlatform/video-off.svg");
    -webkit-mask: url("/assets/images/oldPlatform/video-off.svg");
  }

  &.thumbs-down {
    mask: url("/assets/images/oldPlatform/thumbs-down.svg");
    -webkit-mask: url("/assets/images/oldPlatform/thumbs-down.svg");
  }

  &.user-plus {
    mask: url("/assets/images/oldPlatform/user-plus.svg");
    -webkit-mask: url("/assets/images/oldPlatform/user-plus.svg");
  }

  &.slash {
    mask: url("/assets/images/oldPlatform/slash.svg");
    -webkit-mask: url("/assets/images/oldPlatform/slash.svg");
  }

  &.user-minus {
    mask: url("/assets/images/oldPlatform/user-minus.svg");
    -webkit-mask: url("/assets/images/oldPlatform/user-minus.svg");
  }

  &.pie-chart {
    mask: url("/assets/images/oldPlatform/pie-chart.svg");
    -webkit-mask: url("/assets/images/oldPlatform/pie-chart.svg");
  }

  &.info {
    mask: url("/assets/images/oldPlatform/info.svg");
    -webkit-mask: url("/assets/images/oldPlatform/info.svg");
  }

  &.user-x {
    mask: url("/assets/images/oldPlatform/user-x.svg");
    -webkit-mask: url("/assets/images/oldPlatform/user-x.svg");
  }

  &.refresh {
    mask: url("/assets/images/oldPlatform/refresh-cw.svg");
    -webkit-mask: url("/assets/images/oldPlatform/refresh-cw.svg");
  }

  &.refresh-warn {
    mask: url("/assets/images/oldPlatform/refresh-warn.svg");
    -webkit-mask: url("/assets/images/oldPlatform/refresh-warn.svg");
  }

  &.copy {
    mask: url("/assets/images/oldPlatform/copy.svg");
    -webkit-mask: url("/assets/images/oldPlatform/copy.svg");
  }

  &.image {
    mask: url("/assets/images/oldPlatform/image.svg");
    -webkit-mask: url("/assets/images/oldPlatform/image.svg");
  }

  &.thumbs-up {
    mask: url("/assets/images/oldPlatform/thumbs-up.svg");
    -webkit-mask: url("/assets/images/oldPlatform/thumbs-up.svg");
  }

  &.report {
    mask: url("/assets/images/oldPlatform/report.svg");
    -webkit-mask: url("/assets/images/oldPlatform/report.svg");
  }

  &.alert-circle {
    mask: url("/assets/images/oldPlatform/alert-circle.svg");
    -webkit-mask: url("/assets/images/oldPlatform/alert-circle.svg");
  }

  &.credit-card {
    mask: url("/assets/images/oldPlatform/credit-card.svg");
    -webkit-mask: url("/assets/images/oldPlatform/credit-card.svg");
  }

  &.video {
    mask: url("/assets/images/oldPlatform/video.svg");
    -webkit-mask: url("/assets/images/oldPlatform/video.svg");
  }

  &.activity {
    mask: url("/assets/images/oldPlatform/activity.svg");
    -webkit-mask: url("/assets/images/oldPlatform/activity.svg");
  }

  &.filter {
    mask: url("/assets/images/oldPlatform/filter.svg");
    -webkit-mask: url("/assets/images/oldPlatform/filter.svg");
  }

  &.slack {
    mask: url("/assets/images/oldPlatform/slack.svg");
    -webkit-mask: url("/assets/images/oldPlatform/slack.svg");
  }

  &.alert-triangle {
    mask: url("/assets/images/oldPlatform/alert-triangle.svg");
    -webkit-mask: url("/assets/images/oldPlatform/alert-triangle.svg");
  }

  &.user-check {
    mask: url("/assets/images/oldPlatform/user-check.svg");
    -webkit-mask: url("/assets/images/oldPlatform/user-check.svg");
  }

  &.alert-octagon {
    mask: url("/assets/images/oldPlatform/alert-octagon.svg");
    -webkit-mask: url("/assets/images/oldPlatform/alert-octagon.svg");
  }

  &.users {
    mask: url("/assets/images/oldPlatform/users.svg");
    -webkit-mask: url("/assets/images/oldPlatform/users.svg");
  }

  &.film {
    mask: url("/assets/images/oldPlatform/film.svg");
    -webkit-mask: url("/assets/images/oldPlatform/film.svg");
  }

  &.heart {
    mask: url("/assets/images/oldPlatform/heart.svg");
    -webkit-mask: url("/assets/images/oldPlatform/heart.svg");
  }

  &.trending-up {
    mask: url("/assets/images/oldPlatform/trending-up.svg");
    -webkit-mask: url("/assets/images/oldPlatform/trending-up.svg");
  }

  &.camera {
    mask: url("/assets/images/oldPlatform/camera.svg");
    -webkit-mask: url("/assets/images/oldPlatform/camera.svg");
  }

  &.camera-off {
    mask: url("/assets/images/oldPlatform/camera-off.svg");
    -webkit-mask: url("/assets/images/oldPlatform/camera-off.svg");
  }

  &.zoom-in {
    mask: url("/assets/images/oldPlatform/zoom-in.svg");
    -webkit-mask: url("/assets/images/oldPlatform/zoom-in.svg");
  }

  &.zoom-out {
    mask: url("/assets/images/oldPlatform/zoom-out.svg");
    -webkit-mask: url("/assets/images/oldPlatform/zoom-out.svg");
  }

  &.move {
    mask: url("/assets/images/oldPlatform/move.svg");
    -webkit-mask: url("/assets/images/oldPlatform/move.svg");
  }

  &.focus {
    mask: url("/assets/images/oldPlatform/maximize.svg");
    -webkit-mask: url("/assets/images/oldPlatform/maximize.svg");
  }

  &.list {
    mask: url("/assets/images/oldPlatform/list.svg");
    -webkit-mask: url("/assets/images/oldPlatform/list.svg");
  }

  &.download {
    mask: url("/assets/images/oldPlatform/download.svg");
    -webkit-mask: url("/assets/images/oldPlatform/download.svg");
  }

  &.survey {
    mask: url("/assets/images/oldPlatform/survey.svg");
    -webkit-mask: url("/assets/images/oldPlatform/survey.svg");
  }
  &.test {
    mask: url("/assets/images/oldPlatform/test.svg");
    -webkit-mask: url("/assets/images/oldPlatform/test.svg");
  }

  &.prescription {
    mask: url("/assets/images/oldPlatform/prescription.svg");
    -webkit-mask: url("/assets/images/oldPlatform/prescription.svg");
  }

  &.news {
    mask: url("/assets/images/oldPlatform/radio.svg");
    -webkit-mask: url("/assets/images/oldPlatform/radio.svg");
  }

  &.time {
    background-image: url("/assets/images/oldPlatform/time.svg");
  }

  &.calendar {
    background-image: url("/assets/images/oldPlatform/calendar.svg");
  }

  &.iconSearch {
    background-image: url("/assets/images/oldPlatform/searchTertiary.svg");

    &:focus,
    &:not(:placeholder-shown) {
      background-image: url("/assets/images/oldPlatform/searchActive.svg");
    }
  }

  &.loading {
    background: url("/assets/images/oldPlatform/loadingIcon.png");
    background-size: 100%;
    opacity: 1;
    animation: spin 1.5s linear infinite;
    @keyframes spin {
      100% {
        transform: rotate(0deg);
      }
      0% {
        transform: rotate(360deg);
      }
    }
  }

  &.plan {
    width: auto;
    height: 30px;
    opacity: 1;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;

    // &.clinikPro {
    //   background-image: url("/assets/images/oldPlatform/clinikProLogo.svg");
    // }

    // &.clinik {
    //   background-image: url("/assets/images/oldPlatform/clinikLogo.svg");
    // }

    &.dashboard {
      background-image: url("/assets/images/oldPlatform/dashboardLogo.svg");
    }

    &.dashboardPlus {
      background-image: url("/assets/images/oldPlatform/dashboardPlusLogo.svg");
    }

    // &.premium {
    //   width: 65px;
    //   height: 30px;
    //   background-image: url("/assets/images/oldPlatform/premiumLogo.svg");
    // }

    // &.basic {
    //   width: 55px;
    //   height: 30px;
    //   background-image: url("/assets/images/oldPlatform/basicLogo.svg");
    // }
  }
}
