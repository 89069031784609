.progress-bar-default {
  position: relative;
  width: 100%;
  padding: 3px;
  background-color: $background-color;
  color: $surface-white;
  border-radius: $border-radius;
  &.active {
    background-color: #a5e6ba;
  }
}

.progress-bar-success {
  @extend .regular-text;
  position: relative;
  width: 100%;
  padding: 3px;
  padding-left: $spacing-a-small;
  color: $base-color;
  border-radius: $border-radius;
  background-color: #a5e6ba;
}

.progress-bar-loading {
  width: 50%;
  height: 100%;
  border-radius: 5px;
  position: absolute;
  background-color: $primary-color;
  top: 0;
  left: 0;
}
