.toast-wrapper {
  position: fixed;
  top: 10px;
  width: 33%;
  left: 33.33%;
  z-index: 9999;
}

.toast-body {
  border-radius: $border-radius;
  background-color: $surface-white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
  margin-bottom: $spacing-a-smallest;
  padding: 0;
  animation: toast 0.5s;
  // animation: progress-bar 5s linear forwards;

  .main {
    padding: $spacing-a-smaller;
  }

  .close {
    animation: fadeout 0.5s;
  }

  .toast-progress-bar {
    border-radius: 0 0 4px 4px;
    width: 100%;
    height: 4px;
    background-color: $primary-color;
  }

  .active {
    animation: progress-bar 5s linear forwards;
  }
}

@keyframes toast {
  0% {
    opacity: 0;
  }
  100% {
    // transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes progress-bar {
  100% {
    width: 0%;
  }
}

.toast-image {
  @extend .col-2, .d-flex, .align-items-center;
  width: 10%;
  min-width: 45px;

  img {
    width: 100%;
  }
}

.toast-message {
  @extend .col-6, .d-flex, .align-items-center, .regular-text, .black-color;
  width: 55%;
}

.toast-button {
  @extend .col, .d-flex, .align-items-center;
  flex-direction: row-reverse;
}

@media screen and (max-width: 500px) {
  .toast-wrapper {
    width: 100%;
    left: 0;
    padding: 0 2% 0 2%;
  }
}
