//weight 100
@font-face {
  font-family: "Inter-Thin";
  src: url("../../assets/fonts/Inter-Thin.ttf");
}

//weight 200
@font-face {
  font-family: "Inter-ExtraLight";
  src: url("../../assets/fonts/Inter-ExtraLight.ttf");
}

//weight 300
@font-face {
  font-family: "Inter-Light";
  src: url("../../assets/fonts/Inter-Light.ttf");
}

//weight 400
@font-face {
  font-family: "Inter-Regular";
  src: url("../../assets/fonts/Inter-Regular.ttf");
}

//weight 500
@font-face {
  font-family: "Inter-Medium";
  src: url("../../assets/fonts/Inter-Medium.ttf");
}

//weight 600
@font-face {
  font-family: "Inter-SemiBold";
  src: url("../../assets/fonts/Inter-SemiBold.ttf");
}

//weight 700
@font-face {
  font-family: "Inter-Bold";
  src: url("../../assets/fonts/Inter-Bold.ttf");
}

//weight 800
@font-face {
  font-family: "Inter-ExtraBold";
  src: url("../../assets/fonts/Inter-ExtraBold.ttf");
}

//weight 900
@font-face {
  font-family: "Inter-Black";
  src: url("../../assets/fonts/Inter-Black.ttf");
}
