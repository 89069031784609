.auth-left-side {
  height: 100vh;
  overflow: hidden;
  position: relative;
  flex-direction: row;
  justify-content: center;
  display: flex;
  background: linear-gradient($base-color 0%, #114575 100%);
}

@media screen and (max-width: 768px) {
  .auth-left-side {
    display: none;
  }
}

.kinetikos-information {
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
}

.serial-number-container {
  align-items: center;
  flex-direction: row;
  position: absolute;
  top: 5px;
  left: 10px;
  img {
    margin-right: 5px;
  }
}

.support-link {
  position: absolute;
  top: 10px;
  right: 20px;
  a {
    text-decoration: none;
  }
}

.auth-right-side {
  height: 100vh;
  position: relative;
  background-color: $background-color;
  .scroll {
    height: 100vh;
    position: relative;
    overflow: auto;
  }
  .row {
    min-height: 100%;
  }
}

.form-auth-container {
  margin-top: $spacing-a-medium;
  margin-bottom: $spacing-a-medium;
}

.form-auth-wrapper {
  padding-top: $spacing-a-xmedium;
  padding-left: $spacing-a-medium;
  padding-right: $spacing-a-medium;
  padding-bottom: $spacing-a-medium;
  background-color: $surface-white;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: $border-radius;

  .footer-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}

.form-auth-bottom {
  flex-direction: row;
  justify-content: center;
  padding-top: $spacing-a-small;
  .auth-bottom-element {
    padding-top: $spacing-a-small;
    align-items: center;
    justify-content: center;
    display: flex;
    &.inline-block {
      display: inline-block;
    }
    a {
      text-decoration: none;
    }
  }
}

.logo-position {
  position: absolute;
  top: $spacing-a-small;
  right: $spacing-a-small;
  width: 106px;
  height: auto;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-auth {
  width: 4.6rem;
  height: 2.25rem;
  font-size: $font-s-regular;
  font-family: "Inter-Regular";
  border: 1px solid #ccc;
  border-radius: 6px;

  color: $dark-grey-color;
  background: $surface-white url("../../assets/images/arrow-down.svg") no-repeat
    center right 0.6em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 21px;
  &:focus {
    background-color: $base-color;
    border: 1px solid $primary-hover-color;
    box-shadow: none;
    color: $surface-white;
  }
}

.auth-left-logo {
  position: absolute;
  bottom: 0;
  top: 0;
  margin-top: auto;
  margin-bottom: auto;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.kinetikos-colapsed {
  padding-top: $spacing-a-biggest;
  padding-bottom: $spacing-a-biggest;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.send-code-btn {
  position: absolute;
  right: 0;
  width: 96px;
  text-align: center;
  border-left: 1px solid #dbe7ee;
  cursor: pointer;

  &.disabled {
    cursor: auto;
    opacity: 0.3;
  }
}

.qrcode-modal-wrapper {
  display: flex;
  background-color: rgba($color: $base-color, $alpha: 0.2);
  margin: auto;
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .qrcode-modal-container {
    @include drop-shadow(0, 2px, 4px, -1px, 0.12);
    background-color: rgba($color: $surface-white, $alpha: 1);
    padding: $spacing-a-medium;
    border-radius: $border-radius;
    cursor: zoom-out;
  }
}

.mfa-buttons-wrapper {
  @extend .flex-d-column;
  align-items: center;
}

.backup-tokens-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: $spacing-a-xmedium;

  &.loading {
    position: relative;
    height: 100px;
  }

  .backup-token-container {
    width: 30%;
    padding-top: $spacing-a-smallest;
    padding-bottom: $spacing-a-smallest;
    border-bottom: 1px solid $background-color;
  }
}

.footer-top-animation {
  width: 85%;
  height: 65%;
  position: absolute;
  top: 10%;
}

.tac-signup {
  overflow-y: scroll;
  height: 400px;
  text-align: justify;
  white-space: pre-line;
  padding: $spacing-a-small;
}

.footer-company-info {
  margin-top: auto;
  font-size: $font-s-small;
  color: #8ca1b4;
  padding: $spacing-a-small;

  img {
    float: left;
  }

  .header-text {
    text-align: center;
    padding: $spacing-a-xsmall;
  }

  .product_version {
    margin-left: $spacing-a-smallest;
    float: left;
  }
}

.backup-code-icon-container {
  display: "flex";
  gap: 6;
}

.activate-account-footer {
  display: flex;
  width: 50%;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .activate-account-footer {
    width: 100%;
  }
}
