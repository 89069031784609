//Colors
$base-color: #255986;
$secondary-color: #64c1b4;
$secondary-hover-color: #4ba497;
$background-color: #eff3f6;
$surface-white: #fdfdfe;
$grey-color: #e5e5e5;
$grey-blue-color: #5b6481;
$grey-intense-color: #8ca1b4;
$dark-grey-color: #4c657a;
$primary-hover-color: #407db3;
$primary-color: #508cc0;
$surface-black-color: #071e31;
$cornflower-blue-color: #62788a;
$warning-color: #c92218;
$warning-hover-color: #bc241c;
$background-hover-color: #dbe7ee;
$light-green: #c2e0b3;
$light-gray: #ededed;
$light-pink: #f3aca7;
$light-yellow: #f8e3bc;

//Font family
$font-family-thin: "Inter-Thin";
$font-family-extra-light: "Inter-ExtraLight";
$font-family-light: "Inter-Light";
$font-family-regular: "Inter-Regular";
$font-family-medium: "Inter-Medium";
$font-family-semi-bold: "Inter-SemiBold";
$font-family-bold: "Inter-Bold";
$font-family-extra-bold: "Inter-ExtraBold";
$font-family-black: "Inter-Black";

// font size
$font-s-small: 0.625rem; //10 px
$font-s-regular: 0.75rem; //12 px
$font-s-medium: 0.875rem; //14 px
$font-s-big: 1rem; //16 px
$font-s-xbig: 1.25rem; //20 px
$font-s-large: 1.5rem; //24 px
$font-s-xlarge: 2rem; //32 px
$font-s-xxlarge: 2.625rem; //42 px

// border radius
$border-radius-small: 3px;
$border-radius: 5px;
$border-radius1: 20px;
$border-radius2: 50px;

//spacing absolute
$spacing-a-smallest: 8px;
$spacing-a-smaller: 12px;
$spacing-a-small: 16px;
$spacing-a-xsmall: 24px;
$spacing-a-medium: 32px;
$spacing-a-xmedium: 40px;
$spacing-a-large: 48px;
$spacing-a-biggest: 64px;

//inputs
$input-min-width: 150px;

//FROM PREVIOUS PLATFORM

//Main colors
$bodyBackground: #eff3f6;
$kinetikosBlue: #3c49a4;
$textPrimary: #535f7f;
$textSecondary: #8a92a7;
$textTertiary: #c4c8d3;
$accent: #4bd971;
$accented: #45c195;
$light: #e7edf3;
$whiteman: #fefefe;
$fieldBack: rgb(243, 245, 247);
$error: #e8587a;
//Transparent
$secondaryTransparent: rgba(138, 146, 167, 0.2);
$transparent: rgba(0, 0, 0, 0);
$lightTransparent: rgba(231, 237, 243, 0.2);
$accentTransparent: rgba(75, 217, 113, 0.2);

//Additional Colors
$azure: #3589e8;
$rajah: #ffbb7a;
$radical: #e8587a;
$dandelion: #ffd86e;
$viking: #4db5cb;
$mypink: #d68a8a;
$pastelred: #fd6b65;
$orange: #ed851d;
$yellow: #ffd953;
$orange-light: #dd7811;

//Gradients
$heroGrad: linear-gradient(
  180deg,
  #404daf 0%,
  #39459a 20%,
  #28306b 66%,
  #1c2147 100%
);

$twistGrad: linear-gradient(90deg, #e65918, #74e618, #189ce6, #d218e6);
$kinetikosGrad: linear-gradient(90deg, #3589e8 0%, #4ad27c 100%);
$azureGrad: linear-gradient(63deg, #65bef5 0%, #3589e8 97%);
$rajahGrad: linear-gradient(240deg, #ffd76d 0%, #ff843b 100%);
$radGrad: linear-gradient(240deg, #ff9ea3 0%, #fc625b 100%);
$lightGrad: linear-gradient(0deg, #ecf0f5 0%, #fcfcfc 100%);

$kpalette-color1: #1ca2d1;
$kpalette-color2: #2babb3;
$kpalette-color3: #2dada9;
$kpalette-color4: #32b39b;
$kpalette-color5: #3ab986;

/* Card side colors */
$kblue-color: #06a6c1;
$kdarkblue-color: #012e4f;
$kwhite-color: #f0f8ff;
$klightgreen-color: #93deb4;
$kgreen-color: #66c58e;
$kdarkgreen-color: #173c27;
$kred-color: #de815c;
$kdarkred-color: #8a3324;
$kgray-color: #6d6e71;
$kdarkgray-color: #4b4b4b;
$kyellow-color: #ffb700;
$korange-color: #ff8c00;
$kpink-color: #ff0037;

/* RGB colors side colors */
$klightgray-color: rgba(109, 110, 113, 0.6);
$kblue-rgba-color: rgba(6, 166, 193, 0.4);
$kdarkblue-text-color: rgb(3, 81, 94);

/* Background color */
// $background-color: #f2f2f2;

/* MKinetikos colors */
$mkinetikos-gray-color: #808080;
$mkinetikos-red-color: #ff6961;
$mkinetikos-green-color: #2cd892;

/* PDTracker colors */
$pdtracker-gray-color: #525151;
$pdtracker-gray-light-color: #d4d4d4;
$pdtracker-gray-semi-light-color: #eee;
$pdtracker-gray-lighter-color: #eaeaea;
$pdtracker-gray-general-color: #f4f4f4;
$pdtracker-gray-extra-lighter-color: #fdfdfd;
$pdtracker-blue-color: #404daf;
$pdtracker-blue-lighter-color: #f2f8fd;
$pdtracker-blue-dark-color: #323a75;
$pdtracker-lime-color: #4cd971;
$pdtracker-shadow: 2px 7px 6px -4px rgba(212, 209, 209, 0.94);
$pdtracker-orange-color: #f77a52;

/* Benchmark colors */
$benchmark-0-color: #8db600;
$benchmark-1-color: #008000;
$benchmark-2-color: #d40000;
$benchmark-3-color: #7b1113;

$benchmark-0: #8db600;
$benchmark-1: #008000;
$benchmark-2: #d40000;
$benchmark-3: #7b1113;
$benchmark-none: #6d6e71;

$benchmark-1-2-std_dev: #ff8c00;
$benchmark-2plus-std_dev: #8a3324;

/* Others */
$slide-in-animation: slide-in 0.5s ease forwards;
$box-shadow: 0px 2px 5px rgba(128, 128, 128, 0.3);
$border: 1px solid rgba(128, 128, 128, 0.5);
$border-radius: 4px;

/* variables needed to support report js*/

body {
  --kpalette-color1: #1ca2d1;
  --kpalette-color2: #2babb3;
  --kpalette-color3: #2dada9;
  --kpalette-color4: #32b39b;
  --kpalette-color5: #3ab986;

  /* Card side colors */
  --kblue-color: #06a6c1;
  --kdarkblue-color: #012e4f;
  --kwhite-color: #f0f8ff;
  --klightgreen-color: #93deb4;
  --kgreen-color: #66c58e;
  --kdarkgreen-color: #173c27;
  --kred-color: #de815c;
  --kdarkred-color: #8a3324;
  --kgray-color: #6d6e71;
  --kdarkgray-color: #4b4b4b;
  --kyellow-color: #ffb700;
  --korange-color: #ff8c00;
  --kpink-color: #ff0037;

  /* RGB colors side colors */
  --klightgray-color: rgba(109, 110, 113, 0.6);
  --kblue-rgba-color: rgba(6, 166, 193, 0.4);
  --kdarkblue-text-color: rgb(3, 81, 94);

  /* Background color */
  --background-color: #f2f2f2;

  /* MKinetikos colors */
  --mkinetikos-gray-color: #808080;
  --mkinetikos-red-color: #ff6961;
  --mkinetikos-green-color: #2cd892;

  /* PDTracker colors */
  --pdtracker-gray-color: #525151;
  --pdtracker-gray-light-color: #d4d4d4;
  --pdtracker-gray-semi-light-color: #eee;
  --pdtracker-gray-lighter-color: #eaeaea;
  --pdtracker-gray-general-color: #f4f4f4;
  --pdtracker-gray-extra-lighter-color: #fdfdfd;
  --pdtracker-blue-color: #404daf;
  --pdtracker-blue-lighter-color: #f2f8fd;
  --pdtracker-blue-dark-color: #323a75;
  --pdtracker-lime-color: #4cd971;
  --pdtracker-shadow: 2px 7px 6px -4px rgba(212, 209, 209, 0.94);
  --pdtracker-orange-color: #f77a52;

  /* Benchmark colors */
  --benchmark-0-color: #8db600;
  --benchmark-1-color: #008000;
  --benchmark-2-color: #d40000;
  --benchmark-3-color: #7b1113;
}
