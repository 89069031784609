.modules-list {
  margin-top: $spacing-a-smaller;
  .module {
    background-color: $surface-white;
    margin-bottom: $spacing-a-small;
    @include box($spacing-a-small $spacing-a-smaller);
    @include drop-shadow(0, 2px, 4px, -1px, 0.12);
    @extend .row-space-between;
    .module-image {
      display: inline-flex;
      margin-right: 20px;
      width: 23px;
      height: auto;
    }
    img {
      width: 25px;
    }

    .module-name {
      @extend .regular-text.medium, .black-color;
    }

    .evaluation-data-text {
      @extend .regular-text;
    }

    .evaluation-data {
      @extend .medium-base-color-text;
    }
  }
}
