.create_patient_title {
  text-align: center;
  margin-bottom: $spacing-a-small;
}


.form-documents-container {
  border-top: 1px solid #508cc0;
  padding-top: 21px;
}

.form-documents-header-container {
  margin-bottom: $spacing-a-medium;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  .delete-container {
    justify-content: flex-end;
    display: flex;
  }
}

.form-underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background-color: $primary-hover-color;
}

.form-inside-container {
  padding: $spacing-a-medium;
}

.form-bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: $spacing-a-large;
}

.input-calendar {
  position: absolute;
  top: 8px;
  right: 10px;
  pointer-events: none;
}
