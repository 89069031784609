.support-overview-container {
  width: 100%;
}

.support-overview-content-container {
  margin-top: $spacing-a-xmedium;
  padding: 20px 0 0 0;
}

.support-overview-subheader-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
}

.support-overview-subheader {
  font-weight: 700;
}

.support-overview-create-ticket-button-container {
  padding: 0 !important;
  align-items: flex-end;
}

.support-overview-labels-container {
  margin-top: $spacing-a-medium;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0;
}

.support-overview-tickets-list {
  display: flex;
  flex-direction: row;
  margin-top: $spacing-a-xsmall;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
}

.support-overview-ticket-outer {
  border-bottom: 1px solid $background-color;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $background-color;
  }
}

.support-overview-ticket-inner {
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.support-overview-ticket-item {
  margin: 0;
  color: $surface-black-color;
  font-family: $font-family-regular;
  font-size: $font-s-regular;
  word-wrap: break-word;
  font-size: 12px;
  width: 100%;
}

.support-overview-ticket-label-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
