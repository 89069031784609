.ev-mod-time-input-container {
  font-family: $font-family-regular;
  font-size: $font-s-regular;
  color: $surface-black-color;
  line-height: 14.5px;

  min-width: 150px;
  max-width: 250px;

  display: flex;
  align-items: center;

  padding: $spacing-a-smallest $spacing-a-smaller;
  background-color: $background_color;
  border: 1px solid transparent;
  border-radius: $border-radius;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  position: relative;

  &:focus {
    background-color: $background-color;
    border: 1px solid $primary-color;
  }

  &:focus-within {
    background-color: $background-color;
    border: 1px solid $primary-color;
  }

  &.error {
    border: 1px solid $warning-color;
  }

  input {
    border: 0;
    background-color: transparent;
    display: flex;
    width: 3ch;
  }

  .time-input-select {
    z-index: 5;
    position: absolute;
    top: 115%;
    left: 0;

    width: 100%;
    min-height: 100px;
    height: 164px;
    background-color: $background-color;
    border-radius: $border-radius;
    border: 1px solid $primary-color;

    padding: 6px;

    display: flex;

    .time-input-select-section {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 6px;
      height: 100%;
      overflow-y: auto;

      .time-input-select-item {
        display: flex;
        justify-content: center;
        align-items: center;

        min-height: 32px;

        background-color: $background-color;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
        border-radius: $border-radius;

        margin-bottom: 4px;

        cursor: pointer;

        &:hover:not(.selected) {
          background-color: $background-hover-color;
        }

        &.selected {
          color: $surface-white;
          background-color: $primary-color;
          box-shadow: 0;
        }
      }
    }
  }
}
