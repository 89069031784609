.pagination-wrapper {
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.page-item.active .page-link {
  color: #fff;
  background-color: $base-color;
  border-color: $base-color;
}

.page-link {
  position: relative;
  display: block;
  color: $base-color;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pagination-sm .page-link {
  padding: 0.45rem 0.9rem;
  font-size: $font-s-medium;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}
